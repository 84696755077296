import { service } from '@/ts/service.methods'
import { InternalErrorResponse, ProviderSearchResults, ProviderSearchPayload, Provider, ProviderSearchAddressPredictionOrVerificationPayload, ProviderSearchAddressPredictionOrVerificationResults } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { providerSearchResultsFactory } from '@/factories/services/provider-search'
import { isMock } from '@/../env.config'


// function addCovidValues(locations: Provider[]): void {
//     locations.map((provider: Provider): void => {
//         const heads: boolean = Math.random() < .5
//         provider.locationPreference.healthCompliant = heads ? 'Y':'N'
//     })
// }

export async function searchProviders(searchPayload: ProviderSearchPayload):Promise<ProviderSearchAddressPredictionOrVerificationResults|InternalErrorResponse> {    if (isMock) { 
        const results = require('@/mock-data/search-results.mock.ts')
        // addCovidValues(results.independentLocations)
        // addCovidValues(results.retailLocations)
        // addCovidValues(results.preferredLocations)
        return results
    }
    return new Promise((resolve,reject) => {
        service.post('search', searchPayload)
        .then(res => {
            if (res.data.messageCode === 'PRV001') {
                handleServiceResponse(resolve,reject,res,providerSearchResultsFactory,'providerSearchResultsFactory error')
            } else {
                generateServiceError(reject, res, 'search-providers.ts/searchProviders')
            }
        })
        .catch(err => generateServiceError(reject, err, 'search-providers.ts/searchProviders'))
    })
}

export async function addressVerification(searchPayload: ProviderSearchAddressPredictionOrVerificationPayload):Promise<ProviderSearchAddressPredictionOrVerificationResults|InternalErrorResponse> {
return new Promise((resolve,reject) => {
    service.post('addrVerify', searchPayload)
    .then(res => {
        if (res.data.messageCode === 'GEO001') {
            handleServiceResponse(resolve,reject,res,providerSearchResultsFactory,'providerSearchResultsFactory error')
        } else {
            generateServiceError(reject, res, 'search-providers.ts/addressVerification')
        }
    })
    .catch(err => generateServiceError(reject, err, 'search-providers.ts/addressVerification'))
})
}

export async function addressPrediction(searchPayload: ProviderSearchAddressPredictionOrVerificationPayload):Promise<ProviderSearchResults|InternalErrorResponse> {
return new Promise((resolve,reject) => {
    service.post('addrPredict ', searchPayload)
    .then(res => {
        if (res.data.messageCode === 'GEO001') {
            handleServiceResponse(resolve,reject,res,providerSearchResultsFactory,'providerSearchResultsFactory error')
        } else {
            generateServiceError(reject, res, 'search-providers.ts/addressPrediction')
        }
    })
    .catch(err => generateServiceError(reject, err, 'search-providers.ts/addressPrediction'))
})
}
