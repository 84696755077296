import {
  FormattedNetworkList,
  NetworkOption,
  NetworkListData,
  NetworkResponseObject
} from '@/types'
export const unkownQualifier = '_not_known'
export function networkListFactory(res: NetworkListData): FormattedNetworkList {
  const networks = res.networks.map(
    (networkObject: NetworkResponseObject): NetworkOption => {
      return {
        value: networkObject.networkId,
        label: networkObject.networkName
      }
    }
  ) as NetworkOption[]
  const backupNetwork = networks.find(network => network.label.toLowerCase() === 'advantage network')
  networks.push({
      value: backupNetwork ? `${backupNetwork.value}` : `400000000001`,
      label: 'I don\'t know',
      notKnown: true
  })
  return {
    ...res,
    networks: networks
  }
}
