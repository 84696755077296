<template>
  <font-awesome-icon
    icon="spinner"
    pulse
    aria-label="Loading..."
    class="spinmeister"
    :style="color ? setColor(color) : globalTheme.color"
  />
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      color: {
        required: false,
        type: String
      }
    },
    methods: {
      setColor(color: string): {color: string} {
        return {
          color: color
        }
      }
    }
  })
</script>
