import { service } from '@/ts/service.methods'
import { InternalErrorResponse, FormattedNetworkList } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { networkListFactory } from '@/factories/services/network-list'
import { isMock } from '@/../env.config'

export async function getNetworks():Promise<FormattedNetworkList|InternalErrorResponse> {
    if (isMock) { return networkListFactory(require('@/mock-data/network-list.ts')) as FormattedNetworkList}
    return new Promise((resolve,reject) => {
        service.get('networks')
        .then(res => {
            if (res.data.messageCode.toUpperCase() === 'NTW001') {
                handleServiceResponse(resolve,reject,res,networkListFactory,'networkListFactory factory error')
            } else {
                generateServiceError(reject, res, 'network-list.ts/getNetworks')
            }
        })
        .catch(err => {
            generateServiceError(reject, err, 'network-list.ts/getNetworks')
        })
    })
}
