<template>
  <div
    :class="modifier"
    class="rich-text themed"
    :style="globalTheme.color"
    v-html="findAndReplceClientID(html)"
  ></div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import RichTextRules from '@/mixins/RichTextRules'
  export default defineComponent({
    mixins: [RichTextRules]
  })
</script>
