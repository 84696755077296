<template>
  <div
    container
    style="overflow:visible;">
    <div
      class="provider-info-window"
      grid="row wrap top">
      <div column="xs-12 s-12">
        <button
          v-if="provider.locationId"
          :style="provider.tieredLocation ? globalTheme.colorTieredMapMarkerDark : globalTheme.colorPrimaryDark"
          class="simple"
          :aria-label="`select ${provider.businessName} details in list`"
          @click="scollToLocationInList">
          <p
            class="info-window-title"
            :class="{'with-logo': brandLogo}">
            <img
              v-if="brandLogo && provider.tieredLocation"
              :src="brandLogo.url"
              class="_mrxs brand-logo"
              :aria-label="provider.businessName">
            <strong
              v-else
              class="_mrxs">{{provider.businessName}}</strong>
            <font-awesome-icon
              :icon="['fas', 'caret-down']"/>
          </p>
        </button>
        <p v-else>
          <strong>{{provider.businessName}}</strong>
        </p>
      </div>
      <div
        column="xs-12 s-6 xl-7"
        class="address">
        <p v-if="address.streetAddress1">{{address.streetAddress1}}</p>
        <p v-if="address.streetAddress2">{{address.streetAddress2}}</p>
        <p>{{address.city}}, {{address.state}} {{address.zip}}</p>
      </div>
      <div
        column="xs-12 s-6 xl-5"
        class="flex-wrap">
        <directions-link
          class="_mta" 
          :provider="provider"
          :style="provider.tieredLocation ? globalTheme.colorTieredMapMarkerDark : globalTheme.colorPrimaryDark"
          :label="labels.getDirections"/>
        <schedule-link
          class="inline-caret-link"
          :style="provider.tieredLocation ? globalTheme.colorTieredMapMarkerDark : globalTheme.colorPrimaryDark"
          :provider="provider"
          :labels="labels">
          <font-awesome-icon
            :icon="['fas', 'caret-right']"
            class="caret _mlxxs"/>
        </schedule-link>
        <call-link
          v-if="provider.phoneNumber"
          :phone-number="provider.phoneNumber"
          :label="labels.call" 
          :style="provider.tieredLocation ? globalTheme.colorTieredMapMarkerDark : globalTheme.colorPrimaryDark"/>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, DayLabels , SearchResultCardLabels, ProviderAddress, ContentStackImage } from '@/types'
  import ScheduleLink from '@atoms/ScheduleLink.vue'
  import DirectionsLink from '@atoms/DirectionsLink.vue'
  import CallLink from '@atoms/CallLink.vue'
  export default defineComponent({
    components: {
      ScheduleLink,
      DirectionsLink,
      CallLink
    },
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      }
    },
    data() {
      return {
        address: this.provider.address as ProviderAddress
      }
    },
    computed: {
      brandLogo() {
        return (this as any).$store.state.brandLogos.brandLogos[(this as any).provider.bandId] as ContentStackImage|undefined
      }
    },
    methods: {
      async scollToLocationInList(): Promise<void> {
        if (document.exitFullscreen && document.fullscreenElement) {
          await document.exitFullscreen()
        } 
        const providerInList = document.getElementById((this as any).provider.locationId)
        if (providerInList){
          providerInList.scrollIntoView({ behavior: 'smooth', block: 'start' })
          const focusable: NodeListOf<HTMLElement> = providerInList.parentElement!.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
          setTimeout(() => {
            focusable[0].focus()
          }, 750)
        }
      }
    }
  })
</script>
