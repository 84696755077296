import { objectKeysToCamelCase } from '@oodletech/oodle-js/js'

export function pageFactory(res): any {
  const convertedRes = objectKeysToCamelCase(res)
  if (res.stack) { // error
    return convertedRes
  } else {
    return convertedRes // response
  }
}
