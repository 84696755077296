import locale from '@/store/modules/locale'
import globals from '@/store/modules/globals'
import context from '@/store/modules/context'
import responseMessages from '@/store/modules/response-messages'
import currentProvider from '@/store/modules/current-provider'
import brandLogos from '@/store/modules/brand-logos'
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

const debug = import.meta.env.NODE_ENV !== 'production'

export default createStore({
  state() {
    return {
      plusProviderFlashClosed: false,
      specialOfferFlashClosed: false,
      initialAppLoad: false
    }
  },
  mutations: {
    closePlusProviderFlash(state): void {
      state.plusProviderFlashClosed = true
    },
    closeSpecialOfferFlash(state): void {
      state.specialOfferFlashClosed = true
    },
    flagAppAsLoaded(state): void {
      state.initialAppLoad = true
    }
  },
  modules: {
    locale,
    globals,
    responseMessages,
    brandLogos,
    context,
    currentProvider
  },
  plugins: [
    createPersistedState({
      paths: ['currentProvider'] 
    })
  ],
  strict: debug,
})


