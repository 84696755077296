<template>
  <div class="plus-provider-sidebar-card">
    <hr>
    <div
      :id="provider.locationId"
      class="scroll-anchor">
    </div>
    <div>
      <div style="margin-left:20px">
        <h4>
          <strong>{{ provider.businessName }}</strong>
        </h4>
      </div>
      <div
        v-if="hasSpecialOffer"
        style="margin-left:21px;"
      >
        <special-offer-label :label="labels.specialOfferLabel"/>
      </div>
      <div style="margin-top:23px;">
        <span>
          <p v-if="provider.distance">{{ provider.distance }}mi</p>
          <p v-if="address.streetAddress1">{{ address.streetAddress1 }}</p>
          <p v-if="address.streetAddress2">{{ address.streetAddress2 }}</p>
          <p>{{ address.city }}, {{ address.state }} {{ address.zip }}</p>
        </span>
      </div>
      <div style="margin-left:20px;margin-top:-10px;">
        <schedule-link
          :provider="provider"
          :labels="labels"
          :schedule-appointment-label="scheduleAppointmentLabel"
        >
          <font-awesome-icon
            :icon="['fas', 'caret-right']"
            style="padding-top:3px;"
            class="caret _mlxxs"/>
        </schedule-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import ScheduleLink from '@atoms/ScheduleLink.vue'
  import { mapState } from 'vuex'
  import { Provider, ProviderAddress, SearchResultCardLabels } from '@/types'
  import { formatPhoneNumber } from '@/ts/helpers'

  export default defineComponent({
    components: {
      ScheduleLink
    },
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      specialOffers: {
        type: Object as () => {}
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      },
      scheduleAppointmentLabel: {
        type: String
      }
    },
    data() {
      return {
        address: this.provider.address as ProviderAddress,
      }
    },
    computed: {
      ...mapState({
        icons: (state: any) => {
          return {
            ...state.globals.microsite.icons.searchResultCards,
            ...state.globals.microsite.icons.general
          }
        },
      }),
      icon(): string {
        const icons = this.$store.state.globals.microsite.icons
        const defaultIcon = icons.mapMarkers.mapIconBasic.url
        return defaultIcon
      },
      hasSpecialOffer(): boolean {
        let result = false
        const bandDescription = this.provider.bandDescription
        if (bandDescription && bandDescription in this.specialOffers) {
          const offers = this.specialOffers[bandDescription]
          if (offers) {
            result = true
          }
        }
        return result
      }
    },
    methods: {
      formatPhoneNumber
    },
  })
</script>
<style scoped>
.adjustIcon {
    margin-left: 10px;
}
h4 {
  color: #717272;
  line-height: 1;
}
</style>
