<template>
  <div>
    <simple-modal
      v-if="show"
      :framed="framed"
      @closeModal="closeModal">
      <div style="color: black">
        <img
          class="brand-logo"
          :src="offers[0].businessLogo"
          alt="business logo" />
        <div
          v-for="(offer, i) in offers"
          :key="i">
          <div
            style="margin-top: 1em"
            class="special-offer-popup"
            v-html="offer.title"></div>
          <a
            class="special-offer-popup"
            :href="viewOfferLink"
            target="_parent">View Offer</a>
        </div>
        <div style="margin-top: 1.5em">
          <a
            class="btn sm schedule-cta"
            :style="globalTheme.button"
            :href="viewOfferLink"
            target="_parent">View All Offers</a>
        </div>
      </div>
    </simple-modal>
    <a @click="showModal" tabindex="0">{{ offerButtonLabel }}</a>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      offers: {
        type: Array
      },
      offerButtonLabel: {
        type: String
      }
    },
    data() {
      return {
        show: false,
        viewOfferLink: ''
      }
    },
    computed: {
      framed(): boolean {
        return this.$store.state.context.framed
      }
    },
    methods: {
      showModal() {
        if (this.framed) {
          try {
            window.scrollTo(0, 0)
            window.parent.parent.scrollTo(0, 0)
          } catch {
            parent.postMessage('snap_to_top', '*')
          }
        }
        this.show = true
      },
      closeModal() {
        this.show = false
      }
    },
    created() {
      const urlParams = window.location.pathname.split('/')
      let offerUrl = `${window.location.origin.replace('eyedoclocator', 'member')}/${urlParams[1]}/${urlParams[2]}/private/special-offers`
      if (offerUrl.indexOf('eyemedvisioncare.') === -1) {//vanity url like aetnavision.com, not at eyemedvisioncare domain
        offerUrl = offerUrl.replace('member.', 'www.') // for memberweb: Production vanity url start with www. rather than member. UAT vanity still memberashuat.
      }
      this.viewOfferLink = offerUrl
    }
  })
</script>
<style>
  .special-offer-popup {
    text-transform: none;
    font-weight: normal;
    font-family: "Nexa Book", Helvetica, sans-serif;
    line-height: 1;
  }
  a.special-offer-popup {
    text-decoration: underline;
    font-weight: bold;
  }
</style>
