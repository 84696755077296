<template>
  <div>
    <div class="button-wrap">
      <special-offer-link
        v-if="hasSpecialOffer && isPrivate"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#ffffff !important' } : globalTheme.buttonWhiteBordered"
        class="btn sm special-offer-link"
        :class="showPlusProviderSideBar ? 'no-white-space' : ''"
        :offers="offers"
        :offer-button-label="offerButtonLabel"
        :provider="provider"
        :labels="labels"
      />
      <special-offer-link-independent
        v-if="hasSpecialOfferIndependent"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#ffffff !important' } : globalTheme.buttonWhiteBordered"
        class="btn sm special-offer-link"
        :class="showPlusProviderSideBar ? 'no-white-space schedule-appointment' : ''"
        :offer-button-label="offerButtonLabel"
        :offer-popup-independent="offerPopupIndependent"
      />
      <schedule-link
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#ffffff !important' } : globalTheme.button"
        class="btn sm schedule-cta"
        :class="showPlusProviderSideBar ? 'schedule-appointment' : ''"
        :provider="provider"
        :labels="labels"/>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import ScheduleLink from '@atoms/ScheduleLink.vue'
  import SpecialOfferLink from '@atoms/SpecialOfferLink.vue'
  import { SearchResultCardLabels, Provider } from '@/types'
  import SpecialOfferLinkIndependent from '@/components/atoms/SpecialOfferLinkIndependent.vue'
  export default defineComponent({
    components: {
      ScheduleLink,
      SpecialOfferLink,
      SpecialOfferLinkIndependent
    },
    props: {
      drawerOpen: {
        required: true,
        type: Boolean
      },
      provider: {
        required: true,
        type: Object as () => Provider
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      },
      hasSpecialOffer: {
        type: Boolean
      },
      hasSpecialOfferIndependent: {
        type: Boolean
      },
      offers: {
        type: Array
      },
      offerButtonLabel: {
        type: String
      },
      offerPopupIndependent: {
        type: String
      },
      showPlusProviderSideBar: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        isPrivate: this.$store.getters['context/packageId']
      }
    },
    computed: {
      isValidTierName() {
        const tierName = this.provider.tierName
        return (tierName === 'Walmart' || tierName === 'Costco')
      },
      isDiscountOptOut() {
        const locPreferences = this.provider.locationPreference
        return locPreferences.discountOptOut === 'true'
      }
    },
    methods: {
      toggleCollapse(): void {
        this.$emit('toggleCollapse')
      },
    }
  })
</script>
<style>
  @media(min-width: 768px) {
    .special-offer-link {
      line-height: 2.5 !important;
    }
  }
</style>
<style scoped>
  .no-white-space {
    white-space: nowrap !important;
    padding-left: 15px !important;
  }
  .schedule-appointment {
    max-width: 50% !important;
  }
</style>
