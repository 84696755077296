<template>
  <div class="desktop">
    <div container>
      <div grid="row wrap justify-start flex-end">
        <logo
          class="height-100"
        />
        <div column>
          <div
            grid="row wrap center"
            style="height:100%">
            <div column="xs-12 s-12">
              <div class="log-search-wrap">
                <domain-selector  v-if="isDev"/>
                <language-selector
                  :language-select="languageSelect"
                  class="_mlxs language-selector"/>
                <font-size-select
                  class="_mlxs"
                  :selected-font-size="selectedFontSize"
                  :theme="globalTheme.color"
                  @fontSizeSelected="selectFontSize"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  import DomainSelector from '@molecules/DomainSelector.vue'
  import LanguageSelector from '@molecules/LanguageSelector.vue'
  import FontSizeSelect from '@molecules/FontSizeSelector.vue'
  import Logo from '@atoms/Logo.vue'
  import  { isMock, isDev }  from '@/../env.config'
  
  import  { Select }  from '@/types/forms'

  export default defineComponent({
    name: 'HeaderDesktop' as string,
    components: {
      Logo,
      FontSizeSelect,
      DomainSelector,
      LanguageSelector
    },
    props:{
      languageSelect: {
        required: true,
        type: Object
      },
      selectedFontSize: {
        required: true,
        type: String
      },
    },
    data() {
      return { isDev: isMock || isDev }
    },
    methods: {
      selectFontSize(val): void {
        this.$emit('fontSizeSelected', val)
      }
    },
  })
</script>
<style scoped>
select {
  cursor:pointer;
}
</style>
