<template>
  <div>
    <label
      for="language-selector"
      class="visuallyhidden">{{label}}</label>
    <select
      id="language-selector"
      v-model.trim="selectedLanguage.value"
      name="language-selector"
    >
      <option
        v-for="(option, i) in selectedLanguage.options"
        :key="i"
        :value="option.value"
      >{{ option.label }}</option>
    </select>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { cloneDeep } from 'lodash'
  import { ResponseMessagesFetchPayload } from '@/types'
  export default defineComponent({
    name: 'LanguageSelector',
    props: {
      languageSelect: {
        required: true,
        type: Object
      }
    },
    data() {
      return {
        selectedLanguage: cloneDeep(this.languageSelect)
      }
    },
    computed: {
      label(): string {
        return this.selectedLanguage.value === 'en' ? 'select your language' : 'elige tu idioma'
      }
    },
    watch: {
      selectedLanguage: {
        handler(selectedLanguage) {
          this.changeLanguage(selectedLanguage)
        },
        deep: true
      }
    },
    methods: {
      changeLanguage(selectedLanguage) {
        this.$store.dispatch('responseMessages/getServiceresponseMessages',
        { 
          lang: selectedLanguage.value || 'en', 
          test: this.$route.query.content === 'test' 
        } as ResponseMessagesFetchPayload )
          .then(() => {
            this.$store.commit(
              'locale/changeSelectedLanguage',
              selectedLanguage.value
            )
          })
      }
    }
  })
</script>
