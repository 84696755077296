<template>
  <div class='frm-toggle'>
  <ValidationProvider
    v-slot="{field, errors }"
    :name="id"
    :rules="validations"
    :value="modelValue"
    tag="div"
  >
    <div
      class="scroll-anchor"
      :class="{'invalid-anchor' : errors.length > 0}" />
    <p
      class="group-label"
      :class="{'invalid' : errors.length > 0}">
      {{ label }}
      <tool-tip
        v-if="tooltipContent"
        :message="tooltipContent"
        class="_mlxxs"
      />
      <span
        v-if="(errors.length > 0)"
        class="lips validation _mlxxs"
      >* {{ validationMessage || errors[0] }}</span>
    </p>
    <div style="display: flex;">
    <div
      v-for="(option, i) in options"
      :key="`input_${i}_${option.value}_${id}`" 
      class="flex-wrap _mbs align-radio-btn">
      <input
        v-bind="field" 
        :checked="modelValue === option.value"
        :id="`${i}_${option.value}_${id}`"
        :value="option.value" 
        :disabled="disabled"
        :class="[{'radio-as-button' : asButton}, {error: errors.length > 0}]"
        type="radio"
        :name="label"
        @input="updateValue"
        @focus="$emit('focus')"
        @blur="$emit('blur')"/>
      <label
        v-if="asButton"
        :style="''+option.value === ''+modelValue ? globalTheme.button : globalTheme.buttonWhiteBordered"
        :for="`${i}_${option.value}_${id}`"
        class="_mrs"
        :class="{disabled:disabled}">
        {{ option.label }}
      </label>
      <label
        v-else
        style="margin-bottom:0"
        :for="`${i}_${option.value}_${id}`"
        class="_mrs"
        :class="{disabled:disabled}">
        {{ option.label }}
      </label>
    </div>
  </div>
  </ValidationProvider>
</div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { Option } from '@/types'
  import ToolTip from './ToolTip.vue'

  export default defineComponent({
    components: { ToolTip },
    props: {
      modelValue: {
        type: [String , Boolean],
        default: ''
      },
      options: {
        type: Array as () => Option[],
        default: undefined
      },
      id: {
        type: String as () => string,
        default: ''
      },
      label: {
        type: String as () => string,
        default: ''
      },
      validations: {
        type: String,
        default: ''
      },
      validationMode: {
        type: String as () => string,
        default: 'eager'
      },
      validationMessage: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      tooltipContent: {
        type: String as () => string,
        default: undefined
      },
      asButton: {
        type: Boolean,
        default: false
      },
    },
    methods:{
      updateValue(e) {
        let value= e.target.value; 
        if(value === 'true' || value === 'false'){
          value= e.target.value === 'true';
        }
        this.$emit('update:modelValue', value)
      }
    },
  })
</script>
<style lang="scss" scoped>
label.disabled {
  text-decoration: line-through
}
.align-radio-btn{
   display: flex;
   align-items: center;
}
</style>
