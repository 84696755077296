<template>
  <div class="card default-card">
    <slot />
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  export default defineComponent({
    name: 'card' as string
  })
</script>
