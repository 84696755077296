<template>
  <a
    :href="`tel:${phoneNumber}`"
    class="inline-caret-link call-link mobile hide-print">
    {{label}} <span class="sr-only">in new tab</span>
    <font-awesome-icon
      :icon="['fas', 'caret-right']"
      class="caret _mlxxs"/>
  </a>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      phoneNumber: {
        required: true,
        type: String
      },
      label: {
        required: true,
        type: String
      }
    },
  })
</script>
