<template>
  <span
    class="tiered-provider-label"
    :style="styleOverride ? styleOverride : globalTheme.colorTieredMapMarkerDark"
  >
    <app-inline-svg
      :src="tieredProviderLabelIcon"
      :style="styleOverride ? styleOverride : globalTheme.colorTieredMapMarker"
    />
    <span><strong>{{ label ? label : tieredProviderLabel }}</strong></span>
  </span>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { mapState } from 'vuex'
  export default defineComponent({
    props: {
      label: {
        required: false,
        type: String
      },
      styleOverride: {
        type: Object as () => {}
      }
    },
    computed: {
      ...mapState({
        tieredProviderLabelIcon(state: any): string {
          return state.globals.microsite.icons.searchResultCards.tieredProviderSvg
            .url
        },
        tieredProviderLabel(state: any): string {
          //@ts-ignore
          const spanishLocale = this.$route.params.lang === 'es'
          const label = state.globals.microsite.icons.searchResultCards
            .tieredProviderLabel
          if (label) return label
          return spanishLocale ? 'Proveedor PLUS' : 'PLUS Provider'
        }
      })
    }
  })
</script>
