<template>
  <div v-if="services || isConnecticut">
    <hr />
    <p class="label">
      <app-inline-svg
        :src="icon"
        :style="globalTheme.colorSecondary"/>
      <strong>{{label}}</strong>
    </p>
    <p>{{services}}</p>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider,SearchResultCardLabels } from '@/types'
  import { formatSemiColonList } from '@/ts/helpers/search-providers.helpers.ts'
  import IsConnecticut from '@/mixins/IsConnecticut.ts'

  export default defineComponent({
    mixins: [IsConnecticut],
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      label: {
        required: true,
        type: String
      },
      icon: {
        required: true,
        type: String
      }
    },
    computed: {
      services(): string|undefined {
        return formatSemiColonList(this.provider.locationPreference.services)
      }
    }
  })
</script>
