<template>
  <section
    aria-label="featured content"
    class="featured-content"
  >
    <div
      class="page-sub-header"
      container
    >
      <div :grid="grid ? grid : 'row wrap'">
        <div :column="column ? column : 'xs-12 s-12 l-12'">
          <!-- <template> -->
            <slot />
          <!-- </template> -->
          <div v-if="haContentSlot">
            <slot name="content"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    name: 'slottedSimpleTitle' as string,
    props: {
      column: {
        required: false,
        type: String
      },
      grid: {
        required: false,
        type: String
      }
    },
    computed: {
      haContentSlot () {
        return !!this.$slots['content']
      },
    }
  })
</script>
