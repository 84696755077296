import { service } from '@/ts/service.methods'
import { InternalErrorResponse, AvailableAppointments, AvailableExamsQuery, AppointmentsResponse } from '@/types'
import {
  generateServiceError,
  handleServiceResponse
} from '@/ts/service.locator.response-handling'
import { availableAppointments } from '@/factories/services/get-appointments'
import { isMock } from '@/../env.config'
import { createMockedExams } from '@/mock-data/available-exams.ts'

export async function getExams(params: AvailableExamsQuery): Promise<AvailableAppointments | InternalErrorResponse> {
  if (isMock) {
      return new Promise((resolve) => {
        setTimeout(() => {
            const exams = createMockedExams(params.startDate, params.endDate) as AppointmentsResponse
            resolve(availableAppointments(exams))
          }, 1000)
      })
  } else {
    return new Promise((resolve, reject) => {
      service
        .get('appointment/appointmentList', params)
        .then(res => {
          if (res.data.messageCode.toUpperCase() === 'APT001') {
            handleServiceResponse(resolve, reject, res, availableAppointments, 'getExamsFactory factory error')
          } else {
            generateServiceError(reject, res, 'get-available-exams.ts/getExams')
          }
        })
        .catch(err =>
          generateServiceError(reject, err, 'get-available-exams.ts/getExams')
        )
    })
  }
}

export async function getSbExams(params: AvailableExamsQuery): Promise<AvailableAppointments | InternalErrorResponse> {
  if (isMock) {
      return new Promise((resolve) => {
        setTimeout(() => {
            const exams = createMockedExams(params.startDate, params.endDate) as AppointmentsResponse
            resolve(availableAppointments(exams))
          }, 1000)
      })
  } else {
    return new Promise((resolve, reject) => {
      service
        .get('sbappointment/appointmentList', params)
        .then(res => {
          if (res.data.messageCode.toUpperCase() === 'APC000') {
            handleServiceResponse(resolve, reject, res, availableAppointments, 'getExamsFactory factory error')
          } else {
            generateServiceError(reject, res, 'get-available-exams.ts/getSbExams')
          }
        })
        .catch(err =>
          generateServiceError(reject, err, 'get-available-exams.ts/getSbExams')
        )
    })
  }
}
