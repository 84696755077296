 import {defineComponent} from 'vue'
import { eventBus } from '@/main'
import { CurrentProvider } from '@/types'
import { trackExternalScheduleLinkClick } from '@/ts/adobe-analytics/index'
export default defineComponent({
  data() {
    return {
      autoAdaptOutBoundLinks: true // we need to be able to turn off auto-capture once in a while
    }
  },
  computed: {
    framed(): boolean {
      return this.$store.state.context.framed
    }
  },
  methods: {
    handleSpecificOutBoundLink(link: string, provider?: CurrentProvider) {
      eventBus.$emit('openConfirmModal')
      eventBus.$on('exitAppConfirmed', () => {
        if (provider) {
          trackExternalScheduleLinkClick(link, provider)
        }
        window.open(link, '_blank')
      })
      eventBus.$on('closeExitConfirmation', () => {
        eventBus.$off('exitAppConfirmed')
      })
      if (this.framed) {
        try {
          window.scrollTo(0, 0)
          window.parent.parent.scrollTo(0, 0)
        } catch {
          parent.postMessage('snap_to_top', '*')
        }
      }
    },
    captureOutBoundLinks(element?: HTMLElement): void {
      this.$nextTick(() => {
        setTimeout(() => {
          const el = element ? element : this.$el
          const outBoundLinks = el.querySelectorAll(
            'a[target="_blank"], a[target="_new"]'
          ) as NodeList
          for (const link of outBoundLinks) {
            link.addEventListener('click', e => {
              e.preventDefault()
              document.body.classList.add('lock-body')
              eventBus.$emit('openConfirmModal')
              eventBus.$on('exitAppConfirmed', () => {
                window.open((link as HTMLLinkElement).href)
              })
              eventBus.$on('closeExitConfirmation', () => {
                eventBus.$off('exitAppConfirmed')
              })
              if (this.framed) {
                try {
                  window.scrollTo(0, 0)
                  window.parent.parent.scrollTo(0, 0)
                } catch {
                  parent.postMessage('snap_to_top', '*')
                }
              }
            })
          }
        }, 0)
      })
    }
  },
  mounted() {
    if (this.autoAdaptOutBoundLinks) {
      this.captureOutBoundLinks()
    }
  }
})
