
//components
import AppSection from '@global/AppSection.vue'
import AppCollapsibleSection from '@global/AppCollapsibleSection.vue'
import SvgCaretRight from '@global/Svg/SvgCaretRight.vue'
import SvgChevronRight from '@global/Svg/ChevronRight.vue'
import AppInlineSvg from '@global/AppInlineSvg.vue'
import SimpleModal from '@global/SimpleModal.vue'
import ServiceError from '@global/ServiceError.vue'
import ServiceSuccessMessage from '@global/ServiceSuccessMessage.vue'
import ResponseMessageNaked from '@global/ResponseMessageNaked.vue'
import LoaderSection from '@global/AppLoaderSection.vue'
import RichText from '@global/RichText.vue'
import LocalizedRouterLink from '@global/RouterLinkLocale.vue'
import FlashNotification from '@global/FlashNotification.vue'
import ResponseFlashNotification from '@global/ResponseFlashNotification.vue'
import AppLoaderIcon from '@global/AppLoaderIcon.vue'

//Global Provider Molecules
import ProviderAddress from '@molecules/Results/ProviderAddress.vue'
import ProviderAccessibility from '@molecules/Results/ProviderAccessibility.vue'
import ProviderProducts from '@molecules/Results/ProviderProducts.vue'
import ProviderBrands from '@molecules/Results/ProviderBrands.vue'
import ProviderContact from '@molecules/Results/ProviderContact.vue'
import ProviderHours from '@molecules/Results/ProviderHours.vue'
import ProviderServices from '@molecules/Results/ProviderServices.vue'
import TieredProviderLabel from '@molecules/TieredProviderLabel.vue'
import SpecialOfferLabel from '@molecules/SpecialOfferLabel.vue'
import {VueMaskDirective} from '../v-mask'
import changeCase from 'change-case'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faSpinner,
    faTimes,
    faCaretLeft,
    faCaretRight,
    faCaretDown,
    faCaretUp,
    faCheck,
    faMinus,
    faChevronDown,
    faPlus,
    faSlidersH,
    faInfoCircle,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
    faCalendarPlus,
    faCalendarTimes,
    faCalendarCheck,
    faCalendar,
    faCircle,
    faDotCircle

} from '@fortawesome/free-regular-svg-icons'

library.add(faSpinner, faTimes, faCaretLeft, faCaretRight, faMinus, faPlus, faChevronDown, faCaretDown, faCaretUp, faSlidersH, faInfoCircle, faExternalLinkAlt, faCheck, faCalendarPlus, faCalendarCheck, faCalendar, faCircle, faDotCircle, faCalendarTimes)

export default function addGlobalComponenets(app: App<Element>) {
    app.component('app-section', AppSection)
    app.component('app-collapsible-section', AppCollapsibleSection)
    app.component('app-svg-caret-right', SvgCaretRight)
    app.component('svg-chevron-right', SvgChevronRight)
    app.component('app-inline-svg', AppInlineSvg)
    app.component('service-error', ServiceError)
    app.component('service-success-message', ServiceSuccessMessage)
    app.component('simple-modal', SimpleModal)
    app.component('response-message-naked', ResponseMessageNaked)
    app.component('app-loader-section', LoaderSection)
    app.component('rich-text', RichText)
    app.component('localized-router-link', LocalizedRouterLink)
    app.component('response-flash-notification', ResponseFlashNotification)
    app.component('flash-notification', FlashNotification)
    app.component('app-loader-icon', AppLoaderIcon)

    app.component('provider-address', ProviderAddress)
    app.component('provider-accessibility', ProviderAccessibility)
    app.component('provider-products', ProviderProducts)
    app.component('provider-brands', ProviderBrands)
    app.component('provider-contact', ProviderContact)
    app.component('provider-hours', ProviderHours)
    app.component('provider-services', ProviderServices)
    app.component('tiered-provider-label', TieredProviderLabel)
    app.component('special-offer-label', SpecialOfferLabel)
    app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$changeCase = changeCase
dom.watch()
app.directive('mask',VueMaskDirective)
}
