<template>
  <app-loader-section
    v-if="loading"
    whole-height />
  <div
    role="main"
    v-else-if="page"
    :class="{'no-directions-listed': !directionsFetched}"
    class="directions _mts _mbm">
    <app-inline-svg
      v-for="(marker, i) in renderedWayPointMarkers"
      :id="`custom_marker_${i}`"
      :key="i"
      style="display:none;"
      :src="marker"
      :style="provider.tieredLocation ? globalTheme.colorTieredMapMarker : globalTheme.color"/>
    <response-flash-notification
      v-if="providerSearchError"
      class="_mbs"
      :error-obj="providerSearchError"/>
    <app-section
      v-else-if="provider"
      grid="stretch"
      class="_p0 google-directions">
      <div
        column="xs-12 s-12 m-6"
        class="get-directions-column">
        <h1
          :style="globalTheme.colorBlack"
          class="_mt0 _mbs">{{page.getDirectionsLabels.pageTitle}}</h1>
        <div class="button-wrap print-email _mbs">
          <print-button
            :print-icon="icons.printSvg.url">
            {{page.printButtonLabel}}
          </print-button>
          <button
            class="sm white bordered with-svg btn-email"
            :style="globalTheme.buttonWhiteBorderedSecondary"
            @click="toggleEmailForm">
            <app-inline-svg
              class="_mrxxs btn-svg"
              :src="icons.emailSvg.url"
              :style="globalTheme.colorSecondary"/>
            {{page.emailButtonLabel}}
            <font-awesome-icon
              class="_mlxxs fa-w-10"
              :icon="emailOpen ? ['fas', 'caret-up'] : ['fas', 'caret-down']"/>
          </button>
        </div>
        <app-collapsible-section
          class="_mbs"
          hide-header
          :collapsed="!emailOpen">
          <email-directions-form
            :provider="provider"
            :submit-label="page.getDirectionsLabels.emailInputLabels.submitButton"
            :start-address-label="page.getDirectionsLabels.emailInputLabels.emailAddress"
            @closeDrawer="closeEmailForm"/>
        </app-collapsible-section>
        <ValidationObserver
          ref="observer">
          <form
            ref="directions_form"
            class="_mbxs"
            @submit.prevent="submit">
            <form-radio
              id="travel_mode"
              v-model="travelMode"
              :options="travelOptions"
              :label="page.getDirectionsLabels.inputLabels.travelMode"
            />
            <form-input
              id="origin_address"
              v-model="originAddress"
              :label="page.getDirectionsLabels.inputLabels.startAddress"
              validations="required"
              :validation-mode="validationChecked ? 'aggressive' : 'passive'"
              class="_mbs" />
            <form-input
              id="destination_address"
              v-model="destinationAddress"
              :label="page.getDirectionsLabels.inputLabels.endAddress"
              disabled
              class="_mbs" />
            <div class="button-wrap">
              <button
                class="sm"
                :disabled="fetchingDirections"
                :style="globalTheme.button"
                type="submit">
                {{page.getDirectionsLabels.inputLabels.getDirections}}
              </button>
              <a
                class="btn sm"
                :href="generateMapLink"
                :style="globalTheme.buttonWhiteBordered"
                @click.prevent="validateThenNavigate">
                {{page.getDirectionsLabels.inputLabels.openInNewGoogleMap}} <span class="sr-only">in new window</span>
                <font-awesome-icon
                  icon="external-link-alt"
                  aria-label="Open in new tab"
                  class="_mlxs"
                  :style="[globalTheme.color, globalTheme.fill]"
                />
              </a>
            </div>
          </form>
        </ValidationObserver>
        <div
          class="directions"
          aria-live="polite">
          <app-loader-section v-if="fetchingDirections"/>
          <response-flash-notification
            v-if="directionsError"
            class="_mbs"
            :error-obj="directionsError"/>
          <div
            v-else
            :key="directionsKey"
            ref="directionsList"
            class="_mbs directions-list" />
        </div>
      </div>
      <div
        column="xs-12 s-12 m-6"
        class="map-and-provider-info-column">
        <div class="map" role="application">
          <google-map-single-provider
            v-if="!directionsFetched && provider"
            :provider="provider"
            class="directions-map directions-page" />
            <GMapMap
            v-show="directionsFetched"
            :key="`map_${directionsKey}`"
            ref="directionsMap"
            class="directions-map directions-page"
            :center="{lat:38.850033, lng:-87.6500523}"
            :zoom="4">
          </GMapMap>
        </div>
        <provider-side-bar
          class="directions-page"
          :route-distance="routeDistance"
          :provider="provider"
          :labels="page.resultCard"/>
      </div>
    </app-section>
    <app-section
      v-if="page.disclaimer"
      class="_pt0 disclaimer">
      <div column="xs-12 s-12">
        <rich-text
          :html="page.disclaimer"
          class="_p0"/>
      </div>
    </app-section>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import GetPage from '@/mixins/GetPage'
  import { getDirections } from '@/ts/service-helpers/maps/get-directions'
  import { createProviderGoogleDestination } from '@/ts/helpers/search-providers.helpers'
  import { SearchResultsPageLabels, DirectionsRequest, ServiceResponseMessage, Provider, ProviderAddress, Option, CurrentProvider } from '@/types'
  import ProviderSideBar from '@organisms/Directions/ProviderSideBar.vue'
  import GoogleMapSingleProvider from '@molecules/GoogleMapSingleProvider.vue'
  import PrintButton from '@atoms/PrintButton.vue'
  import FormInput from '@molecules/Form/FormInput.vue'
  import FormRadio from '@molecules/Form/FormRadio.vue'
  import EmailDirectionsForm from '@organisms/Directions/EmailDirectionsForm.vue'
  import { _prepareQueryString } from '@/site.config'
  import { mapState } from 'vuex'
  import { generateIconUrl } from '@/ts/helpers/map-svg'
  const name = 'directions'
  function setOriginAddress(provider: CurrentProvider|undefined): string {
    if (!provider) return ''
    return provider.searchedZipCode && provider.searchedZipCode.toLowerCase() !== 'my location' ? provider.searchedZipCode : ''
  }
  export default defineComponent({
    name: name,
    components: {
      ProviderSideBar,
      GoogleMapSingleProvider,
      FormInput,
      FormRadio,
      EmailDirectionsForm,
      PrintButton
    },
    mixins: [GetPage],
    data() {
      return {
        validationChecked:false,
        autoAdaptOutBoundLinks: false,
        page: null as SearchResultsPageLabels|null,
        directionsError: null as null | ServiceResponseMessage,
        providerSearchError: null as null | ServiceResponseMessage,
        provider: this.$store.state.currentProvider.currentProvider as  CurrentProvider|undefined,
        originAddress: setOriginAddress(this.$store.state.currentProvider.currentProvider),
        destinationAddress: createProviderGoogleDestination(this.$store.state.currentProvider.currentProvider)   as string,
        loading: true,
        directionsFetched: false,
        fetchingDirections: false,
        travelMode: 'DRIVING' as 'DRIVING' | 'BICYCLING' | 'TRANSIT' | 'WALKING',
        directionsKey: 0,
        emailOpen: false,
        routeDistance: null as null | string,
        renderedWayPointMarkers: [] as string[]
      }
    },
    watch:{
      selectedLanguage: {
        handler(languageSelect) {
       setTimeout(() => {
        window.location.reload()
       }, 100)
      },
        deep: true
      },
    },
    computed: {
      travelOptions(): Option[] {
        const travelOptionLabels = (this.page as any).getDirectionsLabels.inputLabels
        return [
          { value: 'DRIVING', label: travelOptionLabels.driving },
          { value: 'BICYCLING', label: travelOptionLabels.bicycling },
          { value: 'TRANSIT', label: travelOptionLabels.transit },
          { value: 'WALKING', label: travelOptionLabels.walking }
        ]
      },
      ...mapState({
        icons: (state: any) => {
          return {
            ...state.globals.microsite.icons.general
          }
        }
      }),
      generateMapLink(): string {
        const provider = this.provider as Provider
        const address = provider.address as ProviderAddress
        const queryPayload = {
          name: provider.businessName,
          ...address.city && { city: address.city },
          ...address.state && { state: address.state },
          ...address.streetAddress1 && { address: address.state }
        }
        const directionsLink = `https://www.google.com/maps/dir/?api=1&origin=${this.originAddress}&destination=${this.destinationAddress}&travelmode=${this.travelMode.toLowerCase()}`
        return directionsLink
      },
      clientID(): string {
        return (this as any).$store.state.globals.microsite.clientId.toLowerCase()
      },
      selectedLanguage(): string {
        return (this as any).$store.state.locale.languageSelect.value
      },
      localizedRoute(): string {
        return `/${this.clientID}/${this.selectedLanguage}`
      }
    },
    methods: {
      generateCustomWayPointMarkers(): void {
        const startIcon = (this as any).page.getDirectionsLabels.directionsIconStart
        const endIcon = (this as any).page.getDirectionsLabels.directionsIconEnd
        if (!startIcon || !endIcon) return
        this.renderedWayPointMarkers = [startIcon.url,endIcon.url]
      },
      generateColoredWayPointMarkers(): string[]|undefined {
        if (!this.renderedWayPointMarkers.length) return
        const startIcon = this.$el.querySelector('#custom_marker_0') as SVGSVGElement
        const endIcon = this.$el.querySelector('#custom_marker_1') as SVGSVGElement
        const startIconUrl = generateIconUrl(startIcon) as string | undefined
        const endIconUrl = generateIconUrl(endIcon) as string | undefined
        if (!startIconUrl || !endIconUrl) return
        return [startIconUrl, endIconUrl]
      },
      createDirectionsRequest(): DirectionsRequest {
        return {
          origin: this.originAddress,
          destination: this.destinationAddress,
          travelMode: this.travelMode
        }
      },
      colorDirectionsLinks(): void {
        const links = (this.$refs.directionsList as HTMLElement).querySelectorAll('a:not(.button)')
        const colorLinks = (linkSet) => {
          if (linkSet.length) {
            [...links].map(link => {
              (link as HTMLElement).style.color = (this as any).darken(this.$store.state.globals.microsite.primaryColor)
            })
          }
        }
        colorLinks(links)
      },
      async validateThenNavigate(e): Promise<void> {
        this.validationChecked=true
        const isValid = await (this.$refs.observer as any).validate()
        if (isValid) {
          window.open((this as any).generateMapLink, '_blank')
        } else {
          const firstErrorEl: HTMLInputElement|null = this.$el.querySelector('.error')
          if (firstErrorEl) {
            firstErrorEl.focus()
          }
        }
      },
      async submit(): Promise<void> {
        this.validationChecked=true
        const isValid = await (this.$refs.observer as any).validate()
        if (isValid) {
          this.getProviderDirections()
        } else {
          const firstErrorEl: HTMLInputElement|null = (this.$refs.directions_form as HTMLElement).querySelector('.error')
          if (firstErrorEl) {
            firstErrorEl.focus()
          }
        }
      },
      getProviderDirections(): void{
        this.fetchingDirections = true
        this.directionsFetched = false
        this.directionsError = null
        this.directionsKey ++
        this.$nextTick(() => {
          //@ts-ignore
          this.$refs.directionsMap.$mapPromise.then((map) => {
            const google = window.google
            function makeMarker( position, icon ) {
              new google.maps.Marker({
                position: position,
                map: map,
                icon: icon
              })
            }
            const directionsDisplay = new google.maps.DirectionsRenderer({
              map: map,
              panel: this.$refs.directionsList
            })
            const directionsRequestObject = this.createDirectionsRequest()
            getDirections(directionsRequestObject, google)
              .then((res) => {
                directionsDisplay.setDirections(res)
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.colorDirectionsLinks()
                    const customWayPointMarkers = this.generateColoredWayPointMarkers()
                    if (customWayPointMarkers) {
                      directionsDisplay.setOptions( { suppressMarkers: true } )
                      const leg = res.routes[ 0 ].legs[ 0 ]
                      makeMarker( leg.start_location,customWayPointMarkers[0])
                      makeMarker( leg.end_location,customWayPointMarkers[1])
                      const directionsListStart = this.$el.querySelector('button[data-leg-index="0"]')
                      const directionsListEnd = this.$el.querySelector('button[data-leg-index="1"]')
                      const listIconStart = directionsListStart!.querySelector('img.adp-marker2') as HTMLImageElement
                      const listIconEnd = directionsListEnd!.querySelector('img.adp-marker2') as HTMLImageElement
                      listIconStart!.setAttribute('alt',"Starting destination marker")
                      listIconEnd!.setAttribute('alt',"Ending destination marker")
                      listIconStart!.src=customWayPointMarkers[0]
                      listIconEnd!.src=customWayPointMarkers[1]
                    }
                  },0)
                })
                this.directionsFetched = true
                this.routeDistance = res.routes[0].legs[0].distance.text
              })
              .catch((serviceError) => {
                this.directionsError = serviceError
              })
              .finally(() => {
                (this as any).emitResizeEvent()
                this.fetchingDirections = false
              })
          })
        })
      },
      toggleEmailForm(): void {
        this.emailOpen = !this.emailOpen
      },
      closeEmailForm(): void {
        this.emailOpen = false
      }
    },
    async created(): Promise<void> {
      await (this as any).getStackPageByName('results')
      this.generateCustomWayPointMarkers()
      this.loading = false
      if (!this.provider) {
        this.$router.push({ path: (this as any).localizedRoute })
      }
      await (this as any).emitResizeEvent()
    }
  })
</script>
