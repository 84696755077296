<template>
  <ValidationObserver 
    ref="emailObserver">
    <response-flash-notification
      v-if="responseMessageObject"
      :error-obj="responseMessageObject"
      @closeNotification="resetForm"/>
    <app-loader-section
      v-else-if="submitting"
      :color="globalTheme.colorSecondary"
      class="_pt0 _pb0"/>
    <form
      v-else
      @submit.prevent="sendEmail">
      <form-input 
        id="recipient_email"
        v-model="recipientEmail"
        :label="startAddressLabel"
        validations="required|email"
        type="email"
        :validation-mode="validationChecked ? 'aggressive' : 'passive'"
        class="_mbs" />
      <div class="button-wrap">
        <button
          class="sm"
          :style="globalTheme.buttonSecondary"
          type="submit">
          {{submitLabel}}
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { emailDirections } from '@/ts/service-helpers/services/email-directions.ts'
  import { NewServiceResponseMessage, InternalErrorResponse, EmailDirectionsPayload, Provider, ProviderLocationPreference } from '@/types'
  import FormInput from '@molecules/Form/FormInput.vue'
  import { createProviderGoogleDestination } from '@/ts/helpers/search-providers.helpers.ts'
  import { formatSemiColonList } from '@/ts/helpers/search-providers.helpers.ts'
  export default defineComponent({
    components: { FormInput },
    props: {
      provider: {
        type: Object as ()=> Provider,
        required: true
      },
      startAddressLabel: {
        type: String,
        default: ''
      },
      submitLabel: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        validationChecked: false,
        recipientEmail: '' as string,
        submitting: false,
        responseMessageObject: null as null|NewServiceResponseMessage
      }
    },
    computed: {
      emailDirectionsPayload(): EmailDirectionsPayload {
        const provider = this.provider as Provider
        const locationPreference = provider.locationPreference as ProviderLocationPreference
        const officeHours: string[] = [
          locationPreference.monHours || '',
          locationPreference.tueHours || '',
          locationPreference.wedHours || '',
          locationPreference.thuHours || '',
          locationPreference.friHours || '',
          locationPreference.satHours || '',
          locationPreference.sunHours || '',
        ]
        return {
          toEmailAddress: this.recipientEmail ,
          providerName: provider.businessName || '',
          address: createProviderGoogleDestination(provider) || '',
          officeHours: officeHours.join(',')|| '',
          products: formatSemiColonList(locationPreference.products) || '',
          services: formatSemiColonList(locationPreference.services)|| '',
          brands: formatSemiColonList(locationPreference.brands)|| '',
          disabilityAccomodations: provider.disabilityAccomodations ? provider.disabilityAccomodations.join(', ') : '' || '',
          locationUrl: provider.locationUrl|| '',
          locationEmail: provider.locationEmailAddress|| '',
          clientId: this.$route.params.clientID || '',
        }
      }
    },
    methods: {
      resetForm(): void {
        this.validationChecked = false
        this.responseMessageObject = null
        this.recipientEmail = ''
        this.$emit('closeDrawer')
      },
      async sendEmail(): Promise<void> {
        this.validationChecked = true
        const isValid = await (this.$refs.emailObserver as any).validate()
        if (isValid) {
          this.submitting = true
          emailDirections(this.emailDirectionsPayload)
            .then(res => {
              this.responseMessageObject = res
            })
            .catch(err => {
              this.responseMessageObject = err
            })
            .finally(() => {
              this.submitting = false
            })
        } else {
          const errorEl: HTMLInputElement|null = this.$el.querySelector('.error')
          if (errorEl) {
            errorEl.focus()
          }
        }
      },
    }
  })
</script>
