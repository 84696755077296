<template>
  <div
    v-if="!hide"
    class="card flash-notification _text-center"
    :role="role">
    <h3
      v-if="hasTitle"
      class="h3 title"
      :style="color ? color : globalTheme.colorTieredMapMarkerDark">
      <slot name="title"></slot>
    </h3>
    <slot></slot>
    <slot name="action"></slot>
    <button
      :style="globalTheme.colorBlack"
      class="simple close"
      aria-label="close notification"
      @click="close">
      <font-awesome-icon
        icon="times"
      />
    </button>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { eventBus } from '@/main'
  export default defineComponent({
    props: {
      role: {
        type: String,
        default: 'alert'
      },
      color: {
        type: Object
      }
    },
    data() {
      return {
        hide: false as boolean
      }
    },
    computed: {
      hasTitle(): boolean {
        return !!this.$slots.title
      },
    },
    methods: {
      close(): void {
        this.hide = true
        this.$emit('close')
        eventBus.$emit('iframeResizeEvent')
      }
    }
  })
</script>
