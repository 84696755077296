<template >
  <div
    v-if="!appointments.length"
    class="_pts _pbs _pls _prs bg-offwhite _text-center">
    <template v-if="nextDayWithAppointment">
      {{nextAvailableAppointmentMessage}}
    </template>
    <template v-else>
      {{labels.noAvailableAppointmentMessage}}
    </template>
  </div>
  <div v-else>
    <div
      v-for="(appt, i) in appointments"
      :key="`${appointments.scheduleId}_${i}`"
      :class="this.$store.state.locale.languageSelect.value === 'en' ? 'appointment-selection' : 'flexwrap'"
      class=" _mbxs">
      <div class="info _mrxs">
        <p class="time _mbxs">{{removeLeadingZero(appt.availableTime)}}</p>
        <p>Dr. {{appt.providerLastName}}</p>
      </div>
      <div class="select-wrap">
        <button
          class="tiny"
          :aria-label="`Select ${removeLeadingZero(appt.availableTime)} appointment`"
          :disabled="isSelectedByOtherPatient(appt)||(isAwaitingSelectionByCurrentPatient(appt) && locking)"
          :style="isSelectedByPatient(appt, appointmentsPayload) ? globalTheme.buttonSecondary : globalTheme.buttonWhiteBorderedSecondary"
          @click="addAppointmentToCurrentPatient(appt)">
          <template v-if="!isSelectedByPatient(appt, appointmentsPayload) && !isAwaitingSelectionByCurrentPatient(appt)">{{labels.selectAppointment}}</template>
          <font-awesome-icon
            v-if="isAwaitingSelectionByCurrentPatient(appt) && locking"
            icon="spinner"
            class="_mlxs _mrxs"
            pulse
            aria-label="Loading..."
          />
          <font-awesome-icon
            v-else-if="isSelectedByPatient(appt, appointmentsPayload)"
            icon="check"
            class="_mlxs _mrxs"
            aria-label="selected"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { AvailableAppointment, UserCreatedAppointment, ScheduleADateLabels } from '@/types'
  import { removeLeadingZero, isSelectedByPatient, isAwaitingSelectionByPatient } from '@/ts/helpers/schedule.helpers.ts'
  export default defineComponent({
    props: {
      appointments: {
        required: true,
        type: Array as () => AvailableAppointment[]
      },
      appointmentsPayload: {
        required: true,
        type: Array as () => UserCreatedAppointment[]
      },
      currentPatientIndex: {
        required: true,
        type: Number
      },
      labels: {
        required: true,
        type: Object as () => ScheduleADateLabels
      },
      timeOfDay: {
        required: true,
        type: String
      },
      nextDayWithAppointment: {
        required: false,
        type: String
      },
      locking: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      currentPatientOnPayload():UserCreatedAppointment {
        return this.appointmentsPayload[this.currentPatientIndex]
      },
      nextAvailableAppointmentMessage(): string {
        const message = this.labels.nextAvailableAppointmentMessage
          .replace('${timeOfDay}', this.timeOfDay)
          .replace('$\'timeOfDay\'', this.timeOfDay)
        return `${message} ${this.nextDayWithAppointment}`
      }
    },
    methods: {
      removeLeadingZero,
      isSelectedByPatient,
      isSelectedByCurrentPatient(appt): boolean {
        const currentAppointment = this.currentPatientOnPayload.appointment
        return (currentAppointment !== null && currentAppointment.scheduleId === appt.scheduleId)
      },
      isAwaitingSelectionByCurrentPatient(appt): boolean {
        const condition = this.currentPatientOnPayload.queuedAppointmentId === appt.scheduleId
        return condition
      },
      isSelectedByOtherPatient(appt): boolean {
        const currentAppointment = this.currentPatientOnPayload.appointment
        return (
          this.isSelectedByPatient(appt, this.appointmentsPayload) &&
          !this.isSelectedByCurrentPatient(appt)
        )
      },
      addAppointmentToCurrentPatient(appt: AvailableAppointment): void {
        if (this.isSelectedByPatient(appt, this.appointmentsPayload)) {
          this.$emit('addAppointmentToCurrentPatient', null)
        } else {
          this.$emit('addAppointmentToCurrentPatient', appt)
        }
      }
    }
  })
</script>
