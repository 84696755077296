<template>
  <a
    :href="link.href"
    :style="globalTheme.colorTertiaryDark"
    target="_blank"
    :aria-label="`Open ${link.title} in a new tab`"
    class="box-link">
    <img
      v-if="link.icon"
      :src="link.icon"
      :alt="`${link.title} brand icon`">
    <div class="link-wrap">
      <div class="_text-center">
        {{link.title}}
        <font-awesome-icon
          :icon="['fas', 'caret-right']"
          aria-label="previous tab"
          class="caret _mlxxs"/>
      </div>
      <slot></slot>
    </div>
  </a>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { BoxIconLink } from '@/types'
  export default defineComponent({
    props: {
      link: {
        required: true,
        type: Object as ()=> BoxIconLink
      }
    }
  })
</script>
