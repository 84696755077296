<template>
  <div
    :class="{ collapsed: sectionCollapsed && collapsible }"
    class="collapsible-section"
  >
    <button
      v-if="!hideHeader"
      class="bg-white section-header simple drawer-toggle"
      :aria-controls="uid"
      :aria-expanded="!sectionCollapsed"
      :aria-labelledby="title ? `toggle ${title} ${isSpanish() ? 'región': 'region'}` : undefined"
      @click.prevent="sectionCollapseToggled">
      <div>
        <div
          v-if="hasTitleSlot"
          :id="`${uid}_title`"
          class="sr-only"><slot name="header-text"></slot></div>
        <slot name="header-text"></slot>
        <slot name="header-content"></slot>

        <div class="actions">
          <slot
            :collapsed="sectionCollapsed"
            name="actions-left"
          ></slot>
          <slot
            :collapsed="sectionCollapsed"
            name="actions-right"
          ></slot>
          <slot
            name="edit-label"
            :collapsed="sectionCollapsed">
          </slot>
          <collapse-button
            v-if="collapsible && !hideToggle"
            ref="collapseButton"
            decorative
            :initially-collapsed="sectionCollapsed"
            class="_bl0"
            @onToggle="sectionCollapseToggled"
          />
        </div>
      </div>
    </button>
    <transition
      @enter="enter"
      @leave="leave"
    >
      <template v-if="destroyable">
        <div
          v-if="!sectionCollapsed"
          :id="uid"
          role="region"
          :aria-labelledBy="hasTitleSlot ? `${uid}_title` : undefined"
          class="drawer"
        >
          <div class="content">
            <slot :collapsed="sectionCollapsed" />
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-show="!sectionCollapsed"
          :id="uid"
          role="complementary"
          :aria-labelledBy="hasTitleSlot ? `${uid}_title` : undefined"
          class="drawer"
        >
          <div class="content">
            <slot :collapsed="sectionCollapsed" />
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import Velocity from 'velocity-animate'
  import CollapseButton from '@atoms/CollapseButton.vue'
  import { eventBus } from '@/main'
  import { v4 as uuidv4 } from 'uuid'

  export default defineComponent({
    name: 'collapsible-section',
    components: {
      CollapseButton
    },
    props: {
      destroyable: {
        required: false,
        default: false,
        type: Boolean
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      collapsible: {
        type: Boolean,
        default: true
      },
      hideHeader: {
        type: Boolean,
        default: false
      },
      hideToggle: {
        type: Boolean,
        default: false
      },
      providedUID: {
        type: String,
        required: false
      },
      forceFocus: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        sectionCollapsed: this.collapsed as boolean,
        uid: this.providedUID ? this.providedUID : `UID_${uuidv4()}`,
        title: null as string|null
      }
    },
    computed: {
      closeLabel(): string {
        return 'Close'
      },
      openLabel(): string {
        return 'Open'
      },
      hasTitleSlot () {
        return !!this.$slots['header-text']
      },
    },
    watch: {
      collapsed: {
        handler(newVal: boolean) {
        this.sectionCollapsed = newVal
    },
    deep: true
  },
    },
    methods: {
      expand(): void {
        this.sectionCollapsed = false
      },
      collapse(): void {
        this.sectionCollapsed = true
      },
      sectionCollapseToggled(): void {
        this.sectionCollapsed = !this.sectionCollapsed
        if (this.sectionCollapsed === true) {
          this.$emit('drawerClosed')
        } else {
          this.$emit('drawerOpened')
        }
      },
      getTitle(): void {
        const titleEl: HTMLElement|null = this.$el.querySelector(`#${this.uid}_title`)
        if (!titleEl) return
        const text = titleEl!.innerText
        this.title = text
      },
      focusContents(): void {
        const contentHtmlNode = this.$el.querySelector(`#${this.uid}`) as HTMLElement
        contentHtmlNode.setAttribute('tabindex', '0')
        contentHtmlNode.focus()
        contentHtmlNode.removeAttribute('tabindex')
        if ((contentHtmlNode.clientHeight || contentHtmlNode.offsetHeight || contentHtmlNode.scrollHeight) > window.innerHeight) {
          const crumbEl: HTMLLIElement|null = document.querySelector('#sub-nav-breadcrumb')
          const crumbHeight = crumbEl ? crumbEl.clientHeight || crumbEl.offsetHeight || crumbEl.scrollHeight : 0
          const header: HTMLElement|null = this.$el.querySelector('.section-header')
          const elHeight = header ? header.clientHeight || header.offsetHeight || header.scrollHeight : 0
          const total = crumbHeight+elHeight
          setTimeout(() => {
            window.scrollBy(0,total * -1)
          }, 1)
        }
      },
      enter(el: HTMLElement, done: boolean): void {
        // @ts-ignore
        Velocity(
          el,
          'slideDown',
          { duration: 150, easing: 'easeInBack' },
          { complete: done }
        ).then(() => {
          eventBus.$emit('iframeResizeEvent')
          if (this.forceFocus){
            this.focusContents()
          }
        })
      },
      leave(el: HTMLElement, done: boolean): void {
        // @ts-ignore
        Velocity(
          el,
          'slideUp',
          { duration: 150, easing: 'easeInBack' },
          { complete: done }
        ).then(() => {
          eventBus.$emit('iframeResizeEvent')
        })
      }
    },
    mounted() {
      this.getTitle()
    }
  })
</script>
