<template>
  <div class="_mbxs doctor-info">
    <button
      role="button"
      class="simple collaps-toggle"
      :style="globalTheme.colorBlack"
      :aria-expanded="expanded"
      :aria-controls="uid"
      :aria-label="`${drawerOpen ? 'hide' : 'show'}` + ' doctor infomation for ' + `${doctor.firstName} ${doctor.middleName} ${doctor.lastName} ${doctor.professionalDesignation}`"
      @click="toggleCollapse">
      <collapse-button
        :decorative="true"
        class="_mrxs"
        :initially-collapsed="!drawerOpen"
        color-secondary/>
      <strong>
        <doctor-name :doctor="doctor"/>
      </strong>
    </button>
    <app-collapsible-section
      force-focus
      class="info-rest _mlxs"
      hide-header
      :provided-u-i-d="uid"
      :collapsed="!drawerOpen">
      <doctor-info
        :hide-accept-new-patient="hideAcceptNewPatient"
        :doctor="doctor"
        :provider="provider"/>
    </app-collapsible-section>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Doctor, Provider } from '@/types'
  import CollapseButton from '@atoms/CollapseButton.vue'
  import DoctorName from '@atoms/Results/DoctorName.vue'
  import DoctorInfo from '@atoms/Results/DoctorInfo.vue'
  import { v4 as uuidv4 } from 'uuid'

  export default defineComponent({
    components: {
      CollapseButton,
      DoctorName,
      DoctorInfo
    },
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      doctor: {
        required: true,
        type: Object as () => Doctor
      },
      hideAcceptNewPatient: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        drawerOpen: false,
        uid: `UID_${uuidv4()}`,
      }
    },
    computed: {
      expanded(): string {
        return this.drawerOpen.toString()
      },
    },
    methods: {
      toggleCollapse(): void {
        this.drawerOpen = !this.drawerOpen
      },
    }
  })
</script>
