<template>
  <simple-modal
    :framed="framed"
    @closeModal="closeConfirmation">
    <div class="flex-wrap --column confirm-exit-content">
      <div
        class="rich-text themed _mbxs"
        :style="globalTheme.colorBlack"
        v-html="microsite.leaveAppConfirmationMessage">
      </div>
      <button
        class="sm _mbxs"
        :style="globalTheme.buttonSecondary"
        @click.once="confirm"
        @keydown.enter.once="confirm">{{microsite.leaveAppButton}}</button>
      <button
        class="sm"
        :style="globalTheme.buttonWhiteBorderedSecondary"
        @click.once="closeConfirmation"
        @keydown.enter.once="closeConfirmation">{{microsite.cancelLeaveButton}}</button>
    </div>
  </simple-modal>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { eventBus } from '@/main'
  import { mapState } from 'vuex'
  export default defineComponent({
    computed: {
      ...mapState({
        microsite: (state: any) => {
          return { 
            ...state.globals.microsite
          }
        }
      }),
      framed(): boolean {
        return this.$store.state.context.framed
      }
    },
    methods: {
      closeConfirmation() {
        eventBus.$emit('closeExitConfirmation')
        document.body.classList.remove('lock-body')
      },
      confirm() {
        eventBus.$emit('exitAppConfirmed')
        this.closeConfirmation()
      }
    }
  })
</script>
