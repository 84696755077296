import { service } from '@/ts/service.methods'
import {
  NewServiceResponseMessage,
  EmailSearchResultsPayload,
  InternalErrorResponse
} from '@/types'
import {
  generateServiceError,
  handleServiceResponse
} from '@/ts/service.locator.response-handling'
import { responseMessageFactory } from '@/factories/services/response-message'
import { isMock } from '@/../env.config'

export async function emailProviders(
  EmailSearchResultsPayload: EmailSearchResultsPayload
): Promise<NewServiceResponseMessage | InternalErrorResponse> {
  if (isMock) {
    return  {
        message: 'The Provider directory has been sent to the given email address. Thank you.',
        messageCode: 'EMD001'
      } as NewServiceResponseMessage
  }
  return new Promise((resolve, reject) => {
    service
      .post('directory/email', EmailSearchResultsPayload)
      .then(res => {
        if (res.data.messageCode === 'EMD001') {
          handleServiceResponse(
            resolve,
            reject,
            res,
            responseMessageFactory,
            'emailProviders error'
          )
        } else {
          generateServiceError(
            reject,
            res,
            'email-search-results.ts/emailProviders'
          )
        }
      })
      .catch(err =>
        generateServiceError(
          reject,
          err,
          'email-search-results.ts/emailProviders'
        )
      )
  })
}
