import { getErrorMessageLabels } from '@/ts/service-helpers/contentstack/get-app-data'
import { ResponseMessagesFetchPayload } from '@/types'

const getDefaultState = () => {
  return { 
    serviceErrors: [],
    serviceSuccessMessages: [],
    responseMessages: undefined
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  resetErrorState(state) {
    state.serviceErrors = []
    state.serviceSuccessMessages = []
  },
  addServiceError(state, serviceError) {
    state.serviceErrors.push(serviceError)
  },
  addServiceSuccessMessage(state, successMessage) {
    state.serviceSuccessMessages.push(successMessage)
  },
  setResponseMessageLabels(state, responseMessages) {
    if (!responseMessages || !responseMessages.errorMessages) return
    const messages = {}
    responseMessages.errorMessages.map(errorMessage => {
      messages[errorMessage.messageCode] = errorMessage.message
    })
    state.responseMessages = messages
  }
}

const actions = {
  async getServiceresponseMessages({ commit }, payload: ResponseMessagesFetchPayload) {
    const responseMessages = await getErrorMessageLabels(payload)
    commit('setResponseMessageLabels', responseMessages)
  },
  clearServiceErrors({ commit }) {
    commit('resetErrorState')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
