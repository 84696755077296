import { service } from '@/ts/service.methods'
import {
  InternalErrorResponse,
  NewServiceResponseMessage,
  EmailDirectionsPayload
} from '@/types'
import {
  generateServiceError,
  handleServiceResponse
} from '@/ts/service.locator.response-handling'
import { responseMessageFactory } from '@/factories/services/response-message'
import { isMock } from '@/../env.config'

export async function emailDirections(
  emailPaylod: EmailDirectionsPayload
): Promise<NewServiceResponseMessage | InternalErrorResponse> {
  if (isMock) {
    return require('@/mock-data/email-directions.ts') as NewServiceResponseMessage
  }
  return new Promise((resolve, reject) => {
    service
      .post('directions/email', emailPaylod)
      .then(res => {
        if (res.data.messageCode === 'EML001') {
          handleServiceResponse(
            resolve,
            reject,
            res,
            responseMessageFactory,
            'emailDirections responseMessageFactoryFactory error'
          )
        } else {
          generateServiceError(
            reject,
            res,
            'email-directions.ts/emailDirections'
          )
        }
      })
      .catch(err =>
        generateServiceError(reject, err, 'search-providers.ts/searchProviders')
      )
  })
}
