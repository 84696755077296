<template>
  <section
    class="four-o-four"
    aria-label="page cannot be found">
    <div container>
      <div grid="row wrap">
        <div
          column="xs-12 s-8 +s-2 l-6 +l-3"
          class="_text-center">
          <app-inline-svg
            :style="[globalTheme.fill, globalTheme.colorForSure]"
            :src="section.imagePath"
            :title="section.imageAlt"
            class="_mbm" />
        </div>
        <div
          column="xs-12 s-12"
          class="_text-center">
          <h1
            class="_mbs"
            :style="globalTheme.color">{{section.title}}</h1>
          <rich-text
            v-if="section.content"
            class="_p0"
            :html="section.content"/>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
interface FourOFourPage {
    title: string
    content: string
    imagePath: string
    imageAlt: string
}
   import {defineComponent} from 'vue'
  export default defineComponent({
    data() {
      return {
        section: {
          title: this.$store.state.globals.microsite.pageNotFound && this.$store.state.globals.microsite.pageNotFound.title ? this.$store.state.globals.microsite.pageNotFound.title : '404 - Page not found',
          content: this.$store.state.globals.microsite.pageNotFound && this.$store.state.globals.microsite.pageNotFound.content ? this.$store.state.globals.microsite.pageNotFound.content : null,
          imagePath: require('@/assets/images/svg/404.svg'),
          imageAlt: 'pair of broken glasses'
        }
      }
    },
  })
</script>
