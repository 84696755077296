<template>
  <div class="logo-container">
    <localized-router-link
      to="/">
      <div
        :style="`background-image: url(${insuranceLogo}); background-color:white;`"
        class="insurance-logo-wrap">
        <img
          :src="insuranceLogo"
          :alt="`${clientId} logo and application home link`"
          class="insurance-logo logo"
        >
      </div>
    </localized-router-link>
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import localizedRouterLink from '../_global/RouterLinkLocale.vue'
  export default defineComponent({
    components:{
      localizedRouterLink
    },
    name: 'logo' as string,
    data() {
      return {
        clientId: this.$store.state.globals.microsite.clientId,
        insuranceLogo: this.$store.state.globals.microsite.logo.url
      }
    },
  })
</script>
