<template>
  <div
    id="mobile-main-nav"
    ref="mobileNav"
    class="mobile">
    <div class="header">
      <logo
        class="height-100"
        insurance="aetna" />
      <language-selector
        :language-select="languageSelect"
        class="_pl0 language-selector"/>
    </div>
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import LanguageSelector from '@molecules/LanguageSelector.vue'
  import Logo from '@atoms/Logo.vue'

  export default defineComponent({
    name: 'HeaderMobile' as string,
    components: {
      Logo,
      LanguageSelector
    },
    props: {
      languageSelect: {
        required: true,
        type: Object
      }
    },
  })
</script>
<style scoped>
.language-selector,
label {
  margin-left: 1rem;
}
label {
  width: 100%;
}
</style>
