<template>
  <header class="main-nav">
    <desktop-header
      :language-select="languageSelect"
      :selected-font-size="selectedFontSize"
      @fontSizeSelected="selectFontSize"/>
    <mobile-header
      :language-select="languageSelect"
      :selected-font-size="selectedFontSize"
      @fontSizeSelected="selectFontSize"/>
  </header>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  import DesktopHeader from './Header/Desktop.vue'
  import MobileHeader from './Header/Mobile.vue'

  export default defineComponent({
    name: 'Header' as string,
    components: {
      DesktopHeader,
      MobileHeader
    },
    props:{
      languageSelect: {
        required: true,
        type: Object
      },
      selectedFontSize: {
        required: true,
        type: String
      },
    },
    methods: {
      selectFontSize(val): void {
        this.$emit('fontSizeSelected', val)
      }
    },
  })
</script>
<style scoped>
select {
  cursor:pointer;
}
</style>
