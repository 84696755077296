<template>
  <div>
    <hr />
    <div grid="row wrap">
      <div
        column="xs-12 s-5 m-6 l-5"
        class="hide-print">
        <p class="label">
          <app-inline-svg
            :src="icon"
            :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondary"/>
          <span><strong>{{label}}</strong>:<br>{{hoursExistCheck(closingTimeToday)}}</span>
        </p>
      </div>
      <div
        column="xs-12 s-7 m-6 l-7"
        class="schedule">
        <div :class="showPlusProviderSideBar ? 'custom-margin-provider-hours' : ''">
          <p
            v-for="(hoursOfOp, i) in formattedHours"
            :key="i">
            <app-inline-svg
              class="print-only"
              :src="icon"
              :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondary"/>
            <span><strong>{{hoursOfOp.days}}</strong>:</span>
            <span class="hours">{{hoursExistCheck(hoursOfOp.hours)}}</span>
          </p>
        </div>
      </div>
      <div
        v-if="isValidTierName"
        class="providerDiv">
        <span v-html="tierNameLabel"></span>
      </div>
      <div
        v-if="isDiscountOptOut"
        class="providerDiv">
        <span v-html="discountLabel"></span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, DayLabels, FormattedHours } from '@/types'
  import { createWeekelyHours } from '@/ts/helpers/provider-hours.helper.ts'

  export default defineComponent({
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      icon: {
        required: true,
        type: String
      },
      label: {
        required: true,
        type: String
      },
      dayLabels: {
        required: true,
        type: Object as () => DayLabels
      },
      hoursUnavailableLabel: {
        required: false,
        type: String
      },
      showPlusProviderSideBar: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        discountLabel: this.$store.state.globals.microsite.noDiscountProviderStatement,
        tierNameLabel: this.$store.state.globals.microsite.wholesaleProviderStatement
      }
    },
    computed: {
      closingTimeToday(): string {
        const day = new Date().toString().substring(0, 3).toLowerCase()
        const openTil = this.provider.locationPreference[`${day}Hours`]
        if (!openTil) return ''
        return openTil.split('-')[1] ? openTil.split('-')[1]  : openTil
      },
      formattedHours():FormattedHours[] {
        const locPreferences = this.provider.locationPreference
        return createWeekelyHours(locPreferences, this.dayLabels, this.$route.params.lang)
      },
      isDiscountOptOut() {
        const locPreferences = this.provider.locationPreference
        return locPreferences.discountOptOut === 'true'
      },
      isValidTierName() {
        const tierName = this.provider.tierName
        return (tierName === 'Walmart' || tierName === 'Costco')
      }
    },
    methods: {
      hoursExistCheck(stringValue: string|undefined|null): string {
        return !stringValue ? this.hoursUnavailableLabel : stringValue
      },
    }
  })
</script>
<style lang="scss" scoped>
.providerDiv{
  width: 100%;
  bottom: 10px;
  right: 10px;
  padding-top: 10px;
  margin-top: 10px;
  font-size: 16px;
}
.providerDiv span{
  float: right;
}

.custom-margin-provider-hours {
  margin-left: -25px;
}
</style>
