<template>

  <app-section
    class="search-tabs _p0"
    aria-label="search tabs">
    <div column="xs-12 s-12">
      <div
        grid="row wrap justify-center"
        role="tablist"
        aria-label="search_page_tabs">
        <template
          v-for="(tab, i) in tabs">
          <button
            v-if="tab"
            :id="`tab_button_${i}`"
            :key="`tab_button_${i}`"
            class="simple tab-option"
            :class="currentTabIndex === i ? 'active' : ''"
            :style="globalTheme[tab.color]"
            column="xs-4 s-4"
            role="tab"
            :tabindex="currentTabIndex === i  ? undefined: '0'"
            :aria-controls="$changeCase.paramCase(tab.title)"
            :aria-selected="currentTabIndex === i ? 'true' : 'false'"
            @click="setCurrentTabIndex(i)"
            @keydown="setTabIndexFromKeydown($event, i)">
            <app-inline-svg :src="tab.icon"/>
            <span class="mobile until-tablet">{{tab.titleMobile}}</span>
            <span class="tablet-up">{{tab.title}}</span>
          </button>
        </template>
      </div>
    </div>
  </app-section>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { SearchTab } from '@/types'
  import AppSection from '../../_global/AppSection.vue'
  export default defineComponent({
    components:{
      AppSection
    },
    props: {
      tabs: {
        required: true,
        type: Array as () => SearchTab[]
      },
      currentTabIndex: {
        required: true,
        type: Number
      }
    },
    methods: {
      setCurrentTabIndex(index: number):void {
        this.$emit('setCurrentTabIndex', index)
      },
      setTabIndexFromKeydown(event: KeyboardEvent, index: number):void {
        switch (event.keyCode) {
        case 37:
          this.setPrevTabIndex(index, true)
          break
        case 39:
          this.setNextTabIndex(index, true)
          break
        }
      },
      setPrevTabIndex(index: number, focus?: boolean):void {
        let nextIndex: number
        if (index === 0) {
          nextIndex = this.tabs.length - 1
        } else {
          nextIndex = index - 1
        }
        this.$emit('setCurrentTabIndex', nextIndex)
        if (focus) {
          (this.$el.querySelector(`#tab_button_${nextIndex}`) as HTMLButtonElement)!.focus()
        }
      },
      setNextTabIndex(index: number, focus?: boolean):void {
        let nextIndex: number
        if (index === this.tabs.length - 1) {
          nextIndex = 0
        } else {
          nextIndex = index + 1
        }
        this.$emit('setCurrentTabIndex', nextIndex)
        if (focus) {
          (this.$el.querySelector(`#tab_button_${nextIndex}`) as HTMLButtonElement)!.focus()
        }
      }
    }
  })
</script>
