
import { NewServiceResponseMessage } from '@/types'
interface FormattedServiceResponse {
  messageCode: string
  message: string
}
export function responseMessageFactory(resObj: NewServiceResponseMessage): FormattedServiceResponse { 
    return {
      messageCode: resObj.messageCode || '',
      message: resObj.message
    }
  }
