import { UserCreatedAppointment, AvailableAppointment, Option, UserCreatedPatient, AvailableAppointments, AvailableExamsOnDay } from '@/types'

export function createAppointment(firstNname: string, lastName: string,): UserCreatedAppointment {
  return {
    patient: {
      firstName: firstNname,
      lastName:lastName,
      contactLenses: null,
      newWearer: null,
      visitedBefore: null
    },
    appointment: null,
    personalInformation: null,
    lockId: null,
    queuedAppointmentId: null,
    failedToSchedule: false
  }
}


export function addDays(selectedDate: Date, days: number): Date {
  const result = new Date(selectedDate)
  result.setDate(result.getDate() + days)
  return result
}

export function datesAreOnSameDay(first: Date, second: Date): Boolean {
  return (
    first?.getFullYear() === second?.getFullYear() &&
    first?.getMonth() === second?.getMonth() &&
    first?.getDate() === second?.getDate()
  )
}

export function isSelectedByPatient(appointment: AvailableAppointment, appointmentsPayload: UserCreatedAppointment[]) {
  let selected = false
 appointmentsPayload.map((userAppointment: UserCreatedAppointment) => {
    const selectedAppointment = userAppointment.appointment
    if (selectedAppointment && selectedAppointment!.scheduleId === appointment.scheduleId) {
      selected = true
    }
  })
  return selected
}

export function isAwaitingSelectionByPatient(appointment: AvailableAppointment, appointmentsPayload: UserCreatedAppointment[]) {
  let selected = false
 appointmentsPayload.map((userAppointment: UserCreatedAppointment) => {
    const selectedAppointment = userAppointment.queuedAppointmentId
    if (selectedAppointment === appointment.scheduleId) {
      selected = true
    }
  })
  return selected
}

export function removeLeadingZero(someString: string): string {
  while (someString?.charAt(0) === '0')
  {
    someString = someString.substr(1)
  }
  return someString?.toLowerCase().replace(/\s/g, '')
}

export function isInViewport(elem: Element|null): boolean {
  if (!elem || elem === null) return false
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) 
    )
}


import date  from 'date-and-time'
export const dayOptions = ((): Option[] =>  {
  const options = [] as Option[]
  for (let i = 1; i < 32; i++) {
    const day = date.parse(`${i}-1-2000`, 'D-M-YYYY')
    options.push({ value: date.format(day, 'DD'), label:date.format(day,'D') })
  }
  return options
})()

export const yearOptions = ((): Option[] =>  {
  const options = [] as Option[]
  const currentYear = parseInt(date.format(new Date(), 'YYYY')) as number
  for (let i = 120; i > 0; i--) {
    const yearToPush = (currentYear - i).toString()
    options.unshift({ value: yearToPush, label: yearToPush })
  }
  options.unshift({ value: currentYear.toString(), label: currentYear.toString() })
  return options
})()

export  function leapYear(year): boolean{
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
}

export function getAppointmentType(currentPatient: UserCreatedPatient): string {
  let appointmentType = ''
  const visitedBefore = currentPatient.visitedBefore
  const needsContacts = currentPatient.contactLenses
  if (visitedBefore) {
    appointmentType += 'EP'
  } else {
    appointmentType += 'NP'
  }
  if (needsContacts) {
    appointmentType += ' CL'
    if (currentPatient.newWearer) {
      appointmentType += ' NW'
    }
  } else {
    if (visitedBefore) {
      appointmentType += ' E'
    } else {
      appointmentType += ' EEX'
    }
  }
  return appointmentType
}

export function appendSelectedAppointemntsToResponse(res: AvailableAppointments, appts: UserCreatedAppointment[], todaysApptDate: string): AvailableAppointments {
  const selectedAppointments = appts.filter(appt => !!appt.appointment).map(appt => appt.appointment) as AvailableAppointment[]
  const todaysExams = res.appointments || [] as AvailableAppointment[]
  let examsNeedSorted = false
  selectedAppointments.map((selectedAppointment: AvailableAppointment): void => {
    const examIsUnlocked = (todaysExams.find(exam => exam.scheduleId === selectedAppointment.scheduleId) !== undefined) as boolean
    if (examIsUnlocked) return
    if (todaysApptDate === selectedAppointment.apptDate) {
      todaysExams.push(selectedAppointment)
      if (todaysExams.length > 1) {
        examsNeedSorted = true
      }
    }
  })
  if (examsNeedSorted) {
    todaysExams.sort(sortAppointments)
  }
  return res
}

export function sortAppointments(appointmentA: AvailableAppointment, appointmentB: AvailableAppointment): number {
  let timeA = appointmentA.sortTime ? appointmentA.sortTime : appointmentA.availableTime.replace('AM', 'a.m.').replace('PM', 'p.m.')
  let timeB = appointmentB.sortTime ? appointmentB.sortTime : appointmentB.availableTime.replace('AM', 'a.m.').replace('PM', 'p.m.')
  timeA = date.parse(timeA, 'hh:mm A')
  timeB = date.parse(timeB, 'hh:mm A')
  //@ts-ignore
  return timeA < timeB  ? -1 :  0
}
