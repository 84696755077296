<template>
  <a
    v-if="isAbsoluteUrl"
    :href="to"
    aria-describedby="leave-page-label"
    target="_blank">
    <slot></slot>
  </a>
  <a
    v-else
    :href="localizedRoute"
    @click.prevent="navigate">
    <slot></slot>
  </a>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { getBaseUrl } from '@/site.config.ts'
  export default defineComponent({
    props: {
      to: {
        required:true,
        type: String as () => string
      },
      tag: {
        type: String,
        required: false
      },
      target: {
        type: [String,Boolean],
        required: false
      }
    },
    computed: {
      clientID() {
        //@ts-ignore
        return this.$store.state.globals.microsite.clientId.toLowerCase()
      },
      selectedLanguage() {
        //@ts-ignore
        return this.$store.state.locale.languageSelect.value
      },
      computedTo(): string {
        const to = this.to as string
        return to.startsWith('/') ? to.substr(1) : to
      },
      isAbsoluteUrl(): boolean {
        const to = this.computedTo as string
        const httpCheck = new RegExp('^(http|https)://', 'i')
        const wwwCheck = new RegExp('^www', 'i')
        return (httpCheck.test(to) || wwwCheck.test(to)) 
      },
      localizedRoute(): string {
        return `${getBaseUrl((this as any).$route.params)}/${this.computedTo}`
      }
    },
    methods: {
      navigate(): void {
        this.$router.push({
          path: `${getBaseUrl(this.$route.params)}/${(this as any).computedTo}`,
        })
      }
    }
  })
</script>
