<template>
  <div
    class="_mtm">
    <h2
      :style="globalTheme.colorBlack"
      class="schedule-title">{{labels.doctorsInThisLocation}}</h2>
    <hr />
    <app-collapsible-section
      v-for="(doctor, i) in doctors"
      :key="i"
      force-focus
      collapsed
      class="_mts _pls _prs schedule-doctor-info">
      <template v-slot:edit-label="{collapsed}">
        <p
          class="tablet-up"
          :style="globalTheme.colorPrimaryDark">
          <template v-if="collapsed">
            {{labels.showDoctorInfo}}
          </template>
          <template v-else>
            {{labels.hideDoctorInfo}}
          </template>
        </p>
      </template>
      <template v-slot:header-text>
        <doctor-name
          :doctor="doctor"
          :style="globalTheme.colorPrimaryDark"
          class="schedule-doctor-name _m0"/>
      </template>
      <hr class="_mt0 _mbxs"/> 
      <doctor-info
        :hide-accept-new-patient="hideAcceptNewPatient"
        :provider="provider"
        :doctor="doctor"
        class="_pbxs"/>
    </app-collapsible-section>
  </div>
    
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { ScheduleADateLabels, Doctor, Provider } from '@/types'

  import DoctorName from '@atoms/Results/DoctorName.vue'
  import DoctorInfo from '@atoms/Results/DoctorInfo.vue'
  export default defineComponent({
    components: {
      DoctorName,
      DoctorInfo
    },
    props: {
      hideAcceptNewPatient: {
        default: false,
        type: Boolean
      },
      labels: {
        required: true,
        type: Object as () => ScheduleADateLabels
      },
      doctors: {
        required: true,
        type: Array as () => Doctor[]
      },
      provider: {
        required: true,
        type: Object as () => Provider
      },
    }
  })
</script>
