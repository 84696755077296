<template>
  <nav
    class="pagination fg-will-change"
    aria-label="pagniation">
    <VuePaginate
      ref="paginate"
      :model-value="page"
      :page-count="pageCount"
      :page-range="15"
      :prev-link-class="color"
      :page-link-class="color"
      :next-link-class="color"
      prev-text="Prev"
      next-text="Next"
      no-li-surround
      hide-prev-next
      :click-handler="clickCallback"
      @update:model-value="newValue => page = newValue"
    >
    </VuePaginate>
  </nav>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { VuePaginate } from '@svifty7/vue-paginate';
  import { eventBus } from '@/main'
  export default defineComponent({
    name: 'pagination',
    components: {
      VuePaginate
    },
    props: {
      pageCount: {
        type: Number,
        required: true
      },
      color: {
        type: String,
        default: 'moon'
      },
      modelValue: {
        type: Number,
        //required: true
      },
      primary: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      page: {
        get() {
          return this.modelValue
        },
        set(newVal) {
          this.$emit('update:modelValue', newVal)        
          this.$nextTick(() => {
            this.setAriaLabels()
          })
        }
      }
    },
    methods: {
      clickCallback(page){
        this.$emit('clicked', page)
        if (!this.primary) {
          eventBus.$emit('paginationEvent', page)
        }
      },
      setAriaLabels(): void {
        const pageLinks = this.$el.querySelectorAll('a')
        Array.from(pageLinks).map((link, i): void => {
          const linkText: string = link.innerHTML
          const isNumberStep: boolean = !isNaN(parseInt(linkText))
          link.setAttribute('data-page-button', `${linkText}`)
          link.setAttribute('role','navigation')
          if (isNumberStep) {
            link.setAttribute('aria-label', `Go to page ${linkText}`)
          } else {
            link.setAttribute('aria-label', `Go to ${linkText} page`)
          }
        })
      }
    },
    mounted() {
      this.setAriaLabels()
      eventBus.$on('paginationEvent', (button) => {
        if (this.primary) {
          const focusEl = this.$el.querySelector(`[data-page-button="${button}"]`) as HTMLLinkElement
          if (focusEl) {
            focusEl.focus()
          }
        }
      })
      }
  })
</script>
