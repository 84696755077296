<template>
  <app-section class="internet-explorer _text-center">
    <div
      container
      class="_mbxl"
    >
      <div column="xs-12 s-12">
        <rich-text
          :html="$store.state.globals.microsite.internetExplorerMessage"
          modifier="body-copy"/>
      </div>
    </div>
  </app-section>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    name: 'no_IE_support'
  })
</script>
