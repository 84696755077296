<template>
  <img
    :src="src"
    alt="" />
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import SVGInjector from 'svg-injector'
  function removeGuts(svg): void {
    const childrenEls = svg.querySelectorAll('path') as SVGSymbolElement[]
    const allEls = svg.querySelectorAll('*') as SVGSymbolElement[]
    Array.from(allEls).map((el): void => {
      el.removeAttribute('id')
      el.removeAttribute('class')
      if (
        el.nodeName.toLowerCase() === 'path' ||
        el.nodeName.toLowerCase() === 'circle'
      ) {
        const fill = el.getAttribute('fill')
        if (!fill) {
          el.style.fill = 'currentColor'
        } else if (
          fill !== 'white' &&
          fill.toLowerCase() !== '#fff' &&
          fill.toLowerCase() !== '#ffffff' &&
          fill.toLowerCase() !== '#fefefe'
        ) {
          el.style.fill = 'currentColor'
        }
      }
    })
  }
  export default defineComponent({
    name: 'inline-svg',
    props: {
      src: {
        type: String,
        required: true
      },
      forceColorOverride: {
        type: Boolean,
        default: true
      }
    },
    mounted(): void {
      try {
        SVGInjector(this.$el, {
          each: svg => {
            svg.setAttribute('role', 'img')
            svg.setAttribute('alt', '')
            svg.setAttribute('aria-hidden', 'true')
            if (!this.forceColorOverride || (this as any).isEyeMed) return
            try {
              removeGuts(svg)
              this.$emit('svg-created')
            } catch {
              this.$emit('svg-created')
            }
          }
        })
      } catch {
        this.$emit('svg-created')
      }
    }
  })
</script>
