<template>
  <div
    v-if="legacyView"
    column="xs-12 s-12">
    <div
      v-if="isMobile"
      grid="row wrap">
      <div
        v-for="(provider) in providerList"
        :key="provider.locationId"
        column="xs-12 s-12 m-6"
        class="legacy-split-column">
        <search-result-card
          :provider="provider"
          :labels="{...page.resultCard}"
          :legacy-view="legacyView"
          :special-offers="specialOffers"
          :offer-button-label="offerButtonLabel"
          :offer-popup-independent="offerPopupIndependent"
          :show-plus-provider-side-bar="showPlusProviderSideBar"
          :hide-accept-new-patient="page.filterLabels.hideAcceptNewPatient"
          @setCurrentProvider="setCurrentProviderFromList(provider)" />
      </div>
    </div>
    <div
      v-else
      grid="row wrap">
      <div
        column="xs-12 s-12 m-6"
        class="legacy-split-column">
        <div grid="row wrap">
          <template
            v-for="(provider, i) in providerList">
            <div
              v-if="!(i % 2)"
              :key="provider.locationId"
              column="xs-12 s-12">
              <search-result-card
                :provider="provider"
                :labels="{...page.resultCard}"
                :legacy-view="legacyView"
                :special-offers="specialOffers"
                :offer-button-label="offerButtonLabel"
                :offer-popup-independent="offerPopupIndependent"
                :show-plus-provider-side-bar="showPlusProviderSideBar"
                :hide-accept-new-patient="page.filterLabels.hideAcceptNewPatient"
                @setCurrentProvider="setCurrentProviderFromList(provider)" />
            </div>
          </template>
        </div>
      </div>
      <div
        column="xs-12 s-12 m-6"
        class="legacy-split-column">
        <div grid="row wrap">
          <template
            v-for="(provider, i) in providerList">
            <div
              v-if="(i % 2)"
              :key="provider.locationId"
              column="xs-12 s-12">
              <search-result-card
                :provider="provider"
                :labels="{...page.resultCard}"
                :legacy-view="legacyView"
                :special-offers="specialOffers"
                :offer-button-label="offerButtonLabel"
                :offer-popup-independent="offerPopupIndependent"
                :show-plus-provider-side-bar="showPlusProviderSideBar"
                :hide-accept-new-patient="page.filterLabels.hideAcceptNewPatient"
                @setCurrentProvider="setCurrentProviderFromList(provider)" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    grid="row wrap">
    <div :column="(hasPlusProviders && !hidePlusProviderSideBar) && !isMobile ? 'xs-9 s-9' : 'xs-12 s-12'">
      <search-result-card
        v-for="(provider) in providerList"
        :key="provider.locationId"
        :provider="provider"
        :labels="{...page.resultCard}"
        :legacy-view="legacyView"
        :special-offers="specialOffers"
        :offer-button-label="offerButtonLabel"
        :offer-popup-independent="offerPopupIndependent"
        :show-plus-provider-side-bar="showPlusProviderSideBar"
        :hide-accept-new-patient="page.filterLabels.hideAcceptNewPatient"
        @setCurrentProvider="setCurrentProviderFromList(provider)" />
    </div>
    <div
      v-if="(hasPlusProviders && !hidePlusProviderSideBar) && !isMobile"
      column="xs-3 s-3">
      <plus-provider-sidebar
        :provider-list="getPlusProvidersFromList()"
        :labels="{...page.resultCard}"
        :special-offers="specialOffers"
        :schedule-appointment-label="scheduleAppointmentLabel"
      />
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import SearchResultCard from '@organisms/Results/SearchResultCard.vue'
  import PlusProviderSidebar from '@organisms/Results/PlusProviderSidebar.vue'
  import { Provider, SearchResultsPageLabels } from '@/types'
  import { eventBus } from '@/main'
  import { debounce } from 'lodash'
  import { mapState } from 'vuex';

  function setMinHeights(cards: NodeListOf<HTMLElement>): void {
    Array.from(cards).map((card: HTMLElement): void => {
      card.style.minHeight = `${0}px`
    })
    const minHeight = Math.max(...Array.from(cards).map((card: HTMLElement): number => {
      return card.offsetHeight
    }))
    Array.from(cards).map((card: HTMLElement): void => {
      card.style.minHeight = `${minHeight}px`
    })
  }
  export default defineComponent({
    components: { SearchResultCard, PlusProviderSidebar },
    props: {
      page: {
        required: true,
        type: Object as ()=> SearchResultsPageLabels
      },
      legacyView: {
        type: Boolean,
        default: false
      },
      providerList: {
        type: Array as () => Provider[],
        required: true
      },
      allProvidersList: {
        type: Array as () => Provider[],
        required: true
      },
      specialOffers: {
        type: Object as () => {}
      },
      zipClass: {
        type: String,
        default: ''
      },
      zipSortingMiles: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        isMobile: window.innerWidth <= 768,
        heightWatcherTiggered: false as boolean,
        offerButtonLabel: this.page.offerButtonLabel as string,
        offerPopupIndependent: this.page.offerPopupIndependent as string,
        scheduleAppointmentLabel: this.page.plusSideBar.scheduleAppointmentLabel as string,
        tieredView: (this.$route.query.tieredView === 'true') as boolean,
        statesHidePlusSidebar : this.$store.state.globals.microsite.statesHidePlusSideBar as []
        ? this.$store.state.globals.microsite.statesHidePlusSideBar as []
        : [],
        currentState: ( this.allProvidersList && this.allProvidersList.length > 0 ) ? this.allProvidersList[0].address.state : ''
      }
    },
    computed: {
      hasPlusProviders() {
        return (this as any).getPlusProvidersFromList().length > 0 && !(this as any).tieredView
      },
      showPlusProviderSideBar() {
        return ((this as any).hasPlusProviders && !(this as any).hidePlusProviderSideBar) && !(this as any).isMobile
      },
      ...mapState({
      hidePlusProviderSideBar: (state) => state.globals.microsite.hidePlusProviderSideBar,
      }),
    },
    watch: {
      legacyView : {
        handler(val : any){
        if (val) {
          this.setInitialMinHeights()
        }
      },
      deep:true
      }
    },
    methods: {
      setCurrentProviderFromList(provider: Provider): void {
        this.$emit('setCurrentProviderFromList', provider)
      },
      mobileCheck:  debounce(function(this): void {
        const isMobile = window.innerWidth < 768
        if (this.isMobile !== isMobile) {
          this.isMobile = isMobile
          if (!isMobile) {
            this.setInitialMinHeights()
            this.stutterAdustCardHeights()
            eventBus.$emit('iframeResizeEvent')
          } else {
            eventBus.$emit('iframeResizeEvent')
          }
        }
      }, 400),
      setInitialMinHeights(): void {
        if (this.heightWatcherTiggered) return
        this.heightWatcherTiggered = true
        this.$watch('providerList', () => {
          setMinHeights(this.$el.querySelectorAll('.search-result-card.legacy'))
        })
        this.stutterAdustCardHeights()
      },
      stutterAdustCardHeights(): void {
        this.$nextTick(() => {
          setTimeout(() => {
            setMinHeights(this.$el.querySelectorAll('.search-result-card.legacy'))
          }, 100) // sloppy, but gets the job done
        })
      },
      getPlusProvidersFromList() {
        let filteredProvidersList = this.allProvidersList
        if(this.hidePlusProviderSideBar === false){
          if(!this.statesHidePlusSidebar.includes(this.currentState)){
            filteredProvidersList = filteredProvidersList.filter(provider => !this.statesHidePlusSidebar.includes(provider.address.state))
          }
        }
        if (this.zipClass.toLowerCase() === 'r') {
          return filteredProvidersList.filter(provider => provider.tieredLocation && provider.distance <= 50).slice(0, 5)
        }
        else if (this.zipClass.toLowerCase() !== 'r') {
          return filteredProvidersList.filter(provider => provider.tieredLocation && provider.distance <= 20).slice(0, 5)
        }
        else {
          return []
        }
      },
    },
    created() {
      window.addEventListener('resize', this.mobileCheck)
      window.addEventListener('orientationchange', this.mobileCheck)
    },
    destroyed() {
      window.removeEventListener('resize', this.mobileCheck)
      window.removeEventListener('orientationchange', this.mobileCheck)
    },
    mounted() {
      if (this.legacyView && !this.isMobile) {
        this.setInitialMinHeights()
        eventBus.$on('forceCardHeightResize', () => {
          this.stutterAdustCardHeights()
          eventBus.$emit('iframeResizeEvent')
        })
      }
      if(this.hidePlusProviderSideBar === false){
        if(this.statesHidePlusSidebar.includes(this.currentState))
        this.$store.commit('globals/overrideHidePlusProviderSideBar', {
          hidePlusProviderSideBarNewValue: true
        })
      }
    }
  })
</script>
