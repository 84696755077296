 import {defineComponent} from 'vue'
import { ContentStackPagePayload, ServiceFactoryError, ServiceResponseMessage } from '@/types'
import { getData } from '@/ts/service-helpers/contentstack/get-page-data'
import { eventBus } from '@/main'

function getAbsoluteHeight(el) {
  const styles = window.getComputedStyle(el)
  const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])
  return Math.ceil(el.offsetHeight + margin)
}

export default defineComponent({
  data() {
    return {
      page: null as null | any
    }
  },
  methods: {
    emitResizeEvent():void {
      // this is for signaling to the parent window to resize when the app is used as an iframe
      this.$nextTick(() => {
        setTimeout(() => {
          const el = (this.$el as HTMLElement)
          parent.postMessage(`resize_event:${getAbsoluteHeight(el)}`, '*')
        }, 1)
    })
    },
    async getStackPageByName(
      name: string,
      factory?: (page: any) => any
    ): Promise<void> {
      const stackPayLoad: ContentStackPagePayload = {
        lang: this.$route.params.lang,
        site: this.$route.params.clientID,
        pageType: name,
        test: this.$route.query.content === 'test'
      }
      return getData(stackPayLoad).then(res => {
        const page = res.page
        if (page) {
          if (factory) {
            try {
              this.page = factory(page)
            } catch (error) {
              this.$store.commit('responseMessages/addServiceError', {
                defaultMessage: error.message,
                serviceName: `Get contentstack ${stackPayLoad.pageType} page`
              } as ServiceFactoryError)
            }
          } else {
            this.page = page
          }
        } else {
          this.$store.commit('responseMessages/addServiceError', {
            defaultMessage: res.errorMessage
          } as ServiceResponseMessage)
        }
      })
    },
  },
   async mounted() {
    // sometimes we need to resize the window for various things like drawers opening and closing, we'll use an eventBus for this
    eventBus.$on('iframeResizeEvent', this.emitResizeEvent) 
  }, 
  beforeDestroy () {
    eventBus.$off('iframeResizeEvent', this.emitResizeEvent)
 }
})
