<template>
  <p
    role="alert"
    v-html="errorMessage"></p>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { ServiceFactoryError, ServiceResponseMessage } from '@/types'
  export default defineComponent({
    props: {
      errorObj: {
        type: Object as () => ServiceResponseMessage,
        required: false
      }
    },
    computed: {
      errorMessage(): string|undefined {
        if (this.errorObj.messageCode) { 
          const userEnteredMessage = this.$store.state.responseMessages.responseMessages[this.errorObj.messageCode]
          return userEnteredMessage ? userEnteredMessage : this.errorObj.defaultMessage || this.errorObj.message
        } else {
          return this.errorObj.defaultMessage || this.errorObj.message
        }
      }
    }  
  })
</script>
