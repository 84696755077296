import { CurrentProvider } from '@/types'

const state = {
  currentProvider: undefined as CurrentProvider | undefined
}

const mutations = {
  setCurrentProvider(state, provider: CurrentProvider | undefined): void {
    state.currentProvider = provider
    if (provider && !provider.searchedZipCode) {
      state.currentProvider.searchedZipCode = 'My Location'
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
