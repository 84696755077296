<template>
  <div
    class="schedule-date-view">
    <div
      ref="scroll_anchor" 
      class="scroll-anchor"/>
    <h2
      :style="globalTheme.colorDark"
      class="schedule-title">{{page.scheduleADate.sectionTitle}} {{currentPatient.firstName}} {{currentPatient.lastName}}</h2>
    <div
      class="themed-strip"
      :style="globalTheme.borderColor" />
    <p
      :style="globalTheme.colorBlack"
      class="picked-month">{{ selectedMonth }}</p>
    <calendar-date-picker-drawer 
      v-model="selectedDate"
      :calendar-key="calendarKey"
      :fetching-exams="fetchingExams"
      :min-date="minDate"
      :labels="page.scheduleADate"/>
    <appointment-picker-drawer
      v-model="selectedDate"
      :is-mobile="isMobile" 
      :calendar-key="calendarKey"
      :fetching-exams="fetchingExams"
      :min-date="minDate"
      :current-available-appointments="currentAvailableAppointments"
      :labels="page.scheduleADate">
    </appointment-picker-drawer>
    <app-loader-section v-if="fetchingInitialExams"/>
    <div v-else-if="currentAvailableAppointments">
      <app-loader-section v-if="fetchingExams"/>
      <div
        v-else
        grid="row wrap"
        class="exam-lists">
        <div
          v-for="(examList, i) in examLists"
          :key="i"
          column="xs-12 s-6 l-4"
          class="_mbs">
          <div class="_text-center">
            <h5
              :style="globalTheme.colorBlack"
              class="_mb0 _caps">{{page.scheduleADate[examList.timeOfDay]}}</h5>
            <p class="_mt0">{{examList.timeFrame}}</p>
          </div>
          <exam-list
            :time-of-day="page.scheduleADate[examList.timeOfDay]"
            :labels="page.scheduleADate"
            :current-patient-index="currentPatientIndex"
            :appointments="examList.appointments"
            :appointments-payload="appointmentsPayload"
            :next-day-with-appointment="examList.nextDayWithAppointment"
            :locking="locking"
            @addAppointmentToCurrentPatient="addAppointmentToCurrentPatient"/>
        </div>
        <div
          v-if="responseMessage"
          column="xs-12 s-12"
          class="_mbs">
          <response-flash-notification :error-obj="responseMessage"/>
        </div>
        <div
          column="xs-12 s-12 m-6 +m-3 l-4 +l-4">
          <div class="button-wrap">
            <button
              :disabled="!appointmentsPayload[currentPatientIndex].appointment"
              :style="globalTheme.button"
              class="schedule-continuer"
              @click="continueOn">
              {{page.continueButton}}
            </button>
          </div>
        </div>
      </div>
      <doctor-list
        v-if="provider.doctors.length"
        :provider="provider"
        class="tablet-up"
        :doctors="provider.doctors"
        :labels="page.scheduleADate"/>
    </div>
    <response-flash-notification
      v-else-if="responseMessage"
      :error-obj="responseMessage"/>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import date from 'date-and-time'
  import 'date-and-time/plugin/ordinal'
  date.plugin('ordinal')
  
  import { ServiceResponseMessage, AvailableAppointments, AvailableExamsQuery, SchedulePageLabels, Provider, UserCreatedAppointment, AvailableAppointment, UserCreatedPatient, AvailableExamsOnDay, LockPayload, LockSuccess, CurrentProvider } from '@/types'
  import { getSbExams, getExams } from '@/ts/service-helpers/services/get-available-exams.ts'
  import { lockAppointment, lockSbAppointment, unlockAppointment, unlockSbAppointment } from '@/ts/service-helpers/services/lock-appointment.ts'
  import { getAppointmentType, addDays, appendSelectedAppointemntsToResponse } from '@/ts/helpers/schedule.helpers'
  import { eventBus } from '@/main'
  import { scheduleViewChildRoutes } from '@/router.ts'
  const today = new Date()


  import CalendarDatePickerDrawer from '@organisms/Schedule/CalendarDatePickerDrawer.vue'
  import AppointmentPickerDrawer from '@organisms/Schedule/AppointmentPickerDrawer.vue'
  import ExamList from '@organisms/Schedule/ExamList.vue'
  import DoctorList from '@organisms/Schedule/DoctorList.vue'

  import ScheduleRouterGuard from '@/mixins/ScheduleRouterGuards.ts'
  import { trackSchedulingStep } from '@/ts/adobe-analytics/index'

import 'date-and-time/locale/es';

  export default defineComponent({
    name:'scheduleDate',
    components: {
      CalendarDatePickerDrawer,
      AppointmentPickerDrawer,
      ExamList,
      DoctorList
    },
    mixins: [ScheduleRouterGuard],
    props: {
      value: {
        required: true,
        type: Array as () => UserCreatedAppointment[]
      },
      page: {
        required: true,
        type: Object as () => SchedulePageLabels
      },
      provider: {
        required: true,
        type: Object as () => CurrentProvider
      },
      currentPatientIndex: {
        type: Number,
        default: 0,
      },
      localizedRoute: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        locking: false,
        windowWidth: window.innerWidth as number,
        fetchingInitialExams: true,
        fetchingExams: true,
        responseMessage: null as null|ServiceResponseMessage,
        currentAvailableAppointments: null as null|AvailableAppointments,
        calendarKey: 0,
        minDate: today as Date,
        selectedDate: today as Date
      }
    },
    computed: { 
      isMobile(): boolean {
        return this.windowWidth < 600
      },
      dateTime() {
      date.locale(this.$route.params.lang);
      return date;
    },
      selectedDateFormattedForQuery(): string {
        return this.dateTime.format(this.selectedDate, 'MM/DD/YYYY')
      },
      selectedStartDateFormattedForQuery(): string {
        const daysBeforeStartDate = this.isMobile ? -1 : -2
        const selectedStartDate = addDays(this.selectedDate, daysBeforeStartDate)
        return this.dateTime.format(selectedStartDate, 'MM/DD/YYYY')
      },
      selectedEndDateFormattedForQuery(): string {
        const daysAfterStartDate = this.isMobile ? 1 : 2
        const selectedEndDate = addDays(this.selectedDate, daysAfterStartDate)
        return this.dateTime.format(selectedEndDate, 'MM/DD/YYYY')
      },
      selectedMonth(): string {
        return this.dateTime.format(this.selectedDate, 'MMMM Y')
      },
      appointmentsPayload: {
        get(): UserCreatedAppointment[] {
          return (this as any).value
        },
        set(newVal: UserCreatedAppointment[]) {
          (this as any).$emit('update:value', newVal)
        }
      },
      currentPatient(): UserCreatedPatient {
        return this.appointmentsPayload[this.currentPatientIndex].patient
      },
      morningAppointments(): AvailableAppointment[] {
        return this.filterAppointmentsByTimeOfDay('morning')
      },
      afternoonAppointments(): AvailableAppointment[] {
        return this.filterAppointmentsByTimeOfDay('afternoon')
      },
      eveningAppointments(): AvailableAppointment[] {
        return this.filterAppointmentsByTimeOfDay('evening')
      },
      nextDayWithMorningAppointment(): string|null {
        return this.getNextDayWithAvailableTimeOfDayAppointment('morning')
      },
      nextDayWithAfternoonAppointment(): string|null {
        return this.getNextDayWithAvailableTimeOfDayAppointment('afternoon')
      },
      nextDayWithEveningAppointment(): string|null {
        return this.getNextDayWithAvailableTimeOfDayAppointment('evening')
      },
      allPatientsScheduled(): boolean {
        const patientWithoutApptIndx = this.appointmentsPayload.findIndex(appt => !appt.appointment)
        return patientWithoutApptIndx === -1
      },
      examLists(): {timeOfDay: string, timeFrame: string, appointments: AvailableAppointment[], nextDayWithAppointment: string|null}[] {
        return [
          { 
            timeOfDay: 'morning',
            timeFrame: '7:00 am - 12:00 pm',
            appointments: (this as any).morningAppointments,
            nextDayWithAppointment: this.nextDayWithMorningAppointment
          },
          { 
            timeOfDay: 'afternoon',
            timeFrame: '12:00 pm - 5:00 pm',
            appointments: (this as any).afternoonAppointments,
            nextDayWithAppointment: this.nextDayWithAfternoonAppointment
          },
          { 
            timeOfDay: 'evening',
            timeFrame: '5:00 pm - 9:30 pm',
            appointments: (this as any).eveningAppointments,
            nextDayWithAppointment: this.nextDayWithEveningAppointment
          }
        ]
      },
      selectedAppointmentType(): string {
        const currentAppointment = this.currentPatient as UserCreatedPatient
        let appointmentType =''
        if (currentAppointment.visitedBefore) {
          appointmentType += 'EP'
        } else {
          appointmentType += 'NP'
        }
        if (currentAppointment.contactLenses) {
          appointmentType += ' CL'
          if (currentAppointment.newWearer) {
            appointmentType += ' NW'
          }
        } else {
          appointmentType += ' E'
        }
        return appointmentType
      }
    },
    watch: {
      async selectedDate(val): Promise<void> {
        if (this.$route.query.isSbAppointment === 'true') {
          await this.getSbExams()
        }
        else {
          await this.getExams()
        }
        this.calendarKey += 1
      },
      async currentPatientIndex(indx): Promise<void> {
        (this.$refs.scroll_anchor as Element).scrollIntoView({ behavior: 'smooth', block: 'start' })
        if (this.$route.query.isSbAppointment === 'true') {
          await this.getSbExams()
        }
        else {
          await this.getExams()
        }
      }
    },
    methods:{
      filterAppointmentsByTimeOfDay(timeOfDay: 'morning'|'afternoon'|'evening'): AvailableAppointment[] {
        const appointments = this.currentAvailableAppointments && this.currentAvailableAppointments.appointments ? this.currentAvailableAppointments!.appointments : []
        return appointments.filter(appt => appt.timeOfDay === timeOfDay )
      },
      async addAppointmentToCurrentPatient(appointment: AvailableAppointment|null): Promise<void> {
        this.locking=true
        const currentPatient = this.appointmentsPayload[this.currentPatientIndex] as UserCreatedAppointment
        if (appointment) { // lock appointment, add to payload for rendering later
          currentPatient.queuedAppointmentId = appointment.scheduleId
          if (this.$route.query.isSbAppointment === 'true') {
            await this.unlockAnyCurrentSbAppointmentOnUser(currentPatient)
            lockSbAppointment({ scheduleId: appointment.scheduleId } as LockPayload)
              .then((res): void => this.setAppointmentOnPatient(res, appointment))
              .catch((serviceError): void => this.renderServiceError(serviceError))
              .finally(() => {
                this.clearLock(currentPatient)
              })
          } else {
            await this.unlockAnyCurrentAppointmentOnUser(currentPatient)
            lockAppointment({ scheduleId: appointment.scheduleId } as LockPayload)
              .then((res): void => this.setAppointmentOnPatient(res, appointment))
              .catch((serviceError): void => this.renderServiceError(serviceError))
              .finally(() => {
                this.clearLock(currentPatient)
              })
          }
        } else { // unlock appointment on deselect (null) and remove from payload
          if (this.$route.query.isSbAppointment === 'true') {
            await this.unlockAnyCurrentSbAppointmentOnUser(currentPatient)
          } else {
            await this.unlockAnyCurrentAppointmentOnUser(currentPatient)
          }
          this.clearLock(currentPatient, true)
        }
      },
      clearLock(currentPatient: UserCreatedAppointment, killAppointment?: boolean): void{
        if (killAppointment) {
          currentPatient.queuedAppointmentId = null
        }
        this.locking = false
      },
      clearAllApptData(currentPatient: UserCreatedAppointment):void {
        currentPatient.appointment = null
        currentPatient.lockId = null
      },
      async unlockAnyCurrentAppointmentOnUser(currentPatient: UserCreatedAppointment): Promise<void> {
        if (!currentPatient.lockId)  return
        return new Promise((resolve,reject) => {
          unlockAppointment({ lockId: currentPatient.lockId! })
            .finally(() => {
              this.clearAllApptData(currentPatient)
              resolve()
            })
        })
      },
      async unlockAnyCurrentSbAppointmentOnUser(currentPatient: UserCreatedAppointment): Promise<void> {
        if (!currentPatient.lockId)  return
        return new Promise((resolve,reject) => {
          unlockSbAppointment({ lockId: currentPatient.lockId! })
            .finally(() => {
              this.clearAllApptData(currentPatient)
              resolve()
            })
        })
      },
      getNextDayWithAvailableTimeOfDayAppointment(timeOfDay: 'morning'|'afternoon'|'evening'): string|null {
        const allFetchedAppointments = (this as any).currentAvailableAppointments.availableExams.map(appointmentDay => appointmentDay.schedules).flat() as AvailableAppointment[]
        const nextDayWithAvailableTimeOfDayAppointment = allFetchedAppointments.find((appointmentDay) => {
          const appointmentDate = new Date(appointmentDay.asDate as Date).setHours(0,0,0,0)
          const minDate = this.minDate.setHours(0,0,0,0) // never show appointments in the past, this could easily changed to selectedDate if the business team wants to only look forward from the current day selected (not the first day in the retrieved list)
          return ( appointmentDate > minDate) && (appointmentDay.timeOfDay === timeOfDay)
        })
        return nextDayWithAvailableTimeOfDayAppointment ? (nextDayWithAvailableTimeOfDayAppointment.apptDate) as string : null
      },
      async getExams(): Promise<void> {
        this.fetchingExams = true
        eventBus.$emit('iframeResizeEvent')
        const query = {
          storeNumber: (this.provider as Provider).externalStoreNumber,
          appointmentType: getAppointmentType(this.currentPatient),
          startDate: this.selectedStartDateFormattedForQuery,
          endDate: this.selectedEndDateFormattedForQuery
        } as AvailableExamsQuery
        return getExams(query)
          .then((res): void => { 
            this.responseMessage = null
            this.currentAvailableAppointments = appendSelectedAppointemntsToResponse(res as AvailableAppointments, this.value as UserCreatedAppointment[], this.selectedDateFormattedForQuery as string ) as AvailableAppointments
          })
          .catch((serviceError): void => {
            this.renderServiceError(serviceError)
          })
          .finally((): void => {
            this.fetchingExams = false
            eventBus.$emit('iframeResizeEvent')
          })
      },
      async getSbExams(): Promise<void> {
        this.fetchingExams = true
        eventBus.$emit('iframeResizeEvent')
        const query = {
          storeNumber: (this.provider as Provider).externalStoreNumber,
          appointmentType: getAppointmentType(this.currentPatient),
          startDate: this.selectedStartDateFormattedForQuery,
          endDate: this.selectedEndDateFormattedForQuery
        } as AvailableExamsQuery
        return getSbExams(query)
          .then((res): void => { 
            this.responseMessage = null
            this.currentAvailableAppointments = appendSelectedAppointemntsToResponse(res as AvailableAppointments, this.value as UserCreatedAppointment[], this.selectedDateFormattedForQuery as string ) as AvailableAppointments
          })
          .catch((serviceError): void => {
            this.renderServiceError(serviceError)
          })
          .finally((): void => {
            this.fetchingExams = false
            eventBus.$emit('iframeResizeEvent')
          })
      },
      setAppointmentOnPatient(res, appointment: AvailableAppointment): void {
        const currentPatient = this.appointmentsPayload[this.currentPatientIndex] as UserCreatedAppointment
        const prettyDate = this.$route.params.lang === 'en' ? this.dateTime.format(this.selectedDate, 'dddd MMM, DDD') : date.format(this.selectedDate, 'M/D/YYYY')
        currentPatient.lockId = (res as LockSuccess).lockId
        currentPatient.appointment = { ...appointment, prettyDate: prettyDate }
      },
      renderServiceError(serviceError) {
        this.responseMessage = { 
          defaultMessage: serviceError.message,
          messageCode: serviceError.messageCode 
        }
      },
      continueOn(): void {
        const patientWithoutApptIndx = this.appointmentsPayload.findIndex(appt => !appt.appointment)
        const query = this.$route.query
        if (patientWithoutApptIndx === -1) {
          const nextRoute = scheduleViewChildRoutes[2]
          this.$router.replace({ 
            name: nextRoute.name,
            path: `${this.localizedRoute}/schedule/${nextRoute.path}`,
            query: query
          }).then(() => {
            if (this.$route.query.isSbAppointment === 'true') {
              trackSchedulingStep('event-sb-step-schedule-date-finished', this.provider)
            }
            else {
              trackSchedulingStep('event-tab-step-schedule-date-finished', this.provider)
            }
          })
        } else {
          this.$emit('setPatientIndex', patientWithoutApptIndx)
        } 
      },
      selectFirstPatientWithoutAppointment():void {
        const patientWithoutApptIndx = this.appointmentsPayload.findIndex(appt => !appt.appointment)
        if (patientWithoutApptIndx !== -1) {
          this.$emit('setPatientIndex', patientWithoutApptIndx)
        } else {
          this.$emit('setPatientIndex', 0)
        }
      },
      setWindowWidth(width: number): void {
        this.windowWidth = width
      }
    },
    async created() {
      const insufficientAppointmentData = this.value.find(user => {
        return !user.patient.firstName || !user.patient.firstName.length ||!user.patient.lastName || !user.patient.lastName.length
      })
      if (insufficientAppointmentData || !this.provider) {
        //@ts-ignore
        this.returnToFirstRoute()
      } else {
        this.selectFirstPatientWithoutAppointment()
        if (this.$route.query.isSbAppointment === 'true') {
          await this.getSbExams()
        }
        else {
          await this.getExams()
        }
        this.fetchingInitialExams = false
      }
    },
    mounted() {
      eventBus.$on('windowSizeChange', this.setWindowWidth)
    },
    beforeDestroy() {
      eventBus.$off('windowSizeChange', this.setWindowWidth)
    }
  })
</script>
