<template>
  <div class="confirmation-actions hide-print">
    <directions-link
      :style-override="globalTheme.buttonSecondary"
      class-override="btn _mts"
      :provider="provider"
      label="directions">

      <app-inline-svg
        class="_mrxs"
        :src="labels.directionsSvgIcon.url"
        :style="globalTheme.colorWhite"/>
      {{labels.getDirections}}
    </directions-link>
    <print-button
      :style-override="globalTheme.buttonSecondary"
      class-override="btn _mts">
      <app-inline-svg
        class="_mrxs"
        :src="printIcon.url"
        :style="globalTheme.colorWhite"/>
      {{labels.printConfirmation}}
    </print-button>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, AppointmentConfirmation } from '@/types'
  import DirectionsLink from '@atoms/DirectionsLink.vue'
  import PrintButton from '@atoms/PrintButton.vue'
  export default defineComponent({
    components: {
      DirectionsLink,
      PrintButton
    },
    props: {
      provider: {
        type: Object as () => Provider,
        required: true
      },
      labels: {
        type: Object as ()=> AppointmentConfirmation,
        required: true
      },
      localizedRoute: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        printIcon: this.$store.state.globals.microsite.icons.general.printSvg
      }
    }
  })
</script>
