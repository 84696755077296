import { getBrandLogos } from '@/ts/service-helpers/contentstack/get-app-data'

const state =  { 
  brandLogos: {}
}

interface FetchedIcon {icon: Object, bandId: string}
const mutations = {
  setBrandLogos(state, res: any) {
    if (!res || !res.brandLogos) return
    const brandLogos = res.brandLogos as FetchedIcon
    const logos = {}
    for (const [key, value] of Object.entries(brandLogos)) {
      logos[value.bandId.toLowerCase()] = value.icon
    }
    state.brandLogos = logos
  }
}

const actions = {
  async getBrandLogos({ commit }, payload: { lang: string, test: boolean }) {
    const logos = await getBrandLogos(payload.lang, payload.test)
    commit('setBrandLogos', logos)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
