import {  Field, Form } from 'vee-validate'
import { regex } from '@vee-validate/rules';
import { defineRule } from 'vee-validate';
defineRule('regex', regex);
// Rule: required
defineRule('required', (value) => {
  return !!value || 'required';
});
// Rule: email
defineRule('email', (value) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'must be a valid email address';
});
// Rule: date
defineRule('date', (value) => {
  return /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/.test(value) || 'must be a valid date (YYYY-MM-DD)';
});
// Rule: address
defineRule('address', (value) => {
  return /^[\w\s]+$/i.test(value) || 'must be a valid address no special characters';
});
// Rule: city
defineRule('city', (value) => {
  return /^[a-zA-Z\s]+(?:[\s-][a-zA-Z\s]+)*$/.test(value) || 'must be a valid address no special characters';
});
// Rule: US Phone Format
defineRule('phoneNumber', (value) => {
  return /^\(?([0-9]{3})\)?([0-9]{3})[-]?([0-9]{4})$/.test(value) || 'Please enter valid 10 digit phone number';
});
defineRule('county', (value) => {
  return /^[A-Za-z,]+$/.test(value) || 'must be a valid county name (letters and commas only)';
});
// Register it globally
export default function addGlobalValidationComponenets(app: App<Element>) {
  app.component('ValidationProvider', Field)
  app.component('ValidationObserver', Form)
}
