import { service } from '@/ts/service.methods'
import { InternalErrorResponse } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { CountiesPayload, CountiesResults } from '@/types/services/counties-list'
import { getDecryptUrlFactory } from '@/factories/services/get-decrypt-url-factory'

export async function getdecryptUrl(getdecryptUrlPayload: CountiesPayload):Promise<CountiesResults|InternalErrorResponse> {
    return new Promise((resolve,reject) => {
        service.post('decryptURL', getdecryptUrlPayload)
        .then(res => {
            if (res) {
                handleServiceResponse(resolve,reject,res,getDecryptUrlFactory,'getdecryptUrlFactory error')
            } else {
                generateServiceError(reject, res, 'get-decryptUrl.ts/getdecryptUrl')
            }
        })
        .catch(err => generateServiceError(reject, err, 'get-decryptUrl.ts/getdecryptUrl'))
    })
}
