import { FormattedFilterList, Option, FilterLabels, FilterValues, AppliedFilter } from '@/types'

// These methods check for initial filter values in the a url's query string and return them when present. Notice that they return a null value otherwise and NOT an emptry string, this is important for use with vue-multiselect which expects null to render empty selections correctly

export function setMultiValFromQuery(
  someKey: string,
  options: FormattedFilterList,
  route
): Option[] | null {
  const valueFromQuery = route.query[someKey] as string
  if (!valueFromQuery) return null
  const allValues = valueFromQuery.split(',')
  return allValues.map((value: string) => {
    return options[someKey].find((option: Option) => option.value === value)
  })
}

export function setSingleValFromQuery(
  someKey: string,
  options: FormattedFilterList,
  route,
  optionalFindSet?: Option[]
): Option | null {
  const valueFromQuery = route.query[someKey] as string
  if (!valueFromQuery) return null
  if (optionalFindSet) {
    return (
      optionalFindSet.find(
        (option: Option) => option.value === valueFromQuery
      ) || null
    )
  } else {
    return options[someKey].find(
      (option: Option) => option.value === valueFromQuery
    )
  }
}

export function createFilterAppliedString(
  filterLabels: FilterLabels,
  filterValues: FilterValues,
  languageLabel: string

): AppliedFilter[] {
  
  const labeledFilters: { label: string; value: string | null, booleanType?:boolean }[] = [
    {
      label: filterLabels.language,
      value: filterValues?.languages ? filterValues.languages.value : null
    },
    {
      label: filterLabels.hours,
      value: filterValues?.hours ? filterValues.hours.label : null
    },
    {
      label: filterLabels.products,
      value: filterValues?.products
        ? filterValues?.products?.value
        : null
    },
    {
      label: filterLabels.gender,
      value: filterValues?.gender ? filterValues.gender.value : null
    },
    {
      label: filterLabels.brands,
      value: filterValues?.brands
        ? filterValues.brands?.value
        : null
    },
    {
      label: filterLabels.specialty,
      value: filterValues?.designations ? filterValues.designations.label : null
    },
    {
      label: filterLabels.services,
      value: filterValues?.services
        ? filterValues?.services?.value
        : null
    },
    // {
    //   label: filterLabels.acceptNew,
    //   value: filterValues.acceptingNew ? '' : null
    // },
    // {
    //   label: filterLabels.covidCompliantToggle,
    //   value: filterValues.covidCompliant ? '' : null,
    //   booleanType: true
    // }
  ]
  function stringFromMultiChoiceVals(values: Option[]): string|null {
    if (!values.length) return null
    return values.map(val => val?.value).join(', ')
  }
  return labeledFilters.filter(labeledFilter => labeledFilter.value !== null)
}
