<template>
  <app-section
    class="loading"
    grid="center justify-center"
    :class="{'whole-height': wholeHeight}">
    <font-awesome-icon
      icon="spinner"
      pulse
      aria-label="Loading..."
      class="spinmeister"
      :style="color ? color : globalTheme.color"
    />
  </app-section>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      wholeHeight: {
        type: Boolean,
        required: false
      },
      color: {
        required: false,
        type: Object as () => {
          color: string
        }
      }
    }
  })
</script>
<style lang='scss'>
.spinmeister {
    font-size:3rem !important;
}
</style>
