<template>
  <div
    class="plus-provider-sidebar"
    :style="sideBarColor"
  >
    <tiered-provider-label
      :label="labels.tieredProviderLabel"
      :style-override="colorWhite"
      class="title"
    />
    <div
      class="plus-provider-sidebar-cards"
      :style="sideBarCardColor"
    >
      <div
        v-for="provider in providerList"
        :key="provider.locationId">
        <plus-provider-sidebar-card
          :provider="provider"
          :labels="labels"
          :special-offers="specialOffers"
          :schedule-appointment-label="scheduleAppointmentLabel"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, SearchResultCardLabels } from '@/types'
  import PlusProviderSidebarCard from '@organisms/Results/PlusProviderSidebarCard.vue'

  export default defineComponent({
    components: {
      PlusProviderSidebarCard
    },
    props: {
      providerList: {
        type: Array as () => Provider[],
        required: true
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      },
      specialOffers: {
        type: Object as () => {}
      },
      scheduleAppointmentLabel: {
        type: String
      }
    },
    data() {
      return {
        colorWhite: { 'color': '#fff' },
        globalColor: this.$store.state.globals.microsite.googleMapTieredProviderIconColor,
        plusProviderSideBarColor: this.$store.state.globals.microsite.plusProviderSideBarColor
      }
    },
    computed: {
      sideBarColor(): any {
        let backgroundColor: any
        if (this.plusProviderSideBarColor) {
          backgroundColor = `background-color:${this.plusProviderSideBarColor}; -webkit-print-color-adjust: exact;`
        }
        else {
          backgroundColor = `background-color:${this.globalColor};-webkit-print-color-adjust: exact;`
        }
        return backgroundColor
      },
      sideBarCardColor(): any {
        let border: any
        if (this.plusProviderSideBarColor) {
          border = `border: 0.4rem solid ${this.plusProviderSideBarColor};`
        }
        else {
          border = `border: 0.4rem solid ${this.globalColor};`
        }
        return border
      }
    }
  })
</script>
<style>
  .title {
    color: white;
  }

  .plus-provider-sidebar-cards {
    background-color: white;
    border-top: 0 !important;
  }

  .plus-provider-sidebar-cards p {
    margin: -20px 0px 15px 22px;
    font-size: smaller;
  }
</style>
