<template>
  <form-multi-select
    :id="`day_${userIndex}`"
    v-model="day"
    class="_mbs field-width"
    :label="label"
    :validations="(day==='' || day===null) ? 'required' : ''"
    :multiple="false"
    placeholder=""
    :allow-empty="false"
    :options="dayOptions"
    :searchable="true"
    :canClear="false"
    />
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Option } from '@/types'
  import { dayOptions, leapYear } from '@/ts/helpers/schedule.helpers.ts'
  import FormMultiSelect from '@molecules/Form/FormMultiSelect.vue'
  export default defineComponent({
    name:'select-day',
    components: {
      FormMultiSelect
    },
    props: {
      canClear:{
        type:Boolean,
        default:false
      },
      modelValue: {
        type: [Object as () => Option, String],
        required: true
      },
      label: {
        required: true,
        type: String
      },
      chosenMonth: {
        type: [String, Object as () => Option],
        required: true
      },
      chosenYear: {
        type: [String, Object as () => Option],
        required: true
      },
      userIndex: {
        type: Number,
        required: true
      }
    },
    computed: {
      day: {
        get(): Option {
          return (this as any).modelValue
        },
        set(newVal: Option) {     
          (this as any)?.$emit('update:modelValue', newVal)
        }
      },
      dayOptions(): Option[] {
        switch (((this as any).chosenMonth as Option)?.value) {
        case '02' :
          return leapYear((this.chosenYear as Option).value) ? dayOptions.slice(0, -2) : dayOptions.slice(0, -3)
        case '04' || '06' || '09' || '11':
          return dayOptions.slice(0, -1)
        default:
          return dayOptions
        }
      }
    },
    watch: {
      chosenYear: {
        handler(val : any){
        this.checkAndResetLastDayChoice()
      },
      deep:true
      },
      chosenMonth : {
        handler(val : any){
        this.checkAndResetLastDayChoice()
        },
        deep:true
      }
    },
    methods: {
      checkAndResetLastDayChoice(): void {
        const chosenDay = this.day?.value as string
        if (!chosenDay) return
        const availableLastDay = this.dayOptions[this.dayOptions.length - 1] as Option
        if (parseInt(chosenDay) > parseInt(availableLastDay.value as string) ) {
          this.day = availableLastDay
        }
      }
    },
  })
</script>
<style scoped>
.field-width{
  padding-right: 0;
  padding-left: 0;
  white-space: normal;
}
</style>
