<template>
  <simple-title>
    <div aria-live="polite">
      <logo
        class="height-100 print-only"
      />
      <h2
        class="_m0"
        :style="globalTheme.colorBlack">
        <span class="hide-print title-width">{{ filteredResults.length  }} </span>
        <template v-if="$route.query.zip">
          <template v-if="resultsAreFiltered">{{
            formatResultsLabel(page.filteredResultsForLabel)
          }}</template>
          <template v-else>{{
            formatResultsLabel(page.resultsForLabel)
          }}</template
          >: "<span
            v-if="$route.query.providerName"
            class="provider-name"
          >{{ $route.query.providerName }},
          </span>
          <span
            v-if="$route.query.city"
            class="provider-address"
          >{{ $route.query.city }},
          </span>
          <span
            v-if="$route.query.state"
            class="provider-address"
          >{{ $route.query.state }},
          </span>
          <span
            v-if="$route.query.zip"
            class="provider-address">{{
              $route.query.zip
            }}</span
          >"
        </template>
        <template v-else>
          {{ formatResultsLabel(page.nearYouLabel) }}
        </template>
      </h2>
      <p
        v-if="currentNetworkName"
        class="network-and-filters-list">
        <span
        ><strong>{{ page.networkLabel }}</strong
        >: {{ currentNetworkName }}</span
        >
        <template
          v-if="resultsAreFiltered && filtersApplied.length"
          class="network-and-filters-list"
        >
          <span
            v-for="(filter, i) in filtersApplied"
            :key="i">
            <strong>{{ filter.label }}</strong
            ><template v-if="!filter.booleanType">:</template> {{ filter?.value ? filter?.value : filter }}
          </span>
        </template>
      </p>
      <localized-router-link
        to="/"
        class="inline-caret-link reverse hide-print"
        :style="globalTheme.colorPrimary"
      >
        <font-awesome-icon
          :icon="['fas', 'caret-left']"
          class="caret _mrxxs" />
        {{ page.searchAgainLabel }}
      </localized-router-link>
    </div>
  </simple-title>
</template>
<script lang="ts">
   import {defineComponent ,  PropType} from 'vue'
  import {
    SearchResultsPageLabels,
    Provider,
    FilterValues,
    FilterLabels,
    Option,
    AppliedFilter
  } from '@/types'
  import Logo from '@atoms/Logo.vue'
  import SimpleTitle from '@sections/SimpleTitleSlotted.vue'
  import { mapState } from 'vuex'
  import { createFilterAppliedString } from '@/ts/helpers/result-filter.helpers.ts'

  export default defineComponent({
    components: { SimpleTitle, Logo },
    props: {
      resultsAreFiltered: {
        type: Boolean,
        default: false
      },
      page: {
        type: Object as () => SearchResultsPageLabels,
        required: false
      },
      filteredResults: {
        type: Array as () => Provider[],
        required: true
      },
      currentNetworkName: {
        type: String,
        required: false
      },
      filterValues: {
        type: [Object, null] as PropType<FilterValues | null>,
        required: true
      },
      filterLabels: {
        required: true,
        type: Object as () => FilterLabels
      }
    },
    computed: {
      
      ...mapState({
        spokenLanguageLabel: (state: any) => {
          return state.globals.microsite.doctorLabels.spokenLanguage
        }
      }),   
      filtersApplied(): AppliedFilter[] {
        return createFilterAppliedString(
          this.filterLabels,
          this.filterValues,
          this.spokenLanguageLabel
        )
      }
    },
    methods: {
      formatResultsLabel(label: string): string {
        // if (this.$route.params.lang.toLowerCase() !== 'en') {
        //   console.log(label.replace('$', ''))
        //   return label.replace('$', '')
        // }
        const resultsHaveLength: boolean = this.filteredResults.length !== 1
        const qualifier = label.match(/[^{}]+(?=})/g)
        const plural = qualifier
          ? label.replace(/[^{}]+(?=})/g, '').replace('${}', qualifier[0])
          : label
        const singular = label.replace(/[^{}]+(?=})/g, '').replace('${}', '')
        return resultsHaveLength ? plural : singular
      }
    },
  })
</script>
<style lang="scss" scoped>
.network-and-filters-list {
  span {
    display: block;
  }
}

.title-width{
  padding-right: 8px;
}
</style>
