import { Option } from '@/types'
import { defaultLocale, localeOptions } from '@/ts/plugins/i18n.ts'

const state = {
  languageSelect: {
    options: localeOptions as Option[],
    value: defaultLocale,
  },
}

const getters = {
  selectedLanguage: state => state.languageSelect.value
}

const mutations = {
  changeSelectedLanguage(state, language) {
    state.languageSelect.value = language
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
