<template>
  <div
    class="form-group"
    style="display:block; position:fixed; top: -9999px; left:-9999px;"
  >
    <label :for="uniqueId" />
    <input
      :id="uniqueId"
      tabindex="-1"
      autocomplete="off"
      type="text"
      :name="uniqueId"
      value=""
    />
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  export default defineComponent({
    data() {
      return {
        uniqueId: 'user_info' as string
      }
    },
    mounted(): void {
      // Component returns if unique input ID to use in the parent to check if it has a value
      this.$emit('honeyPotUniqueId', this.uniqueId)
    }
  })
</script>
