<template>
  <div>
    <h3
      :style="globalTheme.colorBlack"
      class="name _pts"
      role="region"
      aria-label="heading">
      <template v-if="provider.tieredLocation">
        <div class="tiered-provider-logo flex-wrap --column">
          <img
            v-if="brandLogo"
            class="brand-logo"
            :src="brandLogo.url"
            role="img"
            :aria-label="provider.businessName">
          <span
            v-else
            class="tiered-brand-name"
            :style="globalTheme.colorBlack">
            {{provider.businessName}}
          </span>
        </div>
      </template>
      <template v-else>
        {{provider.businessName}}
      </template>
    </h3>
    <div grid="row wrap">
      <provider-address
        hide-link
        column="xs-12 s-12"
        :icon="icons.locationSvg.url"
        :provider="provider"
        role="complementary"
        class="flex-wrap directions">
      </provider-address>
      <provider-contact
        hide-link
        column="xs-12 s-12"
        :provider="provider"
        role="complementary"
        :wrap-email="true"
        class="flex-wrap">
      </provider-contact>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, ContentStackImage } from '@/types'
  import { mapState } from 'vuex'
  export default defineComponent({
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      }
    },
    computed: {
      brandLogo(){
        return this.$store.state.brandLogos.brandLogos[(this as any).provider.businessName.toLowerCase()] as ContentStackImage|undefined},
      ...mapState({
        icons: (state: any) => {
          return { 
            ...state.globals.microsite.icons.searchResultCards, 
            ...state.globals.microsite.icons.general
          }
        }
      }),
    }
  })
</script>
