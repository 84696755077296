import { getGlobals } from '@/ts/service-helpers/contentstack/get-app-data'
import { ColorOverridePaylod, Microsite, GlobalDispatchPayload } from '@/types'

const state = {
  microsite: undefined as Microsite|undefined
}

const getters = {
  microsite: state => state.microsite as Microsite
}

const mutations = {
  setMicrosite( state , res): void {
    state.microsite = res
  },
  overrideColor(state, payload: ColorOverridePaylod): void {
    state.microsite[payload.colorToOverride] = payload.colorValue
  },
  overrideHidePlusProviderSideBar(state, payload): void{
    state.microsite.hidePlusProviderSideBar = payload.hidePlusProviderSideBarNewValue
  }
}

const actions = {
    async getGlobals({ commit }, payload: GlobalDispatchPayload) {
      const microsite = await getGlobals(payload) as Microsite
      commit('setMicrosite', microsite)
    }
  }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
