<template>
  <section
    v-if="isDev"
    class="error-section _p0">
    <div
      class="error-label" 
      :style="globalTheme.backgroundColorTertiaryDark">
      <div container>
        <div :grid="`row wrap`">
          <div column="xs-12 s-12">
            <p><slot name="error-label"></slot></p>
          </div>
        </div>
      </div>
    </div>
    <div class="error-message">
      <div container>
        <div :grid="`row wrap`">
          <div column="xs-12 s-12">
            <pre><slot name="error-message"></slot></pre>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { isDev } from '@/../env.config'
  export default defineComponent({
    name: 'factory-error',
    props: {
      errorObj: {
        type: Object,
        required: false
      }
    },
    data() {
      return {
        isDev:isDev
      }
    }
  })
</script>
