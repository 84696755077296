<template>
  <div
    class="provider-sidebar search-result-card"
    :class="{tiered: provider.tieredLocation}">
    <h3
      :style="globalTheme.colorBlack"
      class="name">
      <app-inline-svg
        v-if="!provider.tieredLocation"
        :style="globalTheme.color"
        :src="icon"
        aria-label="map marker icon" />
      <template v-if="provider.tieredLocation">
        <div class="tiered-provider-logo flex-wrap --column">
          <img
            v-if="brandLogo"
            class="brand-logo"
            :src="brandLogo.url"
            :aria-label="provider.businessName">
          <span
            v-else
            class="tiered-brand-name"
            :style="globalTheme.colorBlack">
            {{provider.businessName}}
          </span>
          <tiered-provider-label :label="labels.tieredProviderLabel"/>
        </div>
      </template>
      <template v-else>
        {{provider.businessName}}
      </template>
    </h3>
    <div grid="row wrap">
      <provider-address
        hide-link
        column="xs-12 s-6"
        :icon="icons.locationSvg.url"
        :provider="provider"
        :label="labels.getDirections"
        :distance="routeDistance"
        class="flex-wrap directions">
      </provider-address>
      <provider-contact
        column="xs-12 s-6"
        :provider="provider"
        :labels="labels"
        :wrap-email="true"
        class="flex-wrap">
      </provider-contact>
    </div>
    <provider-hours
      :icon="icons.hoursSvg.url"
      :provider="provider"
      :label="labels.openToday"
      :hours-unavailable-label="labels.hoursUnavailableMessage"
      :day-labels="dayLabels">
    </provider-hours>
    <provider-services
      :icon="icons.servicesSvg.url"
      :provider="provider"
      :label="labels.services"
    />
    <provider-products
      :icon="icons.productsSvg.url"
      :provider="provider"
      :label="labels.products"
    />
    <provider-brands
      :icon="icons.brandsSvg.url"
      :provider="provider"
      :label="labels.brands"
    />
    <provider-accessibility
      :icon="icons.accessibilitySvg.url"
      :provider="provider"
      :label="labels.accessibility"
    />
    <div
      v-if="provider.doctors.length"
      class="doctor-list">
      <hr />
      <p class="label">
        <app-inline-svg
          :src="icons.providerSvg.url"
          :style="globalTheme.colorSecondary"/>
        <strong>{{labels.doctors}}</strong>
      </p>
      <app-doctor
        v-for="(doctor,i) in provider.doctors"
        :key="i"
        :provider="provider"
        :doctor="doctor"/>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, SearchResultCardLabels, DayLabels, ContentStackImage } from '@/types'
  import { mapState } from 'vuex'
  import AppDoctor from '@molecules/Results/DoctorDrawer.vue'
  export default defineComponent({
    components: {
      AppDoctor
    },
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      },
      routeDistance: {
        required: false,
        type: String
      }
    },
    data() {
      return {
        address: this.provider.address,
        brandLogo: this.$store.state.brandLogos.brandLogos[(this as any).provider.businessName.toLowerCase()] as ContentStackImage|undefined
      }
    },
    computed: {
      ...mapState({
        icons: (state: any) => {
          return { 
            ...state.globals.microsite.icons.searchResultCards, 
            ...state.globals.microsite.icons.general
          }
        },
        dayLabels: (state: any) => state.globals.microsite.days as DayLabels
      }),
      icon(): string {
        const icons = this.$store.state.globals.microsite.icons
        const tieredIcon = icons.searchResultCards.tieredProviderSvg.url
        const defaultIcon = icons.mapMarkers.mapIconBasic.url
        return (this.provider as Provider).tieredLocation ? tieredIcon : defaultIcon
      }
    }
  })
</script>
