<template>
  <div>
    <hr v-if="provider.phoneNumber" />
    <p
      v-if="provider.phoneNumber"
      class="label">
      <app-inline-svg
        :src="icons.callSvg.url"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondary"/>
      {{formatPhoneNumber(provider.phoneNumber)}}
    </p>
    <call-link
      v-if="provider.phoneNumber && !hideLink && labels"
      :phone-number="provider.phoneNumber"
      :label="labels.call"
      :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondaryDark"/>
    <hr
      v-if="provider.locationUrl && !hideLink && labels"
      class="_mt0 hide-print"/>
    <a
      v-if="provider.locationUrl && !hideLink && labels"
      class="label external-url hide-print"
      target="_blank"
      :href="provider.locationUrl"
      :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondaryDark">
      <app-inline-svg
        :src="icons.siteSvg.url"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondary"/>
      <span>{{labels.visitProviderSite}}</span>
    </a>
    <hr
      v-if="provider.locationEmailAddress && !legacyView"/>
    <p
      v-if="provider.locationEmailAddress && !legacyView"
      class="label email">
      <app-inline-svg
        :src="icons.emailSvg.url"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondary"/>
      <span :class="{hyphenate: wrapEmail}">{{provider.locationEmailAddress}}</span>
    </p>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, SearchResultCardLabels } from '@/types'
  import { formatPhoneNumber } from '@/ts/helpers/search-providers.helpers.ts'
  import CallLink from '@atoms/CallLink.vue'
  import { mapState } from 'vuex'

  export default defineComponent({
    components: { CallLink },
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      labels: {
        required: false,
        type: Object as () => SearchResultCardLabels
      },
      hideLink: {
        type: Boolean,
        default: false
      },
      legacyView: {
        default: false,
        type: Boolean
      },
      wrapEmail: {
        default: false,
        type: Boolean
      },
    },
    computed: {
      ...mapState({
        icons: (state: any) => {
          return {
            ...state.globals.microsite.icons.searchResultCards,
            ...state.globals.microsite.icons.general
          }
        }
      }),
      isValidTierName() {
        const tierName = this.provider.tierName
        return (tierName === 'Walmart' || tierName === 'Costco')
      },
      isDiscountOptOut() {
        const locPreferences = this.provider.locationPreference
        return locPreferences.discountOptOut === 'true'
      }
    },
    methods: {
      formatPhoneNumber
    }
  })
</script>
