 import {defineComponent} from 'vue'
import { scheduleViewChildRoutes } from '@/router.ts'
export default defineComponent({
  props: {
    localizedRoute: {
      required: true,
      type: String
    }
  },
  methods: {
      returnToFirstRoute(): void {
        const nextRoute = scheduleViewChildRoutes[0]
        this.$router
          .replace({
            name: nextRoute.name,
            path: `${this.localizedRoute}/schedule/${nextRoute.path}`
          })
          .catch()
      },
  }
})
