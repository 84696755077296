import {
  FormattedFilterList,
  Option,
  FilterListData,
  FilterResObject
} from '@/types'

const sortByPriority = (a, b) => a.priorityOrder - b.priorityOrder

function createOptionsFromFilterResObjects(
  filterResObjects: FilterResObject[]
): Option[] {
  if (!filterResObjects.length) return []
  return filterResObjects.map(
    (filterResObject: any): Option => {
      return {
        value: filterResObject.name,
        label: filterResObject.name,
        code: filterResObject.code
      }
    }
  )
}

export function filterListFactory(res: FilterListData): FormattedFilterList {
  const filters = {
    ...res,
    languages: createOptionsFromFilterResObjects(res.languages),
    hours: [
      { code: 1, value: 'eveningHours', label: 'Evening Hours' },
      { code: 2, value: 'weekendHours', label: 'Weekend Hours' },
      { code: 3, value: 'schedulingLink', label: 'Online Scheduling Available' }
    ],
    products: createOptionsFromFilterResObjects(res.products),
    gender: [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' }
    ],
    brands: createOptionsFromFilterResObjects(res.brands),
    designations: createOptionsFromFilterResObjects(res.designations).map(
      (des) => {
        if (des.value === 'O.D.') {
          des.label = 'OD - Optometrist'
        } else if (des.value === 'M.D.') {
          des.label = 'MD - Ophthalmologist'
        } else if (des.value === 'D.O.') {
          des.label = 'DO - Doctor Of Osteopathy'
        }
        return des
      }
    ),
    services: createOptionsFromFilterResObjects(res.services),
    acceptingNew: [
      { value: 'No', label: 'No' },
      { value: 'Yes', label: 'Yes' }
    ],
    culturalCompetency: [
      { value: 'Yes', label: 'Yes' },
      { value: 'Unknown', label: 'Unknown' }
    ]
    // covidCompliant: [
    //   { value: 'No', label: 'No' },
    //   { value: 'Yes', label: 'Yes' }
    // ]
  }
  return filters
}
