<template>
  <div v-if="currentRouteIndex === 1">
    <div
      v-for="(user, i) in appointmentsPayload"
      :key="i"
      class="user-sidebar-info _mbs">
      <p
        v-if="currentPatientIndex === i || readOnly" 
        :style="user.appointment ? globalTheme.colorPrimaryDark : globalTheme.colorSecondaryDark"
        class="schedule-title">
        <template v-if="appointmentsPayload.length > 1 && !readOnly">
          <font-awesome-icon
            :icon="{prefix: 'far', iconName: 'dot-circle'}"
            class="_mrxxs"
          />
        </template>{{user.patient.firstName}} {{user.patient.lastName}}
        <font-awesome-icon
          v-if="!readOnly"
          :icon="user.appointment ? {prefix: 'far', iconName: 'calendar-check'} : {prefix: 'far', iconName: 'calendar'}"
          class="_mla"
        />
      </p>
      <button
        v-else
        :style="user.appointment ? globalTheme.colorPrimaryDark : globalTheme.colorSecondaryDark"
        class="simple schedule-title"
        :aria-label="`schedule appointment for ${user.patient.firstName} ${user.patient.lastName}`"
        @click="setPatientIndex(i)">
        <font-awesome-icon
          :icon="currentPatientIndex === i ? {prefix: 'far', iconName: 'dot-circle'} : {prefix: 'far', iconName: 'circle'}"
          class="_mrxxs"
        />{{user.patient.firstName}} {{user.patient.lastName}}<font-awesome-icon
          :icon="user.appointment ? {prefix: 'far', iconName: 'calendar-check'} : {prefix: 'far', iconName: 'calendar'}"
          class="_mla"
        />
      </button>
      <div
        class="themed-strip"
        :style="user.appointment ? globalTheme.borderColorPrimary : globalTheme.borderColorSecondary" />
      <div
        v-if="currentPatientIndex === i && !user.appointment"
        class="_ptxs _pbxs _plxs _prxs bg-offwhite">
        <br />
        <p class="time">
          {{labels.nowScheduling}}
        </p>
        <br />
      </div>
      <div
        v-else-if="user.appointment"
        class="_ptxs _pbxs _plxs _prxs bg-offwhite">
        <p class="_mbxs">{{user.appointment.prettyDate}}</p>
        <p class="_mbxs time">{{removeLeadingZero(user.appointment.availableTime)}}</p>
        <p>Dr. {{user.appointment.providerLastName}}</p>
      </div>
      <div
        v-else
        class="_ptxs _pbxs _plxs _prxs bg-offwhite">
        <br />
        <p class="time">
          {{labels.awaitingScheduling}}
        </p>
        <br />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { UserCreatedAppointment, PatientStatusLabels } from '@/types'
  import { removeLeadingZero } from '@/ts/helpers/schedule.helpers.ts'
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      currentRouteIndex: {
        required: true,
        type: Number
      },
      currentPatientIndex: {
        required: true,
        type: Number
      },
      appointmentsPayload: {
        required: true,
        type: Array as () => UserCreatedAppointment[]
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      labels: {
        type: Object as () => PatientStatusLabels,
        required: true
      }
    },
    methods: {
      removeLeadingZero,
      setPatientIndex(i: number): void {
        this.$emit('setPatientIndex', i)
      }
    }
  })
</script>
