import {
  AppointmentsResponse,
  AvailableAppointments,
  AvailableAppointment,
  AvailableExamsOnDay
} from '@/types'
import date from 'date-and-time'

function setAppointmentTimeOfDay(
  availableTime: string
): 'morning' | 'afternoon' | 'evening' {
  const lastChars = availableTime.substr(availableTime.length - 2)
  if (lastChars.toLocaleLowerCase() === 'am') {
    return 'morning'
  }
  const convertLastChars =
    lastChars
      .toLocaleLowerCase()
      .split('')
      .join('.') + '.'
  const convertTime = availableTime.replace(lastChars, convertLastChars)
  const dateFromTime = date.parse(convertTime, 'hh:mm A')
  const fiveOclockSomewhere = 1020 // (17 hours times 60 minutes per hour = total minutes to 5 pm)
  const hoursAsMinutes = parseInt(date.format(dateFromTime, 'HH')) * 60
  const remainderMinutes = parseInt(date.format(dateFromTime, 'HH'))
  const appointmentTimeAsMinutes = hoursAsMinutes + remainderMinutes
  if (appointmentTimeAsMinutes > fiveOclockSomewhere) {
    return 'evening'
  }
  return 'afternoon'
}

function createAppointment(
  appointment: AvailableAppointment,
  apptDate: string
): AvailableAppointment {
  return {
    providerFirstName: appointment.providerFirstName,
    providerLastName: appointment.providerLastName,
    scheduleId: appointment.scheduleId,
    availableTime: appointment.availableTime,
    timeOfDay: setAppointmentTimeOfDay(appointment.availableTime),
    apptDate: apptDate,
    asDate: date.parse(apptDate, 'MM/DD/YYYY')
  }
}

export function availableAppointments(
  res: AppointmentsResponse
): AvailableAppointments {
  res.schedules = res.schedules.map(schedule => {
  return { ...schedule, schedules: schedule.availableSlots }
  })
  const middleIndex = Math.round((res.schedules.length - 1) / 2)
  return {
    appointments: res.schedules[middleIndex].schedules.map(schedule =>
      createAppointment(schedule, res.schedules[middleIndex].apptDate)
    ),
    availableExams: res.schedules.map(appointment => {
      return {
        apptDate: appointment.apptDate,
        asDate: date.parse(appointment.apptDate, 'MM/DD/YYYY'),
        totalAvailable: appointment.totalAvailable,
        schedules: appointment.schedules.map(schedule =>
          createAppointment(schedule, appointment.apptDate)
        )
      }
    })
  }
}
