<template>
  <div class="frm-toggle">
  <ValidationProvider
    v-slot="{ field,errors }"
    :mode="validationMode"
    :name="id"
    :value="modelValue"
    :rules="required ? { required:  modelValue===null || modelValue===false ? true:false } : ''"

    tag="div"
  >
    <input
    v-bind="field"
      tabindex="0"
      :id="id"
      :checked="modelValue===true"
      :disabled="disabled"
      :class="{error: errors.length > 0}"
      type="checkbox"
      :name="id"
      @input="updateValue"/>
    <label
      style="margin-bottom:0"
      :for="id"
      :class="[{disabled:disabled}, {invalid: (errors.length > 0)}]"
      aria-live="polite">
      {{ label }} <span
        v-if="required && errors.length>0"
        class="lips validation">*</span>
      <span
        v-if="(errors.length > 0)"
        class="lips validation _mlxxs"
      >{{ validationMessage || errors[0] }}</span>
      <div
        class="scroll-anchor"
        :class="{'invalid-anchor' : errors.length > 0}" />
    </label>
  </ValidationProvider>
</div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  export default defineComponent({

    props: {
      modelValue: {
        type: Boolean,
        default: false
      },
      id: {
        type: String as () => string,
        default: ''
      },
      label: {
        type: String as () => string,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      validationMode: {
        type: String as () => string,
        default: 'eager'
      },
      validationMessage: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods:{
      updateValue(e) {
        let value = e.target.checked
        this.$emit('update:modelValue', value)
      }
    },
  })
</script>
<style lang="scss" scoped>
label.disabled {
  text-decoration: line-through
}
</style>
