<template>
  <app-section
    class="modal"
    :grid="framed ? 'top justify-center' : 'center justify-center'"
    role="alert"
    @click="$emit('closeModal')">
    <card column="xs-12 s-12 m-7">
      <button
        ref="modal_close"
        class="close-modal btn-icon"
        :style="globalTheme.buttonSecondary"
        aria-label="Close"
        @click="$emit('closeModal')"
        @keydown.enter="$emit('closeModal')">
        <font-awesome-icon
          icon="times"
          :style="globalTheme.white"
        /> </button>
      <slot />
    </card>
  </app-section>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import Card from '@atoms/Card.vue'
  import { trapFocus } from '@/ts/dom-helper.methods.ts'
  export default defineComponent({
    components: {
      Card
    },
    props: {
      framed: {
        type: Boolean,
        default: false
      }
    },
    mounted(): void {
      //@ts-ignore
      this.$refs.modal_close.focus()
      trapFocus(this.$el as HTMLElement)
    }
  })
</script>
<style lang="scss">
.modal {
  overflow: scroll;
  padding:0;
    .card {
        position: relative;
    }
    .close-modal {
        position: absolute;
        top:0;
        right:0;
    }
    border:none !important;
}
.modal [grid] {
    height:100%;
}
.modal .card.default-card {
  margin: 4rem 2rem;
padding: 2.5rem 4rem 2rem;
}
</style>
