<template>
  <button
    :class="classOverride"
    :style="styleOverride ? styleOverride : defaultButtonStyle"
    @click="printPage">
    <app-inline-svg
      v-if="printIcon"
      class="_mrxxs btn-svg"
      :src="printIcon"
      :style="globalTheme.colorSecondary"/>
    <slot></slot>
  </button>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { eventBus } from '@/main'
  export default defineComponent({
    props: {
      printIcon: {
        required: false,
        type: String
      },
      classOverride: {
        type: String,
        default: 'sm white bordered with-svg btn-print'
      },
      styleOverride: {
        type: [Object, Array],
        required: false
      }
    },
    computed: {
      defaultButtonStyle(): object {
        //@ts-ignore
        return this.globalTheme.buttonWhiteBorderedSecondary
      }
    },
    methods: {
      printPage(e): void {
        eventBus.$emit('printPage')
      }
    }
  })
</script>
