import { preferredLanguage } from '@/ts/consts'

export const localeOptions = [
    {
        value: 'en',
        label: 'English',
      },
      {
        value: 'es',
        label: 'Español',
      }
]

export const locales = localeOptions.map(locale => locale.value)

export const defaultLocale = locales.includes(preferredLanguage) ? preferredLanguage : 'en'
