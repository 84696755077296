<template>
  <span>
    {{doctor.firstName}} {{doctor.middleName}} {{doctor.lastName}}<template v-if="doctor.professionalDesignation ||doctor.suffix">, {{doctor.professionalDesignation || doctor.suffix}}</template>
  </span>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Doctor } from '@/types'
  export default defineComponent({
    props: {
      doctor: {
        required: true,
        type: Object as () => Doctor
      }
    },
  })
</script>
