<template>
  <component :is="'section'">
    <slot name="above-container"></slot>
    <div container>
      <slot name="above-grid"></slot>
      <div :grid="`row wrap ${grid}`">
        <slot></slot>
      </div>
      <slot name="below-grid"></slot>
    </div>
    <slot name="below-container"></slot>
  </component>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  export default defineComponent({
    name: 'section-wrapper',
    props: {
      grid: {
        required: false,
        type: String
      }
    }
  })
</script>
