<template>
  <footer>
    <div class="sub-footer">
      <div container>
        <div grid="row wrap center">
          <div column="xs-12 s-12 m-12 l-4">
            <img
              v-if="subFooterLockup"
              :src="subFooterLockup.url"
              :alt="subFooterLockup.description ? subFooterLockup.description: null"
              class="lockup"
            >
          </div>
          <div
            class="sub-foot-nav"
            column="xs-12 s-12 m-12 l-8"
          >
            <nav aria-label="footer sub-nav">
              <ul>  
                <li
                  v-for="(link, i) in navItems"
                  :key="i"
                >
                  <localized-router-link
                    :to="link.href"
                    :style="globalTheme.color"
                  >{{link.title}}</localized-router-link>
                </li>
              </ul>
            </nav>
            <nav
              aria-label="footer social nav"
              class="social">
              <a
                v-for="(link, i) in socialLinks"
                :key="i"
                :href="link.url"
                target="_blank">
                <img
                  :alt="link.alt"
                  :src="link.iconPath">
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isTestingEnvironment()"
      class="bg-offwhite">
      <div
        container
        class="_ptxs _pbxs">
        Version: 1.2
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { FooterNavigation } from '@/types'

 

  export function isTestingEnvironment(): boolean {
    return import.meta.env.NODE_ENV !== 'production' || window.location.host === 'locatoruat.eyemedvisioncare.com'
  }
  export default defineComponent({
    name: 'appFooter' as string,
    data() {
      return {
        ...this.$store.state.globals.microsite.footer
      } as FooterNavigation
    },
    methods: {
      removeSlash(url: string): string {
        return url.replace(/^\/|\/$/g, '')
      },
      isTestingEnvironment
    }
  })
</script>
