<template>
  <div
    ref="card"
    aria-live="polite"
    class="card fade-in">
    <div
      grid="row wrap justify-center"
      class="schedule-card-grid">
      <div
        column
        class="content-column">
        <slot></slot>
      </div>
      <div
        :column="sidebarColumn"
        class="sidebar-column">
        <div
          class="sidebar search-result-card">
          <slot name="sidebar"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { eventBus } from '@/main'
  export default defineComponent({
    props: {
      sidebarColumn: {
        type: String,
        default: 'xs-12 s-12 m-4 l-5'
      }
    }
  })
</script>
