import { DirectionsRequest, ServiceResponseMessage } from '@/types'

function modifyGoogleErrorString(someString):string {
  const lowerCaseString = someString.replace(/_/g, ' ').toLowerCase()
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
}

export async function getDirections(
  requestObj: DirectionsRequest,
  google: any
): Promise<any|ServiceResponseMessage> {
  const directionsService = new google.maps.DirectionsService()
  return new Promise((resolve, reject) => {
    directionsService.route(
      {
        origin: requestObj.origin,
        destination: requestObj.destination,
        travelMode: google.maps.TravelMode[requestObj.travelMode]
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result)
        } else {
          reject({
            messageCode: `GOOGLE_DIRECTIONS_NOT_FOUND`,
            defaultMessage: modifyGoogleErrorString(result.status)
          } as ServiceResponseMessage)
        }
      }
    )
  })
}
