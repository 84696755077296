import { pageFactory } from '@/factories/contentstack/page.factory'
import { ContentStackPagePayload } from '@/types'
import { getStackPageByType } from '@/ts/service-contentstack.methods'

export async function getData(
  stackPayLoad: ContentStackPagePayload
): Promise<any> {
  const contentStackPage = await getStackPageByType(stackPayLoad)
  if (!contentStackPage.errorMessage) {
    const page = pageFactory(contentStackPage)
    return {
      page: page
    }
  } else {
    return contentStackPage.errorMessage
  }
}
