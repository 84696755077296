import { service } from '@/ts/service.methods'
import {
  InternalErrorResponse,
  AppointmentPayload,
  AppointmentSetSuccess,
  UserCreatedAppointment
} from '@/types'
import {
  generateServiceError,
  handleServiceResponse,
  formatServiceError
} from '@/ts/service.locator.response-handling'
import { isMock } from '@/../env.config'
import { isIE } from '@/ts/helpers/IE'
import { unlockAppointment, unlockSbAppointment} from '@/ts/service-helpers/services/lock-appointment.ts'

function confirmApptFactory(res: AppointmentSetSuccess): AppointmentSetSuccess {
  return res
}

const mockedApptsFailure = {
  message: 'Failure',
  messageCode: 'APT008'
}

function mockFlow(
  appts,
  appointmentsPayload
): AppointmentSetSuccess[] | InternalErrorResponse {
  if (appts.length > 1) {
    appts.map((appt, i) => {
      if (i === 2) {
        appointmentsPayload[i].failedToSchedule = true
        appointmentsPayload[i].serviceError = {
          defaultMessage: 'failure',
          messageCode: 'APT008'
        }
      }
    })
    throw mockedApptsFailure
  } else {
    return require('@/mock-data/appt-confirmed.mock.ts') as AppointmentSetSuccess
  }
}

export async function confirmAppointment(
  appts: AppointmentPayload[],
  appointmentsPayload: UserCreatedAppointment[]
): Promise<AppointmentSetSuccess[] | InternalErrorResponse> {
  if (isMock) {
    const promise1 = Promise.resolve(3)
    const promise2 = new Promise((resolve, reject) =>
      setTimeout(resolve, 100, 'foo')
    )
    const promises = [promise1, promise2]
    if (isIE()) {
      return new Promise((resolve, reject) => {
        return Promise.all(promises)
          .then(res =>
            handleServiceResponse(
              resolve,
              reject,
              res,
              confirmApptFactory,
              'confirmApptFactoryFactory error'
            )
          )
          .catch(err =>
            generateServiceError(
              reject,
              err,
              'confirm-appointments.ts/confirmAppointment'
            )
          )
      }).then(() => {
        return mockFlow(appts, appointmentsPayload)
      })
    } else {
      return Promise.allSettled(promises).then(() => {
        return mockFlow(appts, appointmentsPayload)
      })
    }
  }
  const allApptsToSend = appts.map((appt, i) => {
    return new Promise((resolve, reject) => {
      service
        .post('appointment', appt)
        .then(res => {
          if (res.data.messageCode === 'APT007') {
            handleServiceResponse(
              resolve,
              reject,
              res,
              confirmApptFactory,
              'confirmApptFactoryFactory error'
            )
          } else {
            appointmentsPayload[i].failedToSchedule = true
            appointmentsPayload[i].serviceError = {
              defaultMessage: res.message,
              messageCode: res.messageCode
            }
            unlockAppointment({ lockId: appt.lockId })
            generateServiceError(
              reject,
              res,
              'confirm-appointments.ts/confirmAppointment'
            )
          }
        })
        .catch(err => {
          appointmentsPayload[i].failedToSchedule = true
          appointmentsPayload[i].serviceError = formatServiceError(err) || {
            messageCode: '',
            defaultMessage: ''
          }
          unlockAppointment({ lockId: appt.lockId })
          reject(err)
        })
    })
  })
  return new Promise((resolve, reject) => {
    if (isIE()) {
      return Promise.all(allApptsToSend)
        .then(res =>
          handleServiceResponse(
            resolve,
            reject,
            res,
            confirmApptFactory,
            'confirmApptFactoryFactory error'
          )
        )
        .catch(err =>
          generateServiceError(
            reject,
            err,
            'confirm-appointments.ts/confirmAppointment'
          )
        )
    } else {
      Promise.allSettled(allApptsToSend)
        .then(res =>
          handleServiceResponse(
            resolve,
            reject,
            res,
            confirmApptFactory,
            'confirmApptFactoryFactory error'
          )
        )
        .catch(err =>
          generateServiceError(
            reject,
            err,
            'confirm-appointments.ts/confirmAppointment'
          )
        )
    }
  })
}

export async function confirmSbAppointment(
  appts: AppointmentPayload[],
  appointmentsPayload: UserCreatedAppointment[]
): Promise<AppointmentSetSuccess[] | InternalErrorResponse> {
  if (isMock) {
    const promise1 = Promise.resolve(3)
    const promise2 = new Promise((resolve, reject) =>
      setTimeout(resolve, 100, 'foo')
    )
    const promises = [promise1, promise2]
    if (isIE()) {
      return new Promise((resolve, reject) => {
        return Promise.all(promises)
          .then(res =>
            handleServiceResponse(
              resolve,
              reject,
              res,
              confirmApptFactory,
              'confirmApptFactoryFactory error'
            )
          )
          .catch(err =>
            generateServiceError(
              reject,
              err,
              'confirm-appointments.ts/confirmSbAppointment'
            )
          )
      }).then(() => {
        return mockFlow(appts, appointmentsPayload)
      })
    } else {
      return Promise.allSettled(promises).then(() => {
        return mockFlow(appts, appointmentsPayload)
      })
    }
  }
  const allApptsToSend = appts.map((appt, i) => {
    return new Promise((resolve, reject) => {
      service
        .post('sbappointment', appt)
        .then(res => {
          if (res.data.messageCode === 'APC000') {
            handleServiceResponse(
              resolve,
              reject,
              res,
              confirmApptFactory,
              'confirmApptFactoryFactory error'
            )
          } else {
            appointmentsPayload[i].failedToSchedule = true
            appointmentsPayload[i].serviceError = {
              defaultMessage: res.message,
              messageCode: res.messageCode
            }
            unlockSbAppointment({ lockId: appt.lockId })
            generateServiceError(
              reject,
              res,
              'confirm-appointments.ts/confirmSbAppointment'
            )
          }
        })
        .catch(err => {
          appointmentsPayload[i].failedToSchedule = true
          appointmentsPayload[i].serviceError = formatServiceError(err) || {
            messageCode: '',
            defaultMessage: ''
          }
          unlockSbAppointment({ lockId: appt.lockId })
          reject(err)
        })
    })
  })
  return new Promise((resolve, reject) => {
    if (isIE()) {
      return Promise.all(allApptsToSend)
        .then(res =>
          handleServiceResponse(
            resolve,
            reject,
            res,
            confirmApptFactory,
            'confirmApptFactoryFactory error'
          )
        )
        .catch(err =>
          generateServiceError(
            reject,
            err,
            'confirm-appointments.ts/confirmSbAppointment'
          )
        )
    } else {
      Promise.allSettled(allApptsToSend)
        .then(res =>
          handleServiceResponse(
            resolve,
            reject,
            res,
            confirmApptFactory,
            'confirmApptFactoryFactory error'
          )
        )
        .catch(err =>
          generateServiceError(
            reject,
            err,
            'confirm-appointments.ts/confirmSbAppointment'
          )
        )
    }
  })
}
