import { service } from '@/ts/service.methods'
import { InternalErrorResponse } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { CountiesPayload, CountiesResults } from '@/types/services/counties-list'
import { getCountiesFactory } from '@/factories/services/get-counties-factory'

export async function getCounties(countiesPayload: CountiesPayload):Promise<CountiesResults|InternalErrorResponse> {
    return new Promise((resolve,reject) => {
        service.post('getCounties', countiesPayload)
        .then(res => {
            if (res.data.messageCode === 'COUNTY001') {
                handleServiceResponse(resolve,reject,res,getCountiesFactory,'getCountiesFactory error')
            } else {
                generateServiceError(reject, res, 'get-counties.ts/getCounties')
            }
        })
        .catch(err => generateServiceError(reject, err, 'get-counties.ts/getCounties'))
    })
}
