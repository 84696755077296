import {
  DigitalDataObject,
  SearchInfo,
  AdobeGroupObject,
  Provider,
  FilterValues,
  Option,
  CurrentProvider
} from '@/types'
import { isMock } from '@/../env.config.ts'
import { analyticsUrl } from '@/ts/analytics-loader'

function setMappedFilterValues(
  filterValue: null | Option[]
): (number | undefined)[] {
  return filterValue ? filterValue.map(fv => fv?.code) : []
}

export function trackSearchEvent(
  eventName: string,
  filterValues: FilterValues,
  results: Provider[],
  currentPage: number,
  zipCode: string|undefined,
  providerName: string = '',
  currentNetworkName: string|null
): void {
  // results = colon seperated string as bandId:resultPosition:hasSchedulingLink:tier:providerLocationId
  const formattedResults: string[] = results.map(
    (result: Provider, i): string => {
      const hasSchedulingLink =
        result.locationUrl !== null && result.locationUrl !== undefined
          ? 'Y'
          : 'N'
      const tier = result.tieredLocation === true ? 'Y' : 'N'
      const resultPosition =
        currentPage === 1 ? i + 1 : (currentPage - 1) * 10 + (i + 1)
      return `${result.bandId}:${resultPosition}:${hasSchedulingLink}:${tier}:${result.locationId}`
    }
  )
  const searchInfo: SearchInfo = {
    brands: filterValues?.brands,
    services: filterValues?.services,
    products: filterValues?.products,
    hours: filterValues?.hours ? [filterValues?.hours.code] : [],
    language: filterValues?.languages
      ? (filterValues?.languages.value as string)
      : '',
    zipCode: zipCode ? zipCode : 'My Location',
    providerName: providerName,
    speciality: filterValues?.designations
      ? (filterValues?.designations.value as string)
      : '',
    acceptingNewPatientsOnly: filterValues?.acceptingNew,
    culturalCompetency: filterValues?.culturalCompetency
    ? (filterValues?.culturalCompetency.value as 'Yes' | 'Unknown')
    : '',
    gender: filterValues?.gender
      ? (filterValues?.gender.value as 'Male' | 'Female')
      : '',
    network: filterValues?.networkId ? currentNetworkName || filterValues?.networkId.label : currentNetworkName || 'Advantage Network'
  }
  window.digitalData.trackSearchEvent(eventName, searchInfo, formattedResults)
}

export function trackEvent(eventName: string, maybeOptions?: any): void {
  window.digitalData.trackEvent(eventName, maybeOptions)
}

interface AdobeDataTrackingInitPayload {
  sysEnv: 'mobileApp' | 'browser';
  clientID: string;
  groupId?: string;
}

export function updateAdobeDigitalData(payload: AdobeDataTrackingInitPayload) {
  window.digitalData.page.pageInfo.sysEnv = payload.sysEnv
  window.digitalData.clientID = payload.clientID
  if (payload.groupId) {
    window.digitalData.group.activeGroupID = payload.groupId
  }
}

export function addAdobeDigitalData(
  payload?: AdobeDataTrackingInitPayload
): void {
  window.digitalData = {
    version: '2.0',
    page: {
      pageInfo: {
        pageName: '',
        sysEnv: ''
      }
    },
    user: {
      profile: {
        isLoggedIn: false,
        profileInfo: {
          loginType: '' //sso, manual, or auto if registration link
        }
      }
    },
    clientID: '',
    group: {
      activeGroupID: '',
      groupList: '' // comma seperated list
    },
    event: [],
    trackPageView: function(pageName) {
      window.digitalData.page.pageInfo.pageName = pageName
      window.digitalData.event.push({ eventName: pageName, type: 'Page View' })
      window.digitalData._sendToAnalyticsServer('event-page-view')
    },
    trackEvent: function(eventName: string, parameters) {
      window.digitalData.event.push({
        eventName: eventName,
        type: 'Event',
        parameters
      })
      window.digitalData._sendToAnalyticsServer(eventName, parameters)
    },
    trackSearchEvent: function(
      eventName: string,
      searchInfo: SearchInfo,
      results: string[]
    ) {
      window.digitalData.event.push({
        eventName,
        type: 'Event',
        searchInfo,
        results
      })
      const eventIndex = window.digitalData.event.length - 1
      window.digitalData._sendToAnalyticsServer(eventName, {
        eventIndex: eventIndex
      })
    },
    _sendToAnalyticsServer: function(event, parameters) {
      if (isMock) return
      const interval = setInterval(function() {
        if (typeof window['_satellite'] === 'undefined') {
          return
        } else {
          window['_satellite'].track(event, parameters)
          clearInterval(interval)
        }
      }, 100)
    }
  } as DigitalDataObject
}

// Unique tracking events

export function updateAdobeClientID(clientID: string): void {
  window.digitalData.clientID = clientID
}

export function updateAdobeGroup(groupPayload: AdobeGroupObject): void {
  window.digitalData.group = groupPayload
}

export function trackPageView(pageName?: string | null): void {
  window.digitalData.trackPageView(pageName ? pageName : 'route with no name')
}

// Scheduling tracking events

export function trackExternalScheduleLinkClick(
  externalScheduleLink: string,
  provider: CurrentProvider
): void {
  window.digitalData.trackEvent('event-schedule-link-clicked', {
    externalScheduleLink: externalScheduleLink,
    ...setProviderTrackingDetails(provider)
  })
}

export function trackSchedulingStep(
  event: string,
  provider: CurrentProvider
): void {
  window.digitalData.trackEvent(event, {
    ...setProviderTrackingDetails(provider)
  })
}

interface TrackingDetails {
  zipCode: string;
  tier: 'Y'|'N';
  bandId: string | null;
  bandDescription: string | null;
  providerLocationId: string | null;
  externalStoreNumber: string | null;
}

function setProviderTrackingDetails(
  provider: CurrentProvider
): TrackingDetails {
  return {
    zipCode: provider.searchedZipCode
      ? provider.searchedZipCode
      : 'My Location',
    tier: provider.tieredLocation === true ? 'Y' : 'N',
    bandId: provider.bandId,
    bandDescription: provider.bandDescription,
    providerLocationId: provider.locationId,
    externalStoreNumber: provider.externalStoreNumber
  }
}
