<template>
  <select
    v-if="isMock"
    v-model.trim="currentDomain"
    class="_mlxs domain-selector"
  >
    <option
      selected
      disabled
      value="">Pick a Microsite</option>
    <option
      v-for="option in options"
      :key="option"
      :value="option"
    >{{ option }}</option>
  </select>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { getMicrositeList } from '@/ts/service-helpers/contentstack/get-app-data'
  import  { isMock }  from '@/../env.config'
  export default defineComponent({
    data() {
      return {
        isMock,
        options: [],
        currentDomain: this.$route.params.clientID ? this.$route.params.clientID: ''
      }
    },
    watch: {
      currentDomain : {
        handler(val) {
        this.changeMicrositeAndReloadApp(val)
        },
        deep:true
      }
    },
    methods: {
      changeMicrositeAndReloadApp(option: string): void {
        this.$router.replace({ path: `/${option}/${this.$route.params.lang}/` }).catch(err => {})
      }
    },
    async mounted() {
      if (!isMock) return
      const options = await getMicrositeList()
      //@ts-ignore
      this.options = Object.values(options).map(option => option.clientId).sort()
    }
  })
</script>
