<template>
  <div style="display:inline-block;">
    <span
      tabindex="0"
      aira-label="Tooltip question mark"
      @click="openModal"
      @keyup.enter="openModal"
    >
      <font-awesome-icon
        aria-label="Tooltip question mark"
        icon="info-circle"
        :style="globalTheme.colorSecondary"
      />
    </span>
    <simple-modal
      v-if="show"
      :framed="framed"
      @closeModal="closeModal">
      <div v-html="message"></div>
    </simple-modal>
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  export default defineComponent({
    name: 'tooltip',
    props: {
      message: {
        type: String
      }
    },
    data() {
      return {
        show: false as boolean
      }
    },
    computed: {
      framed(): boolean {
        return this.$store.state.context.framed
      }
    },
    methods: {
      openModal(): void {
        (this as any).show = true
        document.body.classList.add('lock-body')
        if (!this.framed) return
        try {
          window.scrollTo(0,0)
          window.parent.parent.scrollTo(0,0)
        } catch {
          parent.postMessage('snap_to_top', '*')
        }
      },
      closeModal(): void {
        (this as any).show = false
        document.body.classList.remove('lock-body')
      }
    }
  })
</script>

<style lang="scss" scoped>
  svg {
    color: $leaf;
    fill: currentColor;
    path {
    fill: currentColor !important;
    }
  }
  span:hover {
    cursor: pointer;
  }
</style>
