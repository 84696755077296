<template>
  <div
    v-if="!errorObj.devError && !hide"
    class="card flash-notification _text-center">
    <h3
      v-if="hasTitle"
      :style="globalTheme.colorTertiaryDark">
      <slot name="title"></slot>
    </h3>
    <response-message-naked
      :error-obj="errorObj"
      :class="{_pt0: !hasTitle}"/>
    <button
      :style="globalTheme.colorBlack" 
      class="simple close"
      @click="close">
      <font-awesome-icon
        icon="times"
        aria-label="close notification"
      />
    </button>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { eventBus } from '@/main'
  import { ServiceResponseMessage } from '@/types'
  export default defineComponent({
    props: {
      errorObj: {
        type: Object as () => ServiceResponseMessage,
        required: true
      }
    },
    data() {
      return {
        hide: false as boolean
      }
    },
    computed: {
      hasTitle(): boolean {
        return !!this.$slots.title
      },
    },
    methods: {
      close(): void {
        this.hide = true
        this.$emit('closeNotification')
        eventBus.$emit('iframeResizeEvent')
      }
    }
  })
</script>
