import {
  ProviderSearchPayload,
  ProviderSearchAddressPredictionOrVerificationPayload,
  Provider,
  ProviderAddress,
  FilterValues,
  Option,
  Doctor,
  DoctorListFilters
} from '@/types'
import { cloneDeep } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import { caseInsensetiveQueryKeyMatch } from '@/ts/helpers'

export function resultsAreFiltered(query: any): boolean {
  return (
    query.hours !== undefined ||
    query.languages !== undefined ||
    query.products !== undefined ||
    query.gender !== undefined ||
    query.brands !== undefined ||
    query.designations !== undefined ||
    query.services !== undefined
    // (query.covidCompliant !== undefined &&
    //   query.covidCompliant !== 'No' &&
    //   query.covidCompliant !== 'no')
    //query.acceptingNew !== 'Yes' // client is unsure of whether they even want this to filter anything so for now do not delete any commented out code dealing with the acceptingNew filter until we have definitive answers (who knows, it all may go back in)
  )
}

import { unkownQualifier } from '@/factories/services/network-list'
export function setSearchPayloadFromQuery(query: any): ProviderSearchPayload {
  const zip = query.zip as string
  const city = query.city as string
  const state = query.state as string
  const networkSetId = caseInsensetiveQueryKeyMatch('networkSetId', query)
  const addressExists = (zip !== undefined ||
    city !== undefined ||
    state !== undefined) as boolean
  const payload = {
    tieredView: query.tieredView ? query.tieredView : ('false' as string),
    sortByZipClass: query.sortByZipClass ? query.sortByZipClass: ('true' as string),
    radius: query?.radius,
    placeId: query?.placeId,
    ...(query.networkId && {
      networkId: query.networkId.replace(unkownQualifier, '') as string
    }),
    ...(query.providerName && { providerName: query.providerName as string }),
    ...(query.latitude && { latitude: query.latitude as string }),
    ...(query.longitude && { longitude: query.longitude as string }),
    ...(query.groupId && { groupId: query.groupId as string }),
    ...(query.classId && { classId: query.classId as string }),
    ...(query.classPlanId && { classPlanId: query.classPlanId as string }),
    ...(networkSetId && { networkSetId: networkSetId as string }),
    ...(query.locationId && { locationId: query.locationId as string }),
    ...(query.clientId && { clientId: query.clientId as string }),
    ...(query.npi  && { npi : query.npi  as string }),
    ...(query.licenseNo && { licenseNo: query.licenseNo as string }),
    ...(query.phoneNumber && { phoneNumber: query?.phoneNumber as string })
  } as any
  const address = {
    ...(query.zip && { zip: query.zip as string }),
    ...(query.city && { city: query.city as string }),
    ...(query.state && { state: query.state as string }),
    ...(query.county && { county: query.county as string })
  }
  if (addressExists) {
    payload.address = address
  }
  return payload
}

export function setSearchByDoctorAddressPayloadFromQuery(query: any): ProviderSearchAddressPredictionOrVerificationPayload {
  const zip = query?.zip as string
  const city = query?.doctorCity as string
  const state = query?.doctorState as string
  const streetAdress = query?.doctorStreetaddress as string
  const addressExists = (zip !== undefined ||
    city !== undefined ||
    streetAdress !== undefined ||
    state !== undefined) as boolean
  const payload = {
    tieredView: query.tieredView ? query.tieredView : ('false' as string),
    sortByZipClass: query.sortByZipClass ? query.sortByZipClass: ('true' as string),
    clientId: query?.clientId,
  } as any
  const address = {
    zip: zip,
    state: state,
    city: city,
    streetAddress1: streetAdress
  }
  if (addressExists) {
    payload.address = address
  }
  return payload
}

export function setQueryStringFromArray(optionsArray: Option[]): string {
  return optionsArray.map(option => option?.value).join()
}

export function clearQueries(routeQueries): Dictionary<string> {
  const query = cloneDeep(routeQueries)
  delete query.languages
  delete query.hours
  delete query.products
  delete query.gender
  delete query.brands
  delete query.designations
  delete query.services
  return query
}

// import { covidToggleOn } from '@/site.config'
export function createQueryObjectFromSelectedFilterValues(
  filterValues: FilterValues,
  mapOpen: boolean | string
): Dictionary<string> {
  // const covidQueryExists =
  //   filterValues.covidCompliant !== null &&
  //   filterValues.covidCompliant !== undefined &&
  //   covidToggleOn
  return {
    ...(filterValues.networkId && {
      networkId: filterValues.networkId.value as string
    }),
    ...(filterValues.languages && {
      languages: filterValues.languages.value as string
    }),
    ...(filterValues.hours && { hours: filterValues.hours.value as string }),
    ...(filterValues.products && {
        products: filterValues.products.value as string
      }),
    ...(filterValues.gender && { gender: filterValues.gender.value as string }),
    ...(filterValues.brands && {
        brands: filterValues.brands.value as string
      }),
    ...(filterValues.designations && {
      designations: filterValues.designations.value as string
    }),
    ...(filterValues.culturalCompetency && { culturalCompetency: filterValues.culturalCompetency.value as string }),
    ...(filterValues.services && {
        services: filterValues.services.value as string
      }),
    // ...(covidQueryExists && {
    //   covidCompliant: filterValues.covidCompliant ? 'Yes' : 'No'
    // }),
    acceptingNew: filterValues.acceptingNew ? 'Yes' : ('No' as string),
    mapOpen: mapOpen === true || mapOpen === 'true' ? 'true' : 'false'
  }
}

export function filterProvidersByDoctorAddress(
  providers: Provider[],
  filterValue
) : Provider[] {
  const filterValueTrimed = filterValue.toLowerCase().replaceAll(/\s/g,'')
  const filterValueFinal = filterValueTrimed.substring(0, 15)
  const filtered = providers?.filter(provider => {
    const providerAddress1 = provider?.address?.streetAddress1.toLowerCase()
    const providerAddress2 = provider?.address?.streetAddress2.toLowerCase()
    const providerAddress = providerAddress1.concat(providerAddress2)
    const providerAddressTrimed = providerAddress?.replaceAll(/\s/g,'')
    const providerAddressFinal = providerAddressTrimed?.substring(0, filterValueFinal.length)
    if(providerAddressFinal === filterValueFinal) {
      return provider
    }
  })
  return filtered
}

export function filterProvidersByUserSelection(
  providers: Provider[],
  filterValues: FilterValues,
  doctorListFilters: DoctorListFilters
): Provider[] {
  const query = createQueryObjectFromSelectedFilterValues(filterValues, true)
  interface QueryControlObect {
    name: string;
    value: string;
  }
  const queries = [
    { name: 'hours', value: query.hours },
    { name: 'languages', value: query.languages },
    { name: 'products', value: query.products },
    { name: 'gender', value: query.gender },
    { name: 'brands', value: query.brands },
    { name: 'designations', value: query.designations },
    { name: 'culturalCompetency', value: query.culturalCompetency },
    { name: 'services', value: query.services }
    // { name: 'covidCompliant', value: query.covidCompliant }
    // { name: 'acceptingNew', value: query.acceptingNew }
  ] as QueryControlObect[]
  const existingQueries = queries.filter(
    query => query.value !== undefined
  ) as QueryControlObect[]
  if (!existingQueries.length) return providers

  // When filterable queries are present in the URL string:
  // 1. Filter strictly, and reductively on provider values (must meet all filters present).
  const filtered = providers.filter(provider => {
    let failedCheck = false
    for (const query of existingQueries) {
      const name = query.name
      if (name === 'hours' && !returnSingleFilterOnProvider(query, provider)) {
        failedCheck = true
      } 
      // else if (
      //   name === 'covidCompliant' &&
      //   !covidCompliantCheck(query.value, provider)
      // ) {
      //   failedCheck = true
      // } 
      else  if (
        name === 'services' &&
        !returnMultiFilterOnProvider(query, provider)
      ) {
        failedCheck = true
      } else  if (
        name === 'products' &&
        !returnMultiFilterOnProvider(query, provider)
      ) {
        failedCheck = true
      } else  if (name === 'brands' && !returnMultiFilterOnProvider(query, provider)) {
        failedCheck = true
      }
    }
    if (!failedCheck) {
      // 2. Doctor filter check (Doctor Gender, Specialty, and Language spoken) happens here and is used as the final strict filter: when no doctors are present, the location is not returned.
      const existingDoctorFilters: [string, Option][] = Object.entries(doctorListFilters)
      // Only filter provider lists on doctor-sepcific values when they are presently selected.
      if (!existingDoctorFilters.length) {
        return provider
      }
      const availableDoctors: Doctor[] = filterDoctorsListBySelectedValues(provider.doctors, existingDoctorFilters)
      // When available, 'provider.doctorsFiltered' will render in the template layer later instead of 'provider.doctors'.
      provider.doctorsFiltered = availableDoctors
      // Only when there is a doctor list that passes filter criteria present do we return the provider to the list.
      if (availableDoctors.length) {
        return provider
      }
    }
  })

  return filtered
}

function returnSingleFilterOnProvider(query, provider: Provider): boolean {
  const node = provider.locationPreference
  const valToCheck = node ? node[query.value] : (false as any)
  const valueExists =
    valToCheck !== false && valToCheck !== null && valToCheck !== undefined
  return valueExists
}

function returnMultiFilterOnProvider(query, provider: Provider): boolean {
  const valToCheck = provider.locationPreference[query.name] as string | null
  if (!valToCheck) return false
  else {
    const providerVals = valToCheck
      .split(';')
      .map(stringVal => stringVal.toLowerCase()) as string[]
    const queryVals = query.value
      .split(',')
      .map(stringVal => stringVal.toLowerCase()) as string[]
    let valueExists = false
    for (const queryVal of queryVals) {
      if (
        providerVals.find(providerVal => {
          return providerVal.toLowerCase().includes(queryVal.toLowerCase())
        })
      ) {
        valueExists = true
      }
    }
    return valueExists
  }
}

// function covidCompliantCheck(
//   valueCheck: string,
//   provider: Provider
// ): boolean {
//   if (valueCheck === 'No') return true
//   const complianceValue =
//     provider.locationPreference.healthCompliant ||
//     provider.locationPreference.healthCompliant
//   const isCompliant = complianceValue
//     ? complianceValue.toLowerCase() === 'y'
//     : false
//   return isCompliant
// }

export function filterDoctorsListBySelectedValues(
  doctors: Doctor[],
  existingDoctorFilters: [string, Option][]
): Doctor[] {
  if (!doctors.length) {
    return doctors
  } 
  return doctors.filter((doctor: Doctor): boolean => {
    const returnDoctorConditions: boolean[] = []
    for (const [key, value] of existingDoctorFilters) {
      const optionValue = value.value as string
      if (key === 'designations') {
        filterDoctorsListOnDesignation(optionValue, doctor) ?
        returnDoctorConditions.push(true) :
        returnDoctorConditions.push(false)
      } else if (key === 'languages') {
        const valueCheck = doctor.languagesSpoken
        if (optionValue?.toLowerCase() === 'english') {
          returnDoctorConditions.push(true)
        } else if (
          valueCheck &&
          valueCheck?.toLowerCase().includes(optionValue.toLowerCase())
        ) {
          returnDoctorConditions.push(true)
        } else {
          returnDoctorConditions.push(false)
        }
      } else if (key === 'gender') {
        const docGender = doctor.gender ? doctor.gender?.toLowerCase() : ''
        const choiceGender = optionValue?.toLowerCase()
        
        if (docGender === choiceGender) {
          returnDoctorConditions.push(true)
        } else {
          returnDoctorConditions.push(false)
        }
      } else if (key === 'culturalCompetency') {
        const culturalCompetencyValue = doctor.culturalCompetencyInd ? doctor.culturalCompetencyInd?.toLowerCase() : ''
        const choiceCulturalCompetencyValue = optionValue?.toLowerCase()
        
        if (culturalCompetencyValue === choiceCulturalCompetencyValue) {
          returnDoctorConditions.push(true)
        } else {
          returnDoctorConditions.push(false)
        }
      } 

    }
    const failsCheck: boolean =
    returnDoctorConditions.find(filterPassed => filterPassed === false) === false
    return !failsCheck
  })
}

function filterDoctorsListOnDesignation(
  valueCheck: string,
  doctor: Doctor
): boolean {
  const scrubbedValue = valueCheck?.toLowerCase().replace(/\./g, '') as string
  return !!(
    doctor.professionalDesignation &&
    doctor.professionalDesignation.toLowerCase() === scrubbedValue
  )
}

export function formatCommaList(someString): string | undefined {
  if (!someString) return
  if (someString.charAt(someString.length - 1) === ',') {
    return someString.slice(0, -1).replace(/,/g, ', ')
  }
  return someString.replace(/,/g, ', ')
}

export function formatSemiColonList(someString): string | undefined {
  if (!someString) return
  if (someString.charAt(someString.length - 1) === ';') {
    return someString.slice(0, -1).replace(/;/g, ', ')
  }
  return someString.replace(/;/g, ', ')
}

export function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumberString
}

export function createProviderGoogleDestination(provider: Provider): string {
  if (!provider) return ''
  const address = provider.address as ProviderAddress
  const destinationValues = [
    provider.businessName,
    address.streetAddress1,
    address.streetAddress2,
    address.city,
    address.state,
    address.zip
  ]
  let destination = ''
  destinationValues.map((value, i) => {
    if (value) {
      if (i === destinationValues.length - 1) {
        destination += value
      } else if (i === destinationValues.length - 2) {
        destination += `${value} `
      } else {
        destination += `${value}, `
      }
    }
  })
  return destination
}

export function weaveProviderLists(
  array1: Provider[],
  array2: Provider[]
): Provider[] {
  const result: Provider[] = []
  const shortestArray = Math.min(array1.length, array2.length)
  const distanceB = array2[0] ? array2[0].distance : 0
  const distanceA = array1[0] ? array1[0].distance : 0
  const secondIsInitallyClosest = (distanceB < distanceA) as boolean
  for (let i = 0; i < shortestArray; i++) {
    if (secondIsInitallyClosest) {
      result.push(array2[i], array1[i])
    } else {
      result.push(array1[i], array2[i])
    }
  }
  const restA: Provider[] = array1.slice(shortestArray) || []
  const restB: Provider[] = array2.slice(shortestArray) || []
  return result.concat(restA).concat(restB)
}
