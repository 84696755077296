<template>
  <factory-error-section
    v-if="errorObj.serviceName && isDev"
    class="error-section _p0"
  >
    <template v-slot:error-label>
      <template v-if="errorObj.serviceName">{{errorObj.serviceName}}  error:</template>
    </template>
    <template v-slot:error-message>{{errorObj.defaultMessage}}</template>
  </factory-error-section>
  <section
    v-else-if="(errorObj.messageCode || errorObj.defaultMessage)&& isDev"
    class="error-section _p0">
    <div
      class="error-label">
      <div container>
        <div :grid="`row wrap`">
          <div column="xs-12 s-12">
            <response-message-naked :error-obj="errorObj"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { ServiceFactoryError, ServiceResponseMessage } from '@/types'
  import FactoryErrorSection from '@global/ErrorSection.vue'
  
  import { isDev } from '@/../env.config'

  export default defineComponent({
    name: 'service-error',
    components: {
      FactoryErrorSection
    },
    props: {
      errorObj: {
        type: Object as () => ServiceResponseMessage | ServiceFactoryError,
        required: false
      }
    },
    data() {
      return {
        isDev: isDev
      }
    },
    computed: {
      errorList(): object[] {
        return this.$store.state.responseMessages
      }
    }
  })
</script>
