import { createRouter, createWebHistory } from 'vue-router'
import Router, { RouterOptions } from 'vue-router'
import { defaultLocale, locales } from '@/ts/plugins/i18n'
import RouterView from '@/views/RouterView.vue'
import Search from '@/views/Search.vue'
import Results from '@/views/Results.vue'
import Directions from '@/views/Directions.vue'
import Schedule from '@/views/Schedule.vue'
import ExamInformation from '@sections/Schedule/01-Exam-Information.vue'
import ScheduleDate from '@sections/Schedule/02-Schedule-Date.vue'
import PersonalInformation from '@sections/Schedule/03-Personal-Information.vue'
import AppointmentConfirmation from '@sections/Schedule/04-Appointment-Confirmation.vue'
import PageNotFound from '@/views/404.vue'
import store from '@/store/index'
import { updateAdobeDigitalData } from '@/ts/adobe-analytics/index.ts'

export const scheduleViewChildRoutes = [
  {
    path: '',
    name: 'Exam Information',
    component: ExamInformation,
    meta: {
      scheduleChild: true,
      preventWindowScollTop: true,
      scheduleIndex:0
    }
  },
  {
    path: 'schedule-date',
    name: 'Schedule Date',
    component: ScheduleDate,
    meta: {
      scheduleChild: true,
      preventWindowScollTop: true,
      scheduleIndex:1
    }
  },
  {
    path: 'personal-information',
    name: 'Personal Information',
    component: PersonalInformation,
    meta: {
      scheduleChild: true,
      preventWindowScollTop: true,
      scheduleIndex:2
    }
  },
  {
    path: 'appointment-confirmation',
    name: 'Appointment Confirmation',
    component: AppointmentConfirmation,
    meta: {
      scheduleChild: true,
      scheduleIndex:3
    }
  },
]
const routes = [
  {
    path: '/:clientID/',
    redirect: to => {
      const clientID = to.params.clientID.toLowerCase()
      return `/${clientID}/${defaultLocale}`
    },
  },
  {
    path: '/:clientID/:lang',
    component: RouterView,
    props: true,
    children: [
      {
        path: '',
        name: 'Search',
        component: Search
      },
      {
        path: 'results',
        name: 'Results',
        component: Results
      },
      {
        path: 'directions',
        name: 'Directions',
        component: Directions
      },
      {
        path: 'schedule',
        component: Schedule,
        props: true,
        children: scheduleViewChildRoutes
      },
      { path: '*', component: PageNotFound }
    ]
  }
]

routes.push({
  path: '/',
  redirect: `/member/${defaultLocale}`
})

export const routerMode: 'hash' | 'history' | 'abstract' | undefined =
import.meta.env.VITE_MOCK_ENV === 'true' ? 'hash' : 'history'
const routerOptions: RouterOptions = {
  history: createWebHistory(),
  mode: routerMode,
  base: `${import.meta.env.BASE_URL}`,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.preventWindowScollTop) return
    try {
      window.parent.parent.scrollTo(0, 0)
    } catch {
      parent.postMessage('snap_to_top', '*')
    }
    return { x: 0, y: 0 }
  }
}

const appRouter = createRouter(routerOptions)
import { trackPageView } from '@/ts/adobe-analytics/index.ts'

appRouter.beforeEach((to, from, next) => {
  let metaTitle = document.title
  metaTitle = 'Vision Provider Locator'
  document.getElementsByTagName('title')[0].innerHTML = metaTitle
  if (!store.state.initialAppLoad) {
    updateAdobeDigitalData({
      sysEnv: to.query.device === 'mobileApp' ? 'mobileApp' : 'browser',
      clientID: to.params.clientID || '',
      groupId: (to.query.groupId as string) || ''
    })
  }
  trackPageView(to.name)
  
  function routeClientIDLogic(): void {
    // some services in the memberweb application are returning imperfect url's for the provider locator iframe, so the clientID needs to be forced to lowercase on occasion
    const clientID = to.params.clientID
    if (to.params.clientID && (/[A-Z]/.test(clientID))) {
      next(to.fullPath.replace(clientID, clientID.toLowerCase()))
    }
  }

  function routeLanaguageLogic(): void {
    if (!locales.includes(to.params.lang)) {
      next(to.fullPath.replace(to.params.lang, defaultLocale)) // default locale if provided one does not exist
    } else if (store.getters['locale/selectedLanguage'] !== to.params.lang) {
      store.commit('locale/changeSelectedLanguage', to.params.lang) // update state to provided locale when a param is present that does not match default locale (but can be navigated to)
      next()
    } else {
      next()
    }
  }

  routeClientIDLogic()
  routeLanaguageLogic()

})

appRouter.afterEach(() => {
  parent.postMessage('routeChanged', '*')
})


export default appRouter
