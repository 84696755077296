<template>
  <div class="box-link lasik-box-link">
    <div
      v-if="link.icon"
      class="image-wrap">
      <img
        :src="link.icon"
        :alt="`${link.title} brand icon`">
    </div>
    <div class="flex-wrap">
      <div
        v-if="hasSlot"
        class="tier-label-wrap">
        <slot></slot>
      </div>
      <a
        v-if="link.href && link.title"
        :href="link.href"
        :style="globalTheme.colorTertiaryDark"
        target="_blank"
        :aria-label="`Open ${link.title} in a new tab`"
      >
        <div class="link-wrap">
          <div>
            {{link.title}}
            <font-awesome-icon
              :icon="['fas', 'caret-right']"
              aria-label="previous tab"
              class="caret _mlxxs"/>
          </div>
        </div>
      </a>
      <a
        v-if="link.phoneNumberLabel && link.phoneNumber"
        :style="globalTheme.colorTertiaryDark"
        :href="`tel:${link.phoneNumber}`"
      >{{ link.phoneNumberLabel }}</a
      >
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { LasikIconLink } from '@/types'
  export default defineComponent({
    props: {
      link: {
        required: true,
        type: Object as ()=> LasikIconLink
      }
    },
    computed: {
      hasSlot(): boolean {
        return !!this.$slots.default
      }
    }
  })
</script>
