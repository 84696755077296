<template>
  <div :class="$route.name ? $route.name.toLowerCase() : ''">
    <div style="position:absolute; transform:translateX(-100%);">
      <app-inline-svg
        id="map_marker_tiered"
        :src="mapIcons.mapIconTiered.url"
        :style="globalTheme.colorTieredMapMarker"
        @svg-created="mapMakersLoaded += 1"/>
      <app-inline-svg
        id="map_marker_basic"
        :src="mapIcons.mapIconBasic.url"
        :style="globalTheme.color"
        @svg-created="mapMakersLoaded += 1"/>
    </div>
    <router-view :framed="framed"/>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { mapState } from 'vuex'
  export default defineComponent({
    props: {
      framed: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        mapMakersLoaded: 0,
      }
    },
    computed: {
      ...mapState({
        mapIcons: (state: any): string => state.globals.microsite.icons.mapMarkers
      })
    }
  })
</script>
