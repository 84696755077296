<template>
  <div container>
    <div grid="row wrap">
      <div
        column="xs-12 s-12">
        <div class="flex-wrap search-result-actions">
          <button
            :disabled="!filterOptions || !networks"
            class="sm btn-filter"
            :style="globalTheme.buttonSecondary"
            @click="toggleFilter">
            <font-awesome-icon
              v-if="!filterOptions || !networks"
              icon="spinner"
              pulse
              aria-label="Loading..."
              class="_mrxxs"
            />
            <font-awesome-icon
              v-else
              class="_mrxxs"
              :icon="['fas', 'sliders-h']"/>
            {{page.filterButtonLabel}}
            <font-awesome-icon
              class="_mlxxs"
              :icon="filtersOpen ? ['fas', 'caret-up'] : ['fas', 'caret-down']"/>
          </button>
          <print-button
            class="_mrxxs"
            :print-icon="icons.printSvg.url">
            {{page.printButtonLabel}}
          </print-button>
          <button
            class="sm white bordered with-svg btn-email"
            :style="globalTheme.buttonWhiteBorderedSecondary"
            @click="toggleEmailForm">
            <app-inline-svg
              class="_mrxxs btn-svg "
              :src="icons.emailSvg.url"
              :style="globalTheme.colorSecondary"/>
            {{page.emailButtonLabel}}
            <font-awesome-icon
              class="_mlxxs fa-w-10"
              :icon="emailOpen ? ['fas', 'caret-up'] : ['fas', 'caret-down']"/>
          </button>
          <!-- <div
            v-if="legacyView && covidToggleOn"
            class="frm-toggle tiered-toggle">
            <label :for="specialToggleID">{{page.filterLabels.covidCompliantToggle}}</label>
            <VueToggles
              id="toggle_button"
              v-model="covidToggleValue"
              :labels="false"
              :color="globalTheme.covidSafeColor"
              @keyup.enter.native="covidToggleValue = covidToggleValue"/>
          </div> -->
          <div
            v-if="!legacyView && !isMobile"
            class="frm-toggle tiered-toggle">
            <label :id="specialToggleID">{{page.filterLabels.tieredProvidersToggle}}</label>
            <VueToggles
              id="toggle_button"
              v-model="tieredView"
              :labels="false"
              :aria-labelledby="specialToggleID"
              uncheckedBg="lightgrey"
              checkedBg="rgb(228, 5, 113)"
              :width="50"
              :height="22"
              @keyup.enter="tieredView = !tieredView"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { VueToggles } from "vue-toggles";
   import {defineComponent} from 'vue'
  import { SearchResultsPageLabels, FormattedFilterList, Option, FilterValues } from '@/types'
  import PrintButton from '@atoms/PrintButton.vue'
  import { mapState } from 'vuex'
  import { eventBus } from '@/main'
  export default defineComponent({
   
    components: {
      PrintButton,
      VueToggles
    },
    props: {
      modelValue: {
        type: Boolean,
        required:true
      },
      page: {
        type: Object as ()=> SearchResultsPageLabels,
        required: false
      },
      networks: {
        type: Array as ()=> Option[]
      },
      filterOptions: {
        type: Object as () => FormattedFilterList
      },
      filtersOpen: {
        type: Boolean,
        required: true
      },
      legacyView: {
        type: Boolean,
        required: true
      },
      // covidToggle: {
      //   type: Boolean,
      //   required: true
      // },
      emailOpen: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        // covidToggleValue: this.covidToggle as boolean,
        covidToggleOn: this.$store.state.context.covidToggleOn,
        specialToggleID: 'special_toggle',
        windowWidth: window.innerWidth as number
      }
    },
    computed: {
      ...mapState({
        icons: (state: any) => {
          return { 
            ...state.globals.microsite.icons.general
          }
        },
        buttonLabels: (state: any) => {
          return { 
            ...state.globals.microsite.doctorLabels
          }
        } 
      }),
      tieredView: {
        get(): boolean {
          return this.modelValue
        },
        set(newVal: boolean) {
          this.$emit('update:modelValue', newVal)
        }
      },
      isMobile(): boolean {
        return this.windowWidth <= 768
      }
    },
    // watch: {
    //   covidToggleValue(newVal: boolean) {
    //     eventBus.$emit('covidToggleFromActions', newVal)
    //   },
    // },
    methods: {
      toggleEmailForm(): void {
        this.$emit('toggleEmailForm')
      },
      toggleFilter(e): void {
        this.$emit('toggleFilter')
      },
      setWindowWidth(width: number): void {
        this.windowWidth = width
      }
    },
    mounted() {
      const specialToggle = this.$el.querySelector('#toggle_button')
      if (!specialToggle) return
      const specialToggleCheckbox = specialToggle.querySelector('input')
      if (!specialToggleCheckbox) return
      specialToggleCheckbox.setAttribute('id', this.specialToggleID)
      eventBus.$on('windowSizeChange', this.setWindowWidth)
    },
    beforeDestroy() {
      eventBus.$off('windowSizeChange', this.setWindowWidth)
    }
  })
</script>
