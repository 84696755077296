import { isIE } from '@/ts/helpers/IE.ts'
export function generateIconUrl(svgEl: SVGSVGElement): string | undefined {
  //if (isIE()) return
  try {
    const iconString = new XMLSerializer().serializeToString(svgEl)
    return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
  } catch {
    return
  }
}
