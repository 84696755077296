import { FooterNavigation, Link } from '@/types'

function removeSlash(url: string) {
  return url.replace(/^\/|\/$/g, '')
}
export function footerNavigationFactory(microsite): FooterNavigation {
  const lockup = microsite.subFooterLockup
  const socialLinks = microsite.socialLinks
  const footerNavItems = microsite.footerLinks
  return {
    socialLinks: socialLinks
      ? socialLinks.map(socialLink => {
          const icon = socialLink.icon
          const link = socialLink.link
          return {
            iconPath: icon ? icon.url : undefined,
            url: link.href,
            alt: link.title
          }
        })
      : undefined,
    navItems: footerNavItems
      ? footerNavItems.map(navItem => {
          return navItem as Link
        })
      : undefined,
    subFooterLockup: lockup
      ? {
          url: lockup.url || undefined,
          description: lockup.description || undefined
        }
      : undefined
  }
}
