<template>
  <div>
    <simple-modal
      v-if="show"
      :framed="framed"
      @closeModal="closeModal">
      <div 
        style="color: black"
        class="special-offer-popup"
        v-html="offerPopupIndependent"
      >
      </div>
    </simple-modal>
    <a 
      tabindex="0"
      @click="showModal">
      {{ offerButtonLabel }}
    </a>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      offerButtonLabel: {
        type: String
      },
      offerPopupIndependent: {
        type: String
      }
    },
    data() {
      return {
        show: false
      }
    },
    computed: {
      framed(): boolean {
        return this.$store.state.context.framed
      }
    },
    methods: {
      showModal() {
        if (this.framed) {
          try {
            window.scrollTo(0, 0)
            window.parent.parent.scrollTo(0, 0)
          } catch {
            parent.postMessage('snap_to_top', '*')
          }
        }
        this.show = true
      },
      closeModal() {
        this.show = false
      }
    }
  })
  </script>
  <style>
    .special-offer-popup {
      text-transform: none;
      font-weight: normal;
      font-family: "Nexa Book", Helvetica, sans-serif;
      line-height: 1;
    }
  </style>
  