 import {defineComponent} from 'vue'
export default defineComponent({
    computed: {
        isConnecticut(): boolean {
          const state: string|null = (this as any).provider.address.state
          if (!state) return false
          return state.toLowerCase() === 'ct'
        }
    }
})
