import { service } from '@/ts/service.methods'
import { NewServiceResponseMessage, InternalErrorResponse, LockPayload, LockSuccess } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { isMock } from '@/../env.config'


function lockApptFactory(res: LockSuccess):LockSuccess  {
    return res
}

export async function lockAppointment(
  appt: LockPayload
): Promise<LockSuccess | InternalErrorResponse> {
    if (isMock) { return require('@/mock-data/lock-success.mock.ts') as LockSuccess }
    return new Promise((resolve,reject) => {
        service.post('appointment/lock', appt)
        .then(res => {
            if (res.data.messageCode === 'LCK001') {
                handleServiceResponse(resolve,reject,res, lockApptFactory,'lockAppointmentFactory error')
            } else {
                generateServiceError(reject, res, 'lock-appointment.ts/lockAppointment')
            }
        })
        .catch(err => generateServiceError(reject, err, 'lock-appointment.ts/lockAppointment'))
    })
}

export async function lockSbAppointment(
    appt: LockPayload
  ): Promise<LockSuccess | InternalErrorResponse> {
      if (isMock) { return require('@/mock-data/lock-success.mock.ts') as LockSuccess }
      return new Promise((resolve,reject) => {
          service.post('sbappointment/lock', appt)
          .then(res => {
              if (res.data.messageCode === 'LCK001') {
                  handleServiceResponse(resolve, reject, res, lockApptFactory, 'lockAppointmentFactory error')
              } else {
                  generateServiceError(reject, res, 'lock-appointment.ts/lockSbAppointment')
              }
          })
          .catch(err => generateServiceError(reject, err, 'lock-appointment.ts/lockSbAppointment'))
      })
  }

interface UnlockPayload {
    lockId: string
}

export async function unlockAppointment(
    unlockAppt: UnlockPayload
  ): Promise<NewServiceResponseMessage> {
      if (isMock) { return require('@/mock-data/unlock-success.mock.ts') as LockSuccess }
      return new Promise((resolve,reject) => {
          service.post('appointment/unlock', unlockAppt)
          .then(res => {
              if (res.data.messageCode === 'LCK004') {
                  handleServiceResponse(resolve,reject,res, lockApptFactory,'unlockAppointmentFactory error')
              } else {
                  generateServiceError(reject, res, 'lock-appointment.ts/unlockAppointment')
              }
          })
          .catch(err => generateServiceError(reject, err, 'lock-appointment.ts/unlockAppointment'))
      })
  }

  export async function unlockSbAppointment(
    unlockAppt: UnlockPayload
  ): Promise<NewServiceResponseMessage> {
      if (isMock) { return require('@/mock-data/unlock-success.mock.ts') as LockSuccess }
      return new Promise((resolve,reject) => {
          service.post('sbappointment/unlock', unlockAppt)
          .then(res => {
              if (res.data.messageCode === 'LCK004') {
                  handleServiceResponse(resolve,reject,res, lockApptFactory,'unlockAppointmentFactory error')
              } else {
                  generateServiceError(reject, res, 'lock-appointment.ts/unlockSbAppointment')
              }
          })
          .catch(err => generateServiceError(reject, err, 'lock-appointment.ts/unlockSbAppointment'))
      })
  }
