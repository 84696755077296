<template>
  <section
    aria-label="featured content"
    class="featured-content"
  >
    <div
      class="page-sub-header"
      container
    >
      <div grid="row wrap">
        <div column="xs-12 s-12 l-12">
          <template
            v-if="section.title">
            <h1
              v-if="section.titleAsHtml"
              :class="{'no-content': !section.content.length || section.stretchTitle}"
              :style="!!section.textColor ? { color: section.textColor } : globalTheme.color"
              v-html="section.title"></h1>
            <h1
              v-else
              :class="{'no-content': !section.content.length || section.stretchTitle}"
              :style="!!section.textColor ? { color: section.textColor } : globalTheme.color"
            >{{ section.title }}</h1>
          </template>
          <div
            v-if="section.content && section.content !== '<p><br></p>'"
            v-html="section.content"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { SimpleTitle } from '@/types'

  export default defineComponent({
    name: 'simpleTitle' as string,
    props: {
      section: {
        type: Object as () => SimpleTitle,
        required: true
      }
    }
  })
</script>
