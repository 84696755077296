 import {defineComponent} from 'vue'
import { setExternalLinkAriaLabels } from '@/ts/dom-helper.methods.ts'
import { getBaseUrl } from '@/site.config.ts'
import { routerMode } from '@/router'
import { isMock } from '@/../env.config'
export default defineComponent({
  props: {
    html: {
      type: String,
      required: true
    },
    modifier: {
      type: String,
      required: false
    }
  },
  methods: {
    findAndReplceClientID(htmlString: string): string {
      return htmlString
        .replace(/{clientID}/g, this.$route.params.clientID)
        .replace(/{locale}/g, this.$route.params.lang)
    },
    colorRichTextLinks(): void {
      const links = this.$el.querySelectorAll('a:not(.button)')
      if (links.length) {
        [...links].map(link => {
          (link as HTMLElement).style.color = (this as any).darken(
            this.$store.state.globals.microsite.primaryColor
          )
        })
      }
    },
    amendHeadings() {
      for (let i = 1; i < 5; i++) {
        const headerClass: string = `h${i}`
        this.$el.querySelectorAll(headerClass).forEach(header => {
          header.classList.add(headerClass)
        })
      }
    },
    checkAndSetInternalLinks(): void {
      let hash
      if (routerMode === 'hash') {
        hash = isMock ? '/eyemed-provider-locator-dist/#/' : '/#/'
      } else {
        hash = ''
      }
      this.$el.querySelectorAll('a').forEach(link => {
        // modify relative, internal links to work with all client ID combinations
        if (link.href.includes('$SITE_URL')) {
          link.href = link.href.split('%20').join('')
          const splitUrl = link.href.split('$SITE_URL').pop()
          link.href = `${hash}${getBaseUrl(
            this.$route.params
          )}/${splitUrl}`.replace(/\/\//g, '/')
        } 
      })
      for (let i = 1; i < 5; i++) {
        const headerClass: string = `h${i}`
        this.$el.querySelectorAll(headerClass).forEach(header => {
          header.classList.add(headerClass)
        })
      }
    }
  },
  mounted() {
    this.colorRichTextLinks()
    this.amendHeadings()
    this.checkAndSetInternalLinks()
    setExternalLinkAriaLabels(this.$el)
  }
})
