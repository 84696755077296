import { objectKeysToCamelCase } from '@oodletech/oodle-js/js'
import { defaultLocale } from '@/ts/plugins/i18n'
import { getMicrositeGlobals, getMicrosites, getresponseMessages, getProviderLogoIcons } from '@/ts/service-contentstack.methods'
import { footerNavigationFactory } from '@/factories/contentstack/footer-navigation.factory'
import { FooterNavigation, Microsite, GlobalDispatchPayload } from '@/types'



export async function getGlobals(payload: GlobalDispatchPayload): Promise<Microsite> {
  let microsite = await getMicrositeGlobals(payload)
  microsite = await objectKeysToCamelCase(microsite)
  const footer = await footerNavigationFactory(microsite) as FooterNavigation
  microsite.footer = footer
  return  microsite
}

export async function getMicrositeList(): Promise<any> {
  const sites = await getMicrosites()
  return  objectKeysToCamelCase(sites) || [{ clientId: 'member' }]
}

export async function getErrorMessageLabels(payload): Promise<any> {
  const errorLabels = await getresponseMessages(payload.lang, payload.test)
  return  objectKeysToCamelCase(errorLabels) 
}

export async function getBrandLogos(lang: string, test: boolean): Promise<any> {
  const logos = await getProviderLogoIcons(lang, test)
  return  objectKeysToCamelCase(logos) 
}
