<template>
  <app-collapsible-section class="date-picker-drawer">
    <template v-slot:header-content>
      <h5
        class="picker-title"
        :style="globalTheme.colorDark">
        <app-inline-svg
          class="_mrxs"
          :src="labels.appointmentSvgIcon.url"
          :style="globalTheme.colorPrimary"/>
        {{labels.appointmentAvailability}}
      </h5>
    </template>
    <div
      grid="row wrap">
      <div column="xs-12 s-12">
        <div
          class="flex-wrapper day-picker">
          <div
            v-if="aFewDaysAgo"
            tabindex="0"
            class="caret-wrap"
            aria-label="Move back a few days"
            @keydown.enter.stop="selectDate(aFewDaysAgo, false)"
            @click.stop="selectDate(aFewDaysAgo, false)">
            <font-awesome-icon
              :icon="['fas', 'caret-left']"
              class="caret"/>
          </div>
          <template
            v-for="(day, i) in nextFewDays" :key="i">
            <div
              v-if="isInThePast(day)"

              class="day-appointments disabled">
              <div>
                <span
                  class="day-name">{{dateTime.format(day.asDate, 'ddd')}}</span>
                <span
                  class="day-digit"> {{dateTime.format(day.asDate, 'DD')}}</span>
              </div>
              <div
                class="day-available"
                :style="globalTheme.colorBlack">
                0 {{labels.available}}
              </div>
            </div>
            <div
              v-else-if="isSelectedDay(day)"

              class="day-appointments disabled"
              :style="[globalTheme.backgroundColor, globalTheme.colorWhite]">
              <div>
                <span class="day-name">{{dateTime.format(day.asDate, 'ddd')}}</span>
                <span class="day-digit"> {{dateTime.format(day.asDate, 'DD')}}</span>
              </div>
              <div
                class="day-available"
                :style="globalTheme.colorWhite">
                <template v-if="fetchingExams">
                  <app-loader-icon color="white" width="20px"/>
                </template>
                <template v-else-if="currentAvailableAppointments">
                  {{day.totalAvailable}} {{labels.available}}
                </template>
              </div>
            </div>
            <div
              v-else
              :key="i"
              tabindex="0"
              class="day-appointments"
              :class="{disabled: fetchingExams}"
              @keydown.enter.stop="selectDate(day.asDate, false)"
              @click.stop="selectDate(day.asDate)">
              <div>
                <span class="day-name">{{dateTime.format(day.asDate, 'ddd')}}</span>
                <span class="day-digit"> {{dateTime.format(day.asDate, 'DD')}}</span>
              </div>
              <div class="day-available">
                <template v-if="fetchingExams">
                  <app-loader-icon color="#EB5100" width="20px"/>
                </template>
                <template v-else-if="currentAvailableAppointments">
                  {{day.totalAvailable}} avail.
                </template>
              </div>
            </div>
          </template>
          <div
            tabindex="0"
            class="caret-wrap"
            role="button"
            aria-label="Move forward a few days from now"
            @keydown.enter.stop="selectDate(aFewDaysFromNow, false)"
            @click.stop="selectDate(aFewDaysFromNow, false)">
            <font-awesome-icon
              :icon="['fas', 'caret-right']"
              class="caret"/>
          </div>
        </div>
      </div>
    </div>
  </app-collapsible-section>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { ScheduleADateLabels, AvailableAppointments, AvailableExamsOnDay } from '@/types'
  import { addDays, datesAreOnSameDay } from '@/ts/helpers/schedule.helpers'
  import { eventBus } from '@/main'
  import date from 'date-and-time'
  import 'date-and-time/locale/es';
  export default defineComponent({
    props: {
      modelValue: {
        required: true,
        type: Date
      },
      minDate: {
        required: true,
        type: Date
      },
      labels: {
        required: true,
        type: Object as () => ScheduleADateLabels
      },
      currentAvailableAppointments: {
        required: false,
        type: Object as () => AvailableAppointments
      },
      fetchingExams: {
        type: Boolean,
        default: false
      },
      calendarKey: {
        type: Number,
        default:0
      },
      isMobile: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      selectedDate: {
        get(): Date {
          return this.modelValue
        },
        set(newVal: Date) {
          (this as any).$emit('update:modelValue', newVal)
        }
      },
      dateTime(): any {
        date.locale(this.$route.params.lang)
        return date
      },
      nextFewDays(): AvailableExamsOnDay[] {
        const numDays = this.isMobile ? 3 : 5
        const startDay = this.isMobile ? -1 : -2
        const nextFewDays = []
        for (let i = 0; i < numDays; i++) {
          //@ts-ignore
          nextFewDays.push({ asDate: addDays(this.selectedDate, i + startDay) })
        }
        if (!this.currentAvailableAppointments || this.fetchingExams) return nextFewDays
        const availableExams = this.currentAvailableAppointments.availableExams as AvailableExamsOnDay[]
        const nextFewRealDays = []
        for (let i = 0; i < numDays; i++) {
          //@ts-ignore
          nextFewRealDays.push(availableExams[i])
        }
        return nextFewRealDays
      },
      range(): number {
        return this.isMobile ? 2 : 3
      },
      aFewDaysAgo(): Date|null {
        const numDays = this.range
        const aFewDaysAgo = addDays(this.selectedDate, (numDays * -1)) as Date
        //@ts-ignore
        const minDate = this.minDate as Date
        const sameDay = datesAreOnSameDay(this.selectedDate, minDate)
        if (sameDay){
          return null
        } else if ( new Date(aFewDaysAgo) <  new Date(minDate)) {
          return minDate
        }
        else {
          return aFewDaysAgo
        }
      },
      aFewDaysFromNow(): Date {
        const numDays = this.range
        return addDays(this.selectedDate, numDays) as Date
      }
    },
    methods: {
      isSelectedDay(day: AvailableExamsOnDay): boolean {
        //@ts-ignore
        return datesAreOnSameDay(day.asDate, this.modelValue)
      },
      isInThePast(day: AvailableExamsOnDay): boolean {
        return (new Date(day.asDate as Date).setHours(0,0,0,0) <  new Date(this.minDate).setHours(0,0,0,0))
      },
      selectDate(date: Date, removeFocuse = true): void {
        if (this.fetchingExams) return
        this.selectedDate = date
        if (removeFocuse) {
          //@ts-ignore
          if (document !== null) {document.activeElement.blur()}
        }
      },
    }
  })
</script>
