<template>
  <section
    class="success-section _p0">
    <div
      class="success-label" 
      :style="globalTheme.backgroundColorSuccess">
      <div container>
        <div :grid="`row wrap`">
          <div column="xs-12 s-12">
            <response-message-naked :error-obj="errorObj"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { ServiceFactoryError, ServiceResponseMessage } from '@/types'
  

  export default defineComponent({
    name: 'service-success-message',
    props: {
      errorObj: {
        type: Object as () => ServiceResponseMessage,
        required: false
      }
    }
  })
</script>
