import { ServiceResponseMessage } from '@/types'

interface FormattedResponseObject {defaultMessage: string, messageCode: string}
export function formatServiceError(err): FormattedResponseObject|undefined  {
  let responseObject
  if (err && err.response && err.response.data) {
    responseObject = err.response.data
    if (
      (responseObject && responseObject.defaultMessage) ||
      (responseObject && responseObject.messageCode) ||
      (responseObject && responseObject.message)
    ) {
      if (responseObject.message) {
        responseObject.defaultMessage = responseObject.message
      }
      return (responseObject as FormattedResponseObject)
    } else {
      return undefined
    }
  } else if ((err && err.data && err.data.defaultMessage) || (err && err.data && err.data.messageCode)) {
    return err.data as FormattedResponseObject
  } else if ((err && err.defaultMessage) || (err && err.messageCode)) {
    return err  as FormattedResponseObject
  }
}

export function generateServiceError(
  reject,
  err,
  serviceMethodName: string
): void {
  if (formatServiceError(err)) {
    reject(formatServiceError(err))
  } else {
    // unaccounted for general errors
    const errorObj = JSON.parse(JSON.stringify(err))
    if (errorObj.config) {
      reject({
        serviceName: serviceMethodName,
        defaultMessage: `Failed to ${errorObj.config!.method}: ${
          errorObj.message
        }`,
        devError: true
      } as ServiceResponseMessage)
    } else {
      reject({
        serviceName: serviceMethodName,
        defaultMessage: `${errorObj.message ||
          errorObj.defaultMessage ||
          'undefined error'}`,
        devError: true
      } as ServiceResponseMessage)
    }
  }
}

export function handleServiceResponse(
  resolve,
  reject,
  res,
  factoryFunction: Function,
  factoryFunctionName: string
) {
  try {
    return resolve(factoryFunction(res.data))
  } catch (error) {
    reject({
      serviceName: factoryFunctionName,
      serviceFactoryError: error.message
    })
  }
}
