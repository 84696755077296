<template>
  <div
    class="search-result-card"
    :class="{tiered: provider.tieredLocation, legacy: legacyView}">
    <div
      :id="provider.locationId"
      class="scroll-anchor">
    </div>
    <div
      class="themed-strip"
      :style="provider.tieredLocation ? globalTheme.borderTieredMapMarker :
        (isValidTierName ? { borderColor: wholesaleProviderColor + ' !important' } :
          (isDiscountOptOut ? { borderColor: noDiscountProviderColor + ' !important' } : globalTheme.borderColor))" />
    <div grid="row wrap stretch">
      <div
        :column="legacyView ? 'xs-12 s-12' : 'xs-12 s-12 m-6'"
        class="title-column">
        <button
          class="simple card-title-map-link"
          :aria-label="`select ${provider.businessName} in map`"
          @click="setCurrentProvider"
          @keydown.enter="setCurrentProvider">
          <div
            :style="globalTheme.colorBlack"
            class="h3 name">
            <app-inline-svg
              v-if="!brandLogo() && !provider.tieredLocation"
              :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.color"
              :src="icon"
              aria-label="map marker icon" />
            <template v-if="provider.tieredLocation">
              <div class="tiered-provider-logo flex-wrap --column">
                <img
                  v-if="brandLogo()"
                  class="brand-logo"
                  :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : ''"
                  :src="brandLogo()"
                  :aria-label="provider.businessName">
                <span
                  v-else
                  class="tiered-brand-name"
                  :style="globalTheme.colorBlack">
                  {{provider.businessName}}
                </span>
              </div>
            </template>
            <template v-else>
              <img
                v-if="brandLogo()"
                class="brand-logo"
                :src="brandLogo()"
                :aria-label="provider.businessName">
              <template
                v-else>
                {{provider.businessName}}
              </template>
            </template>
            <font-awesome-icon
              :style="provider.tieredLocation ? globalTheme.colorTieredMapMarker :  ((isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.color)"
              :icon="['fas', 'caret-up']"/>
          </div>
        </button>
        <div
          v-if="provider.tieredLocation || hasSpecialOffer || hasSpecialOfferIndependent"
          grid="row wrap baseline">
          <div
            v-if="provider.tieredLocation"
            column="xs-12 s-6">
            <tiered-provider-label :label="labels.tieredProviderLabel"/>
          </div>
          <div
            v-if="hasSpecialOffer"
            column="xs-12 s-6">
            <special-offer-label :label="labels.specialOfferLabel"/>
          </div>
          <div
            v-if="hasSpecialOfferIndependent"
            column="xs-12 s-6">
            <special-offer-label
              :style="(isDiscountOptOut) ? { color: '#808080 !important' } : ''"
              :label="labels.specialOfferLabel"/>
          </div>
          <!-- <div
            v-if="covidSafe"
            column="xs-12 s-6">
            <span
              class="covid-safe-provider-label"
              :style="globalTheme.colorCovidSafe">
              <app-inline-svg
                :src="icons.covidSafeSvg.url"
                aria-label="map marker icon" />
              {{labels.covidCompliantToggle}}
            </span></div> -->
        </div>
      </div>
      <result-card-actions
        v-if="!legacyView"
        column="xs-12 s-12 m-6"
        class="action-column tablet-up"
        :labels="labels"
        :drawer-open="drawerOpen"
        :provider="provider"
        :has-special-offer="hasSpecialOffer"
        :has-special-offer-independent="hasSpecialOfferIndependent"
        :offers="offers"
        :offer-button-label="offerButtonLabel"
        :offer-popup-independent="offerPopupIndependent"
        :show-plus-provider-side-bar="showPlusProviderSideBar"
        @toggleCollapse="toggleCollapse"
      />
      <provider-address
        :icon="icons.locationSvg.url"
        :provider="provider"
        show-provider-distance
        :label="labels.getDirections"
        :legacy-view="legacyView"
        :column="legacyView ? 'xs-12 s-12 m-6' : 'xs-12 s-12 m-6 l-3'"
        class="flex-wrap directions directions-column">
      </provider-address>
      <provider-contact
        :provider="provider"
        :labels="labels"
        :legacy-view="legacyView"
        :column="legacyView ? 'xs-12 s-12 m-6' : 'xs-12 s-12 m-6 l-3'"
        class="flex-wrap contact-column">
      </provider-contact>
      <div
        v-if="legacyView"
        column="xs-12 s-12">
        <hr
          v-if="provider.locationEmailAddress"/>
        <p
          v-if="provider.locationEmailAddress"
          class="label email">
          <app-inline-svg
            :src="icons.emailSvg.url"
            :style="globalTheme.colorSecondary"/>
          <span class="hyphenate">{{provider.locationEmailAddress}}</span>
        </p>
      </div>
      <provider-hours
        class="hours-column"
        :icon="icons.hoursSvg.url"
        :provider="provider"
        :label="labels.openToday"
        :hours-unavailable-label="labels.hoursUnavailableMessage"
        :day-labels="dayLabels"
        :show-plus-provider-side-bar="showPlusProviderSideBar"
        :column="legacyView ? 'xs-12 s-12' : 'xs-12 s-12 m-12 l-6'">
      </provider-hours>
    </div>
    <app-collapsible-section
      hide-header
      :provided-u-i-d="`uid_${provider.locationId}`"
      :collapsed="!drawerOpen">
      <div grid="row wrap">
        <div
          v-if="provider.doctors.length || isConnecticut"
          :column="legacyView ? 'xs-12 s-12' : 'xs-12 s-12 m-6'"
          class="doctor-list">
          <div grid="row wrap">
            <div column="xs-12 s-12">
              <hr />
              <p class="label">
                <app-inline-svg
                  :src="icons.providerSvg.url"
                  :style="globalTheme.colorSecondary"/>
                <strong>{{labels.doctors}}</strong>
              </p>
              <app-doctor
                v-for="(doctor,i) in doctorList"
                :key="i"
                :hide-accept-new-patient="hideAcceptNewPatient"
                :provider="provider"
                :doctor="doctor"/>
            </div>
          </div>
        </div>
        <div
          :column="legacyView || !provider.doctors.length ? 'xs-12 s-12' : 'xs-12 s-12 m-6'"
          class="extra-info">
          <div grid="row wrap">
            <provider-services
              column="xs-12 s-12"
              :icon="icons.servicesSvg.url"
              :provider="provider"
              :label="labels.services"
            />
            <provider-products
              column="xs-12 s-12"
              :icon="icons.productsSvg.url"
              :provider="provider"
              :label="labels.products"
            />
            <provider-brands
              column="xs-12 s-12"
              :icon="icons.brandsSvg.url"
              :provider="provider"
              :label="labels.brands"
            />
            <provider-accessibility
              column="xs-12 s-12"
              :icon="icons.accessibilitySvg.url"
              :provider="provider"
              :unknownAccessibility="unknownAccessibility"
              :label="labels.accessibility"
            />
          </div>
        </div>
      </div>
    </app-collapsible-section>
    <div
      grid="row wrap"
      class="_mts"
      :class="{'mobile until-tablet': !legacyView }">
      <result-card-actions
        column="xs-12 s-12"
        class="action-column"
        :labels="labels"
        :drawer-open="drawerOpen"
        :provider="provider"
        :has-special-offer="hasSpecialOffer"
        :has-special-offer-independent="hasSpecialOfferIndependent"
        :offers="offers"
        :offer-button-label="offerButtonLabel"
        :offer-popup-independent="offerPopupIndependent"
        :show-plus-provider-side-bar="showPlusProviderSideBar"
        @toggleCollapse="toggleCollapse"
      />
    </div>
    <div
      grid="row wrap justify-end"
      class="_mtxs">
      <button
        style="border: 0"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important', border: '#808080 !important' } : globalTheme.buttonWhiteBordered"
        class="btn white bordered sm details-cta"
        :aria-controls="`uid_${provider.locationId}`"
        :aria-expanded="drawerOpen.toString()"
        :aria-label="ariaLabel"
        @click="toggleCollapse"
        @keydown.enter.prevent="toggleCollapse">
        <template v-if="!drawerOpen">+ {{labels.detailsToggle}}</template>
        <template v-else>- {{labels.detailsToggleClose}}</template>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, SearchResultCardLabels, DayLabels, ContentStackImage, Doctor, Option } from '@/types'
  import { mapState } from 'vuex'
  import { isTestContentLocation, csTestBaseUrl, csBaseUrl } from '@/site.config'
  import AppDoctor from '@molecules/Results/DoctorDrawer.vue'
  import ResultCardActions from '@molecules/Results/ResultCardActions.vue'
  import IsConnecticut from '@/mixins/IsConnecticut.ts'

  // function covidSafeCheck(provider: Provider): boolean {
  //   const complianceValue = provider.locationPreference.healthCompliant || provider.locationPreference.healthCompliant
  //   return complianceValue ? complianceValue.toLowerCase() === 'y' : false
  // }
  import { filterDoctorsListBySelectedValues } from '@/ts/helpers/search-providers.helpers.ts'
  export default defineComponent({
    components : {
      AppDoctor,
      ResultCardActions
    },
    mixins: [IsConnecticut],
    props: {
      legacyView: {
        default: false,
        type: Boolean
      },
      provider: {
        required: true,
        type: Object as () => Provider
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      },
      specialOffers: {
        type: Object as () => {}
      },
      offerButtonLabel: {
        type: String
      },
      offerPopupIndependent: {
        type: String
      },
      showPlusProviderSideBar: {
        default: false,
        type: Boolean
      },
      hideAcceptNewPatient: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        address: this.provider.address,
        drawerOpen: false,
        wholesaleProviderColor: this.$store.state.globals.microsite.wholesaleProviderColor,
        noDiscountProviderColor: this.$store.state.globals.microsite.noDiscountProviderColor,
        offers: null
        // covidSafe: covidSafeCheck(this.provider) && this.$store.state.context.covidToggleOn
      }
    },
    computed: {
      doctorList(): Doctor[] {
        if (this.provider.doctorsFiltered) {
          return this.provider.doctorsFiltered
        } else {
          return this.provider.doctors
        }
      },
      ...mapState({
        icons: (state: any) => {
          return {
            ...state.globals.microsite.icons.searchResultCards,
            ...state.globals.microsite.icons.general
          }
        },
        dayLabels: (state: any) => state.globals.microsite.days as DayLabels
      }),
      icon(): string {
        const icons = this.$store.state.globals.microsite.icons
        const tieredIcon = icons.searchResultCards.tieredProviderSvg.url
        const defaultIcon = icons.mapMarkers.mapIconBasic.url
        return (this.provider as Provider).tieredLocation ? tieredIcon : defaultIcon
      },
      ariaLabel(): string {
        //@ts-ignore
        return this.drawerOpen ? `${this.labels.detailsToggleClose} for this ${this.provider.businessName}` : `${this.labels.detailsToggle} for this ${this.provider.businessName}`
      },
      isDiscountOptOut(): boolean {
        const locPreferences = this.provider.locationPreference
        return locPreferences.discountOptOut === 'true'
      },
      isValidTierName(): boolean {
        const tierName = this.provider.tierName
        return (tierName === 'Walmart' || tierName === 'Costco')
      },
      hasSpecialOffer(): boolean {
        let result = false
        const bandDescription = this.provider.bandDescription
        if (bandDescription && bandDescription in this.specialOffers) {
          const offers = this.specialOffers[bandDescription]
          if (offers) {
            result = true
            // eslint-disable-next-line
            this.offers = offers
          }
        }
        return result
      },
      hasSpecialOfferIndependent(): boolean {
        const locPreference = this.provider.locationPreference
        return locPreference.specialOfferIndicator
      },
      unknownAccessibility(): boolean {
        return this.doctorList.length === 0 && this.provider.disabilityAccomodations.length === 0;
      }
    },
    methods: {
      toggleCollapse(): void {
        this.drawerOpen = !this.drawerOpen
        const selector = '#uid_' + this.provider.locationId + ' .doctor-info > button'
        const elem = document.querySelector(selector) as HTMLElement
        if (elem) {
          elem.setAttribute('tabindex', '1')
          elem.focus()
        }
      },
      setCurrentProvider(): void {
        this.$emit('setCurrentProvider')
      },
      brandLogo() {
        let finalBrandLogoUrl
        const allLogos: Object = this.$store.state.brandLogos.brandLogos
        const businessName: string = this.provider.businessName ? this.provider.businessName.toLowerCase() : ''
        const locationLogo: string = this.provider.locationPreference.locationLogo ? this.provider.locationPreference.locationLogo.toLowerCase() : ''
        const matchingBrand: string|undefined = Object.keys(allLogos).find(key => {
          return  (locationLogo && locationLogo.includes(key) || businessName === key) ? key : undefined
        })
        if (locationLogo && locationLogo.startsWith('emcontent')) {
          const brandLogoPath = locationLogo.split('/').pop()
          finalBrandLogoUrl = isTestContentLocation ? `${csTestBaseUrl}/assets/member/${brandLogoPath}` : `${csBaseUrl}/assets/member/${brandLogoPath}`
        }
        else if (matchingBrand) {
          finalBrandLogoUrl = allLogos[matchingBrand].url
        }
        return finalBrandLogoUrl
      }
    },
  })
</script>
<style>
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
</style>
