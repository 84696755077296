<template>
  <GMapMap
    class="single-provider-map"
    :center="createLatLng(provider)"
    :zoom="15"
    :options="options">
    <GMapMarker
      :icon="setIcon(provider)"
      :clickable="false"
      :position="createLatLng(provider)"/>
  </GMapMap>
</template>
<script lang="ts">
  import { Provider, SearchResultCardLabels } from '@/types'
  import mapStyles from '@/ts/helpers/map.styles.ts'
   import {defineComponent} from 'vue'
  import { LatLng } from '@/types'
  import { generateIconUrl } from '@/ts/helpers/map-svg.ts'
  export default defineComponent({
    name: 'SingleProviderMap',
    props: {
      provider: {
        required: false,
        type: Object as () => Provider
      },
      removeControls: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return { 
        googleMap: null,
        options: {
          styles: mapStyles,
          disableDefaultUI: this.removeControls
        },
        iconTiered: document.querySelector('#map_marker_tiered') as SVGSVGElement,
        iconBasic: document.querySelector('#map_marker_basic') as SVGSVGElement
      }
    },
    methods: {
      createLatLng(provider: Provider): LatLng {
        return { lat: provider.latitude, lng: provider.longitude }
      },
      setIcon(location: Provider): any {
        const scale = 35
        const icons = this.$store.state.globals.microsite.icons.mapMarkers
        const tieredIcon = icons.mapIconTiered.url
        const defaultIcon = icons.mapIconBasic.url
        const OGurl = location.tieredLocation ? tieredIcon : defaultIcon
        const styledUrl = location.tieredLocation ? generateIconUrl(this.iconTiered) : generateIconUrl(this.iconBasic)
        return { 
          url: styledUrl ? styledUrl : OGurl,
          scaledSize: { width: scale, height: scale },
          labelOrigin: { x: scale/2, y: scale+15 }
        }
      }
    }
  })
</script>
