import { service } from '@/ts/service.methods'
import { InternalErrorResponse, FormattedAlliedList, AlliedSearchPayload } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { alliedListFactory } from '@/factories/services/allied-list'
import { isMock } from '@/../env.config'

export async function alliedSearch(alliedSearchPayload: AlliedSearchPayload):Promise<FormattedAlliedList|InternalErrorResponse> {
  if (isMock) { return alliedListFactory(require('@/mock-data/allied-list.ts')) as FormattedAlliedList }
  return new Promise((resolve, reject) => {
    service.post('alliedSearch', alliedSearchPayload)
      .then(res => {
        if (res.data.messageCode.toUpperCase() === 'PRV001') {
          handleServiceResponse(resolve, reject, res, alliedListFactory, 'alliedListFactory factory error')
        } else {
          generateServiceError(reject, res, 'allied-list.ts/alliedSearch')
        }
      })
      .catch(err => {
        generateServiceError(reject, err, 'allied-list.ts/alliedSearch')
      })
  })
}
