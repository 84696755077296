import {
  SimpleTitle,
  SearchPageLabels,
  SearchByLocationTab,
  SearchByDoctorTab,
  BoxIconLink,
  LasikIconLink
} from '@/types'
function prefetchIcon(linkGroup: any, key: string): void {
  if (linkGroup[key] && linkGroup[key].url) {
    const img = new Image()
    img.src = linkGroup[key].url
  }
}
export function searchPagLabelsFactory(page): SearchPageLabels {
  const titleSection = page.titleSection
  const searchByLocation = page.searchByLocation
  const searchByDoctor = page.searchByDoctor
  const benefitsOnline = page.benefitsOnline
  const tabLinks = [
    {
      icon: searchByLocation.tabSvgIcon.url,
      title: searchByLocation.tabTitle,
      titleMobile: searchByLocation.tabTitleMobile,
      color: 'colorPrimary'
    },
    {
      icon: searchByDoctor.tabSvgIcon.url,
      title: searchByDoctor.tabTitle,
      titleMobile: searchByDoctor.tabTitleMobile,
      color: 'colorSecondary'
    }
  ]
  if (!benefitsOnline.hideTab) {
    tabLinks.push({
      icon: benefitsOnline.tabSvgIcon.url,
      title: benefitsOnline.tabTitle,
      titleMobile: benefitsOnline.tabTitleMobile,
      color: 'colorTertiary'
    })
  }
  const pageFormatted = {
    simpleTitle: {
      title: titleSection.simpleTitle,
      content: titleSection.content,
      stretchTitle: titleSection.stretchTitle,
      textColor: titleSection.textColor
    } as SimpleTitle,
    common: {
      failedToFetchNetworkOptionsMessage: page.failedToFetchNetworkOptionsMessage as string,
      zipCodeLabel: page.zipCodeLabel as string,
      zipCodeValidationMessage: page.zipCodeValidationMessage as string,
      networkSelectLabel: page.networkSelectLabel as string,
      networkSelectValidationMessage: page.networkSelectValidationMessage as string,
      networkSelectPlaceholderText: page.networkSelectPlaceholderText as string,
      searchButtonLabel: page.searchButtonLabel as string,
      doNotKnowNetworkChoice: page.doNotKnowNetworkChoice as string,
      orSeperator: page.orSeperator as string
    },
    searchByLocationTab: {
      tabIcon: searchByLocation.tabSvgIcon.url as string,
      tabTitle: searchByLocation.tabTitle as string,
      myLocationButton: searchByLocation.useMyLocationButtonText as string,
      infoContent: searchByLocation.infoContent as string,
      infoContentAdditional: searchByLocation.infoContentAdditional as string,
      countyInputLabel: searchByLocation.countyInputLabel as string,
      hideSearchByCounty: searchByLocation.hideSearchByCounty as boolean,
      searchByCountyButton: searchByLocation.searchByCountyButton as string,
      searchByCountyLinkText: searchByLocation.searchByCountyLinkText as string,
      searchByZipLinkText: searchByLocation.searchByZipLinkText as string
    } as SearchByLocationTab,
    searchByDoctorTab: {
      tabIcon: searchByDoctor.tabSvgIcon.url as string,
      tabTitle: searchByDoctor.tabTitle as string,
      cityLabel: searchByDoctor.cityLabel as string,
      stateLabel: searchByDoctor.stateLabel as string,
      doctorNameLabel: searchByDoctor.doctorNameLabel as string,
      doctorPhoneNumberLabel: searchByDoctor.doctorPhoneNumberLabel as string,
      doctorNamePlaceholderText: searchByDoctor.doctorNamePlaceholderText as string,
      doctorLicenseLabel: searchByDoctor.doctorLicenseLabel as string,
      npiLabel: searchByDoctor.npiLabel as string,
      searchDoctorNameLinkText: searchByDoctor.searchDoctorNameLinkText as string,
      searchNpiLinkText: searchByDoctor.searchNpiLinkText as string,
      searchByAddressLinkText: searchByDoctor.searchByAddressLinkText as string,
      searchByPhoneLinkText: searchByDoctor.searchByPhoneLinkText as string,
      streetAddressLabel: searchByDoctor.streetAddressLabel as string,
      showSearchByAddress: searchByDoctor?.showSearchByAddress as boolean,
      showSearchByPhone: searchByDoctor?.showSearchByPhone as boolean,
      searchByAddressButtonLabel: searchByDoctor.searchByAddressButtonLabel as string,
      stateOrZipRequiredMessage: searchByDoctor.stateOrZipRequiredMessage as string,
      infoContent: searchByDoctor.infoContent as string,
      infoContentAdditional: searchByDoctor.infoContentAdditional as string
    } as SearchByDoctorTab,
    benefitsOnlineTab: {
      hideTab: benefitsOnline.hideTab as boolean,
      hideTieredProviderBranding: benefitsOnline.hideTieredProviderBranding as boolean,
      tabIcon: benefitsOnline.tabSvgIcon.url as string,
      tabTitle: benefitsOnline.tabTitle as string,
      infoContent: benefitsOnline.infoContent as string,
      eye360InfoBox: benefitsOnline.eye360InfoBox as string,
      benefitsOnline: {
        title: benefitsOnline.benefitsOnline.title as string,
        externalLinks: benefitsOnline.benefitsOnline.externalLink.map(
          linkGroup => {
            prefetchIcon(linkGroup, 'icon')
            return {
              tiered: linkGroup.tieredProvider ? linkGroup.tieredProvider : false,
              icon: linkGroup.icon ? linkGroup.icon.url : null,
              title: linkGroup.link.title,
              href: linkGroup.link.href
            }
          }
        ) as BoxIconLink[],
        hideBenefitsSection: benefitsOnline.benefitsOnline.hideBenefitsSection as boolean
      },
      lasikProviders: {
        title: benefitsOnline.lasikProviders.title as string,
        externalLinks: benefitsOnline.lasikProviders.externalLink.map(
          (linkGroup, i) => {
            prefetchIcon(linkGroup, 'brandIcon')
            const phoneInfo = {
              phoneNumberLabel: i === 0 ? linkGroup.phoneNumberLabel || benefitsOnline.lasikProviders
              .phoneNumberLabel: linkGroup.phoneNumberLabel,
              phoneNumber: i === 0 ? linkGroup.phoneNumber || benefitsOnline.lasikProviders.phoneNumber  : linkGroup.phoneNumber
            }
            return {
              tiered: linkGroup.tieredProvider ? linkGroup.tieredProvider : false,
              icon: linkGroup.brandIcon ? linkGroup.brandIcon.url : null,
              title: linkGroup.link.title,
              href: linkGroup.link.href,
              ...phoneInfo
            }
          }
        ) as LasikIconLink[],
        hideLasikSection: benefitsOnline.lasikProviders.hideLasikSection as boolean
      },
      alliedProviders: {
        title: benefitsOnline && benefitsOnline.alliedProviders ? benefitsOnline.alliedProviders.title : '',
        externalLinks: benefitsOnline && benefitsOnline.alliedProviders ? benefitsOnline.alliedProviders.externalLink.map(
          (linkGroup, i) => {
            prefetchIcon(linkGroup, 'brandIcon')
            return {
              tiered: linkGroup.tieredProvider ? linkGroup.tieredProvider : false,
              icon: linkGroup.brandIcon ? linkGroup.brandIcon.url : null,
              title: linkGroup.link.title,
              href: linkGroup.link.href,
              key: linkGroup.key
            }
          }
        ): [],
        hideAlliedSection: benefitsOnline && benefitsOnline.alliedProviders ? benefitsOnline.alliedProviders.hideAlliedSection : false
      }
    },
    tabs: tabLinks
  }
  return pageFormatted
}
