/* Global Site Variables
---------------------------- */
import { Dictionary } from 'vue-router/types/router'
import { testHosts } from '@/../memTestHostLocations.ts'

export const baseServiceUrl: string = import.meta.env.VITE_BASE_SERVICES_URL!
export const serviceVersion: string = import.meta.env.VITE_BASE_SERVICES_VERSION!
// export const covidToggleOn: boolean = import.meta.env.VITE_COVID_TOGGLE_ON==='true'
export const csBaseUrl: string = import.meta.env.VITE_CONTENTSTACK_BASE_SERVICES_URL!
export const csTestBaseUrl: string = 'https://content.eyemedvisioncare.com/contentTEST/locator'
export const csSpecialOffersBaseUrl: string = import.meta.env.VITE_CONTENTSTACK_SPECIAL_OFFERS_URL!
export const csSpecialOffersTestBaseUrl: string = 'https://content.eyemedvisioncare.com/contentMEMTEST'

export const isTestContentLocation: boolean =  (function(): boolean {
  const location = window.location.host
  return testHosts.find(host => host === location) !== undefined
})()

export const _prepareQueryString = (uri, queryParams = {}) => {
    Object.keys(queryParams).forEach((key, i) => {
      uri += i === 0 ? '?' : '&'
      const urlEncodedVal = encodeURIComponent(queryParams[key])
      uri += `${key}=${urlEncodedVal}`
    })
    return uri
  }

export function getServiceUrl(endpoint: string, params?: object, version?: string): string {
    version = version ? version : serviceVersion
    return _prepareQueryString(`${baseServiceUrl}/api/${version}/${endpoint}`, params)
  }

  export function getBaseUrl(params: Dictionary<string>): string {
    return `/${params.clientID}/${params.lang}`
  }
