import { SpecialOfferData, FetchedSpecialOfferObject } from '@/types'
import date from 'date-and-time'
export function specialOffersFactory(offers: any[]): SpecialOfferData[] {
  const formattedOffers = offers
    .map(
      (offer: FetchedSpecialOfferObject): SpecialOfferData => {
        const image = offer.image
        const now: number = new Date().getTime()
        const startDate: number | undefined = offer.startDate
          ? Date.parse(date.parse(offer.startDate, 'YYYY-MM-DD'))
          : undefined
        const endDate: number | undefined = offer.endDate
          ? Date.parse(date.parse(offer.endDate, 'YYYY-MM-DD'))
          : undefined
        const isValidOffer: boolean = (startDate && endDate) ? startDate <= now && endDate >= now : false
        const bandDescription: string = offer.bandDescription
        const brands: (string|undefined)[] = offer.brands ? [offer.brand, ...offer.brands] : [offer.brand]
        const types: (string|undefined)[] = offer.types ? [offer.type, ...offer.types] : [offer.type]
        //@ts-ignore
        const filteredBrands: string[] = brands.filter(brand => typeof brand === 'string')
        //@ts-ignore
        const filteredTypes: string[] = types.filter(type => typeof type === 'string')
        const excludedState: (string|undefined)[] = offer.excludedState ? offer.excludedState : undefined
        return {
          bandDescription: bandDescription,
          brand: filteredBrands,
          type: filteredTypes,
          businessLogo: image ? image.url : undefined,
          businessLogoAltText: offer.imageAlt || '',
          title: offer.specialOfferTitle,
          content: offer.body,
          disclaimer: offer.disclaimer,
          downloadUrl: '#',
          expirationDate: offer.endDate,
          addToWallet: false,
          sortOrder: offer.sortOrder,
          isValidOffer: isValidOffer,
          excludedState: excludedState
        }
      }
    )
    .sort((offerA, offerB) => {
      return offerA.sortOrder - offerB.sortOrder
    })
    .filter(offer => offer.isValidOffer)
  return formattedOffers
}
