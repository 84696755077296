export const testHosts: string [] = [
    'eyedoclocatorashuat.eyemedvisioncare.com',
    'memberashuat.eyemedvisioncare.com',
    'localhost:7128',
    'emapacheint2a.lux-ext.net:7128',
    'emapachedev1a.luxgroup.net:7128',
    'atl-emapacheint4.lenscrafters.com:7128',
    'atl-emapacheqa4.lenscrafters.com:7128',
    'atl-emapacheuat6.lenscrafters.com:7128',
    'localhost:8080',
    'localhost:8080',
    'memberashuat.blue2020ma.com',
    'eyedoclocatorashuat.blue2020ma.com',
    'memberashuat.aetnavision.com',
    'eyedoclocatorashuat.aetnavision.com',
    'memberashuat.aetnamedicarevision.com',
    'eyedoclocatorashuat.aetnamedicarevision.com',
    'memberashuat.humanavis.com',
    'eyedoclocatorashuat.humanavis.com',
    'memberashuat.humanamedicarevision.com',
    'eyedoclocatorashuat.humanamedicarevision.com',
    'eyedoclocatordev1.eyemedvisioncare.com'
]
