import { service } from '@/ts/service.methods'
import { InternalErrorResponse, FormattedFilterList } from '@/types'
import { generateServiceError, handleServiceResponse } from '@/ts/service.locator.response-handling'
import { filterListFactory } from '@/factories/services/filter-list'
import { isMock } from '@/../env.config'

export async function getFilters():Promise<FormattedFilterList|InternalErrorResponse> {
    if (isMock) { return filterListFactory(require('@/mock-data/filter-list.ts')) as FormattedFilterList}
    return new Promise((resolve,reject) => {
        service.get('filters')
        .then(res => {
            if (res.data.messageCode.toUpperCase() === 'FIL001') {
                handleServiceResponse(resolve,reject,res,filterListFactory,'filterListFactory factory error')
            } else {
                generateServiceError(reject, res, 'get-filters.ts/getFilters')
            }
        })
        .catch(err => generateServiceError(reject, err, 'get-filters.ts/getFilters'))
    })
}
