<template>
<div class="form-group" role="form">
  <ValidationProvider
    v-slot="{ field,errors }"
    :mode="validationMode"
    :name="id"
    :rules="validations"
    ref="observer"
    tag="div"
  >
    <div
      class="scroll-anchor"
      :class="{'invalid-anchor' : errors.length > 0}" />
    <label
      :for="id"
      :class="{'invalid' : errors.length > 0}"
      aria-live="polite"
      @click.prevent
    ><span class="moon">{{ label }}</span>
      <tool-tip
        v-if="tooltipContent"
        :message="tooltipContent"
        class="_mlxxs"
      />
      <span
        v-if="(errors.length > 0)"
        class="lips validation _mlxxs"
      >* {{ validationMessage || errors[0] }}</span>
    </label>
    <vue-accessible-multiselect
      style="display:block!important;"
      :id='id'
      ref="multiselect"
      :name="label"
      v-model="internalValue"
      :options="options"
      :track-by="trackBy"
      :mode="mode"
      :close-on-select="true"
      :searchable="true"
      :searchStart="true"
      :close-on-deselect="true"
      :placeholder="placeholder"
      :canClear="false"
      :canDeselect="id !== 'network'"
    >
    </vue-accessible-multiselect>
  </ValidationProvider>
</div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Option } from '@/types'
  import ToolTip from './ToolTip.vue'
  import Multiselect from "@vueform/multiselect";
  export default defineComponent({
   
    components: {
      ToolTip,
      VueAccessibleMultiselect: Multiselect,
      
    },
    props: {
      canClear:{
        type:Boolean,
        default:false
      },
      mode:{
        type:String,
        default:'single'
      },
      modelValue: {
        type: [Array, String, Object],
        default: () => [''] as String[]
      },
      options: {
        type: Array as () => String[],
        default: undefined
      },
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      validations: {
        type: String,
        default: ''
      },
      validationMode: {
        type: String ,
        default: 'eager'
      },
      validationMessage: {
        type: String,
        required: false
      },
      invalid: {
        type: Boolean,
        required: false
      },
      required: {
        type: Boolean,
        required: false,
        default:false
      },
      tooltipContent: {
        type: String as () => string,
        default: undefined
      },
      searchable: {
        type: Boolean,
        default: false
      },
      searchStart: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: true
      },
      trackBy: {
        type: String,
        default: 'label'
      },
      allowEmpty: {
        type: Boolean,
        default: true
      },
      clearOnSelect: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        required: false
      }, 
      taggable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        touched: false as boolean
      }
    },
    computed: {
      internalValue: {
        get(): any {
          if (Array.isArray(this.modelValue)) {
        return this.modelValue.map((obj) => obj?.value);
      }
          return this.modelValue?.value
        },
        set(newVal: Option | string): void {
          let optionObject = [];
          if(this.mode === 'tags'){
            optionObject = newVal.map(value => this.options.find(option => option.value === value));
          }
          else{
            optionObject = this.options.find(option => option.value === newVal);
          }
          (this as any).$emit('update:modelValue', optionObject);
          }
      },
    },
    mounted(){
      const multiselectEl = this.$refs.multiselect.$el.firstElementChild.firstElementChild
      if(multiselectEl){
        // multiselectEl.setAttribute('aria-labelledby', this.$changeCase.paramCase(this.placeholder) + '-lbl');
        // multiselectEl.setAttribute('aria-expanded', 'false');
        multiselectEl.removeAttribute('aria-placeholder');
        multiselectEl.removeAttribute('aria-multiselectable');
      }
    }
  })
</script>

<style lang="scss"></style>
<style src="@vueform/multiselect/themes/default.css"></style>
