<template>
  <div v-if="provider.disabilityAccomodations.length || isConnecticut || unknownAccessibility">
    <hr />
    <p class="label">
      <app-inline-svg
        :src="icon"
        :style="globalTheme.colorSecondary"/>
      <strong>{{label}}</strong>
    </p>
    <p>
      <span
        v-for="(accomodation, i) in provider.disabilityAccomodations"
        :key="i">
        {{accomodation}}<template v-if="i !== provider.disabilityAccomodations.length - 1">, </template>
      </span>
      <span v-if="unknownAccessibility">Unknown</span>
    </p>
  </div>
  <div v-else>
    <hr />
    <p class="label">
      <app-inline-svg
        :src="icon"
        :style="globalTheme.colorSecondary"/>
      <strong>{{label}}</strong>
    </p>
    <p>
      <span>Not Handicap Accessible</span>
    </p>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider,SearchResultCardLabels } from '@/types'
  import IsConnecticut from '@/mixins/IsConnecticut.ts'

  export default defineComponent({
    mixins: [IsConnecticut],
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      unknownAccessibility: {
        type: Boolean
      },
      label: {
        required: true,
        type: String
      },
      icon: {
        required: true,
        type: String
      }
    }
  })
</script>
