<template>
  <div container>
    <div grid="row wrap">
      <div column="xs-12 s-12">
        <ValidationObserver ref="emailObserver">
          <response-flash-notification
            v-if="responseMessageObject"
            :error-obj="responseMessageObject"
            @closeNotification="resetForm"
          />
          <app-loader-section
            v-else-if="submitting"
            :color="globalTheme.colorSecondary"
            class="_pts _pb0"
          />
          <form
            v-else
            class="email-results-form"
            @submit.prevent="sendEmail">
            <form-input
              id="recipient_email"
              v-model="recipientEmail"
              :label="inputLabel"
              validations="required|email"
              type="email"
              :validation-mode="validationChecked ? 'aggressive' : 'passive'"
            />
            <button
              class="sm"
              :style="globalTheme.buttonSecondary"
              type="submit"
            >
              {{ submitLabel }}
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { emailProviders } from '@/ts/service-helpers/services/email-search-results'
  import { mockedData } from '@/mock-data/email-search-results'
  import {
    NewServiceResponseMessage,
    EmailSearchResultsPayload
  } from '@/types'
  import FormInput from '@molecules/Form/FormInput.vue'
  export default defineComponent({
    components: { FormInput },
    props: {
      inputLabel: {
        type: String,
        default: ''
      },
      submitLabel: {
        type: String,
        default: ''
      },
      emailSearchResultsPayload: {
        type: Object as () => EmailSearchResultsPayload,
        required: true
      }
    },
    data() {
      return {
        validationChecked: false,
        recipientEmail: '' as string,
        submitting: false,
        responseMessageObject: null as null | NewServiceResponseMessage
      }
    },
    methods: {
      resetForm(): void {
        this.validationChecked = false
        this.responseMessageObject = null
        this.recipientEmail = ''
        this.$emit('closeDrawer')
      },
      async sendEmail(): Promise<void> {
        this.validationChecked = true
        const isValid = await (this.$refs.emailObserver as any).validate()
        if (isValid) {
          this.submitting = true
          const payloadPlusEmailAddress: EmailSearchResultsPayload = { ...this.emailSearchResultsPayload, toEmailAddress: this.recipientEmail }
          emailProviders(payloadPlusEmailAddress)
            .then(res => {
              this.responseMessageObject = res
            })
            .catch(err => {
              this.responseMessageObject = err
            })
            .finally(() => {
              this.submitting = false
            })
        } else {
          const errorEl: HTMLInputElement | null = this.$el.querySelector(
            '.error'
          )
          if (errorEl) {
            errorEl.focus()
          }
        }
      }
    }
  })
</script>
