<template>
  <div>
    <hr />
    <div class="label">
      <app-inline-svg
        :src="icon"
        :style="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : globalTheme.colorSecondary"/>
      <span>
        <p v-if="isVisionSource">{{provider.businessName}}</p>
        <p v-if="distance">{{distance}}</p>
        <p v-else-if="provider.distance && showProviderDistance">{{provider.distance}} mi</p>
        <p v-if="address.streetAddress1">{{address.streetAddress1}}</p>
        <p v-if="address.streetAddress2">{{address.streetAddress2}}</p>
        <p>{{address.city}}, {{address.state}} {{address.zip}}</p>
        <directions-link
          v-if="!hideLink"
          class="_mta hide-print"
          :style-override="(isValidTierName || isDiscountOptOut) ? { color: '#808080 !important' } : {}"
          :provider="provider"
          :label="label"/>
      </span>
    </div>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, ProviderAddress } from '@/types'
  import DirectionsLink from '@atoms/DirectionsLink.vue'

  export default defineComponent({
    components: {
      DirectionsLink
    },
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      label: {
        required: false,
        type: String
      },
      icon: {
        required: true,
        type: String
      },
      hideLink: {
        type: Boolean,
        default: false
      },
      distance: {
        required: false,
        type: String
      },
      legacyView: {
        type: Boolean,
        default: false
      },
      showProviderDistance: {
        required: false,
        type: Boolean
      }
    },
    data() {
      return {
        address: this.provider.address as ProviderAddress,
      }
    },
    computed: {
      isValidTierName() {
        const tierName = this.provider.tierName
        return (tierName === 'Walmart' || tierName === 'Costco')
      },
      isDiscountOptOut() {
        const locPreferences = this.provider.locationPreference
        return locPreferences.discountOptOut === 'true'
      },
      isVisionSource() {
        const locationLogo = this.provider.locationPreference.locationLogo ? this.provider.locationPreference.locationLogo.toLowerCase() : ''
        return locationLogo === 'visionsource'
      }
    }
  })
</script>
