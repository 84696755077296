<template>
  <a
    :style="styleOverride ? styleOverride : defaultStyle"
    :class="classOverride"
    :href="localizedRoute"
    :aria-label="`${label} to this ${provider.businessName}`"
    @click.prevent="setProviderThenSearch">
    <slot v-if="hasDefaultSlot"></slot>
    <template v-else>
      {{label}}
      <font-awesome-icon
        :icon="['fas', 'caret-right']"
        class="caret _mlxxs"/>
    </template>
  </a>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, SearchResultCardLabels, DirectionsLinksQuery } from '@/types'
  import { getBaseUrl } from '@/site.config.ts'
  export default defineComponent({
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      label: {
        required: true,
        type: String
      },
      classOverride: {
        type: String,
        default: 'inline-caret-link'
      },
      styleOverride: {
        type: [Object, Array],
        required: false
      }
    },
    computed: { 
      hasDefaultSlot (): boolean {
        //@ts-ignore
        return !!this.$slots.default
      },
      defaultStyle(): object {
        //@ts-ignore
        return this.globalTheme.colorSecondary
      },
      localizedRoute(): string {
        return `${getBaseUrl((this as any).$route.params)}/directions`
      }
    },
    methods: {
      setProviderThenSearch(): void {
        const searchedZipCode = (this as any).provider.searchedZipCode ? (this as any).provider.searchedZipCode : this.$route.query.zip
        this.$store.commit('currentProvider/setCurrentProvider', {
          ...(this as any).provider,
          searchedZipCode: searchedZipCode
        })
        this.$router.push({ path: (this as any).localizedRoute })
      }
    }
  })
</script>
