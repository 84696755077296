<template>
  <div class='form-group' 
  role="form"
  :aria-label="label">
  <ValidationProvider
    v-slot="{ field,errors }"
    :name="id"
    :rules="validations"
    :value="modelValue"
    tag="div"
    ref="provider"
  >

    <div
      class="scroll-anchor"
      :class="{'invalid-anchor' : errors.length > 0}" />
    <label
      :for="id"
      :class="{invalid : errors.length > 0 && !hideValidationMessage}"
      aria-live="polite"
      @click.prevent
    ><span class="moon _mrxxs">{{ label }}</span>
      <template v-if="!hideValidationMessage">
        <span
          v-if="(errors.length > 0)"
          class="lips validation"
        >*{{ validationMessage || errors[0]  }}</span>
      </template>
    </label>
    <div
      :class="{'password-wrapper' : showHideToggle}"
      class="input-wrapper"
    >
      <input
        v-if="mask"
        v-bind="field" 
        :value="modelValue"
        :id="id"     
        v-mask="mask"
        :inputmode="inputmode"
        :pattern="pattern"
        :placeholder="placeholder"
        :class="{error: errors.length > 0 && !hideValidationMessage}"
        :disabled="disabled"
        :name="id"
        type="string"
        @input="updateValue"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <input
        v-else
        v-bind="field" 
        :id="id"  
      :value="modelValue"
        :inputmode="inputmode"
        :maxlength="maxLength"
        :pattern="pattern"
        :placeholder="placeholder"
        :class="{error: errors.length > 0 && !hideValidationMessage}"
        :disabled="disabled"
        :name="id"
        type="string"
        @input="updateValue"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <slot name="button" />
    </div>
  </ValidationProvider>
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'

  export default defineComponent({
  
    props: {
      modelValue: {
        type: String as () => string,
        default: ''
      },
      id: {
        type: String as () => string,
        default: ''
      },
      label: {
        type: String as () => string,
        default: ''
      },
      validations: {
        type:  String as () => string || Object as () => Object,
        default: ''
      },
      validationMode: {
        type: String as () => string || Object as () => Object,
        default: 'eager'
      },
      disabled: {
        type: Boolean as () => boolean,
        default: false
      },
      showHideToggle: {
        type: Boolean,
        default: false
      },
      validationMessage: {
        type: String,
        required: false
      },
      mask: {
        type: String,
        required: false
      },
      hideValidationMessage: {
        type: Boolean,
        default: false
      },
      inputmode: {
        type: String,
        required: false
      },
      pattern: {
        type: String,
        required: false
      },
      placeholder: {
        required: false,
        type: String
      },
      maxLength: {
        type: String,
        default: ''
      },
    },
    methods:{
      updateValue(e) {
       
        this.$refs.provider?.validate()
        this.$emit('update:modelValue', e.target.value)
      }
    },
  })
</script>
<style
  lang="scss"
  scoped
>
  input {
    padding: 5px 10px;
  }

  .password-wrapper {
    position: relative;

    .toggle-password {
      position: absolute;
      right: 15px;
      min-width: 0 !important;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
